import { secs, years } from '@adrise/utils/lib/time';

import { getCookie, setCookie } from 'client/utils/localDataStorage';
import logger from 'common/helpers/logging';

/**
 * This is known to not be secure, because it is enforced on the client side.
 * However, the isSelectedGroup() check is already client side only, so is
 * similarly vulnerable. Until we move to proper server-side experiment values
 * we will have this vulnerability to users utilizing DevTools to bypass the check.
 */
const rolloutOverride: { getStatus(): boolean; setStatus(status: boolean): void } = {
  getStatus: () => {
    return getCookie(':)') === '1';
  },
  setStatus: (shouldEnable) => {
    const cookieValue = shouldEnable ? '1' : '0';
    try {
      setCookie(':)', cookieValue, years(10) / secs(1));
    } catch (e) {
      logger.error(`Error writing cookie value of ":)=${cookieValue}".`);
    }
  },
};

export default rolloutOverride;
