import {
  ErrorType,
  PLAYER_ERROR_DETAILS,
  HLSJS_ERROR_DETAILS,
  MEDIA_ERROR_CODES,
  TIZEN_CONNECTION_FAILED_ERROR,
} from '@adrise/player';
import type {
  ErrorEventData,
} from '@adrise/player';
import type { ValueOf } from 'ts-essentials';

import { UNKNOWN_ERROR } from 'client/features/playback/track/client-log/utils/types';

export const QoSErrorType = {
  NONE_ERROR: -1,
  UNKNOWN_ERROR_TYPE: 0,
  DRM_ERROR: 1,
  MEDIA_ERROR: 2,
  NETWORK_ERROR: 3,
  OTHER_ERROR: 4,
} as const;
export type QoSErrorTypeValues = ValueOf<typeof QoSErrorType>;

export const QoSErrorCode = {
  NONE_ERROR: -1,
  UNKNOWN_ERROR_CODE: 0,

  // DRM_ERROR 1000~1999
  HDCP_INCOMPLIANCE: 1000,
  HLSJS_KEY_SYSTEM_INVALID_HDCP_VERSION: 1001,
  HLSJS_KEY_SYSTEM_LICENSE_INVALID_STATUS: 1002,
  HLSJS_KEY_SYSTEM_LICENSE_INTERNAL_ERROR: 1003,
  HLSJS_KEY_SYSTEM_LICENSE_EXPIRED: 1004,
  HLSJS_KEY_SYSTEM_NO_KEYS: 1005,
  HLSJS_KEY_SYSTEM_NO_ACCESS: 1006,
  HLSJS_KEY_SYSTEM_NO_SESSION: 1007,
  HLSJS_KEY_SYSTEM_LICENSE_REQUEST_FAILED: 1008,
  HLSJS_KEY_SYSTEM_NO_INIT_DATA: 1009,
  HLSJS_KEY_SYSTEM_STATUS_OUTPUT_RESTRICTED: 1010,
  /* 1011 ~ 1016 Deprecated shaka error */
  HLSJS_KEY_SYSTEM_SESSION_UPDATE_FAILED: 1017,

  // MEDIA_ERROR 2000~2999
  HLSJS_MANIFEST_INCOMPATIBLE_CODECS_ERROR: 2000,
  HLSJS_FRAG_PARSING_ERROR: 2001,
  HLSJS_FRAG_REVERT_ERROR: 2002,
  HLSJS_BUFFER_ADD_CODEC_ERROR: 2003,
  HLSJS_BUFFER_INCOMPATIBLE_CODECS_ERROR: 2004,
  HLSJS_BUFFER_APPEND_ERROR: 2005,
  HLSJS_BUFFER_REVERT_APPEND_ERROR: 2006,
  HLSJS_BUFFER_APPENDING_ERROR: 2007,
  HLSJS_BUFFER_STALLED_ERROR: 2008,
  HLSJS_BUFFER_FULL_ERROR: 2009,
  HLSJS_BUFFER_SEEK_OVER_HOLE: 2010,
  HLSJS_BUFFER_NUDGE_ON_STALL: 2011,
  DOM_EXCEPTION_NOT_SUPPORTED_ERR: 2012,
  DOM_EXCEPTION_ABORT_ERR: 2013,
  DOM_EXCEPTION_INVALID_CHARACTER_ERR: 2014,
  MEDIA_ERR_ABORTED: 2015,
  MEDIA_ERR_NETWORK: 2016,
  MEDIA_ERR_DECODE: 2017,
  MEDIA_ERR_SRC_NOT_SUPPORTED: 2018,

  // NETWORK_ERROR 3000~3999
  HLSJS_MANIFEST_LOAD_ERROR: 3000,
  HLSJS_MANIFEST_LOAD_TIMEOUT: 3001,
  HLSJS_MANIFEST_PARSING_ERROR: 3002,
  HLSJS_LEVEL_EMPTY_ERROR: 3003,
  HLSJS_LEVEL_LOAD_ERROR: 3004,
  HLSJS_LEVEL_LOAD_TIMEOUT: 3005,
  HLSJS_AUDIO_TRACK_LOAD_TIMEOUT: 3006,
  HLSJS_SUBTITLE_LOAD_ERROR: 3007,
  HLSJS_SUBTITLE_TRACK_LOAD_TIMEOUT: 3008,
  HLSJS_FRAG_LOAD_ERROR: 3009,
  HLSJS_FRAG_LOAD_TIMEOUT: 3010,
  HLSJS_KEY_LOAD_ERROR: 3011,
  HLSJS_KEY_LOAD_TIMEOUT: 3012,
  HLSJS_INTERNAL_ABORTED: 3013,
  HLSJS_AUDIO_TRACK_LOAD_ERROR: 3014,
  /* 3015 ~ 3018 Deprecated shaka error */
  TIZEN_CONNECTION_FAILED_ERROR: 3019,

  // OTHER_ERROR 4000~4999
  SETUP_ERROR: 4000,
  HLSJS_LEVEL_SWITCH_ERROR: 4001,
  HLSJS_BUFFER_CUE_EMPTY_ON_SB_UPDATE_END: 4002,
  HLSJS_INTERNAL_EXCEPTION: 4003,
  HLSJS_REMUX_ALLOC_ERROR: 4004,
  /* 4005 Deprecated shaka error */
  HLSJS_DEVIATED_BUFFER_FLUSHING: 4006,
} as const;
export type QoSErrorCodeValues = ValueOf<typeof QoSErrorCode>;

const getErrorType = (error: ErrorEventData) => {
  switch (error.type) {
    case ErrorType.DRM_ERROR:
    case ErrorType.KEY_SYSTEM_ERROR:
      return QoSErrorType.DRM_ERROR;
    case ErrorType.MEDIA_ERROR:
      return QoSErrorType.MEDIA_ERROR;
    case ErrorType.NETWORK_ERROR:
      return QoSErrorType.NETWORK_ERROR;
    case ErrorType.OTHER_ERROR:
    case ErrorType.SETUP_ERROR:
    case ErrorType.MUX_ERROR:
      return QoSErrorType.OTHER_ERROR;
    default:
      break;
  }
  return QoSErrorType.UNKNOWN_ERROR_TYPE;
};

const getErrorCodeFromDetails = (error: ErrorEventData) => {
  switch (error.details) {
    case PLAYER_ERROR_DETAILS.HDCP_INCOMPLIANCE:
      return QoSErrorCode.HDCP_INCOMPLIANCE;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_INVALID_HDCP_VERSION:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_INVALID_HDCP_VERSION;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_LICENSE_INVALID_STATUS:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_LICENSE_INVALID_STATUS;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_LICENSE_INTERNAL_ERROR:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_LICENSE_INTERNAL_ERROR;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_LICENSE_EXPIRED:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_LICENSE_EXPIRED;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_NO_KEYS:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_NO_KEYS;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_NO_ACCESS:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_NO_ACCESS;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_NO_SESSION:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_NO_SESSION;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_LICENSE_REQUEST_FAILED:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_LICENSE_REQUEST_FAILED;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_NO_INIT_DATA:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_NO_INIT_DATA;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_STATUS_OUTPUT_RESTRICTED:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_STATUS_OUTPUT_RESTRICTED;
    case HLSJS_ERROR_DETAILS.KEY_SYSTEM_SESSION_UPDATE_FAILED:
      return QoSErrorCode.HLSJS_KEY_SYSTEM_SESSION_UPDATE_FAILED;
    case HLSJS_ERROR_DETAILS.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
      return QoSErrorCode.HLSJS_MANIFEST_INCOMPATIBLE_CODECS_ERROR;
    case HLSJS_ERROR_DETAILS.FRAG_PARSING_ERROR:
      return QoSErrorCode.HLSJS_FRAG_PARSING_ERROR;
    case HLSJS_ERROR_DETAILS.FRAG_REVERT_ERROR:
      return QoSErrorCode.HLSJS_FRAG_REVERT_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_ADD_CODEC_ERROR:
      return QoSErrorCode.HLSJS_BUFFER_ADD_CODEC_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_INCOMPATIBLE_CODECS_ERROR:
      return QoSErrorCode.HLSJS_BUFFER_INCOMPATIBLE_CODECS_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_APPEND_ERROR:
      return QoSErrorCode.HLSJS_BUFFER_APPEND_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_REVERT_APPEND_ERROR:
      return QoSErrorCode.HLSJS_BUFFER_REVERT_APPEND_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_APPENDING_ERROR:
      return QoSErrorCode.HLSJS_BUFFER_APPENDING_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_STALLED_ERROR:
      return QoSErrorCode.HLSJS_BUFFER_STALLED_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_FULL_ERROR:
      return QoSErrorCode.HLSJS_BUFFER_FULL_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_SEEK_OVER_HOLE:
      return QoSErrorCode.HLSJS_BUFFER_SEEK_OVER_HOLE;
    case HLSJS_ERROR_DETAILS.BUFFER_NUDGE_ON_STALL:
      return QoSErrorCode.HLSJS_BUFFER_NUDGE_ON_STALL;
    case HLSJS_ERROR_DETAILS.MANIFEST_LOAD_ERROR:
      return QoSErrorCode.HLSJS_MANIFEST_LOAD_ERROR;
    case HLSJS_ERROR_DETAILS.MANIFEST_LOAD_TIMEOUT:
      return QoSErrorCode.HLSJS_MANIFEST_LOAD_TIMEOUT;
    case HLSJS_ERROR_DETAILS.MANIFEST_PARSING_ERROR:
      return QoSErrorCode.HLSJS_MANIFEST_PARSING_ERROR;
    case HLSJS_ERROR_DETAILS.LEVEL_EMPTY_ERROR:
      return QoSErrorCode.HLSJS_LEVEL_EMPTY_ERROR;
    case HLSJS_ERROR_DETAILS.LEVEL_LOAD_ERROR:
      return QoSErrorCode.HLSJS_LEVEL_LOAD_ERROR;
    case HLSJS_ERROR_DETAILS.LEVEL_LOAD_TIMEOUT:
      return QoSErrorCode.HLSJS_LEVEL_LOAD_TIMEOUT;
    case HLSJS_ERROR_DETAILS.AUDIO_TRACK_LOAD_TIMEOUT:
      return QoSErrorCode.HLSJS_AUDIO_TRACK_LOAD_TIMEOUT;
    case HLSJS_ERROR_DETAILS.SUBTITLE_LOAD_ERROR:
      return QoSErrorCode.HLSJS_SUBTITLE_LOAD_ERROR;
    case HLSJS_ERROR_DETAILS.SUBTITLE_TRACK_LOAD_TIMEOUT:
      return QoSErrorCode.HLSJS_SUBTITLE_TRACK_LOAD_TIMEOUT;
    case HLSJS_ERROR_DETAILS.FRAG_LOAD_ERROR:
      return QoSErrorCode.HLSJS_FRAG_LOAD_ERROR;
    case HLSJS_ERROR_DETAILS.FRAG_LOAD_TIMEOUT:
      return QoSErrorCode.HLSJS_FRAG_LOAD_TIMEOUT;
    case HLSJS_ERROR_DETAILS.KEY_LOAD_ERROR:
      return QoSErrorCode.HLSJS_KEY_LOAD_ERROR;
    case HLSJS_ERROR_DETAILS.KEY_LOAD_TIMEOUT:
      return QoSErrorCode.HLSJS_KEY_LOAD_TIMEOUT;
    case HLSJS_ERROR_DETAILS.INTERNAL_ABORTED:
      return QoSErrorCode.HLSJS_INTERNAL_ABORTED;
    case HLSJS_ERROR_DETAILS.AUDIO_TRACK_LOAD_ERROR:
      return QoSErrorCode.HLSJS_AUDIO_TRACK_LOAD_ERROR;
    case HLSJS_ERROR_DETAILS.LEVEL_SWITCH_ERROR:
      return QoSErrorCode.HLSJS_LEVEL_SWITCH_ERROR;
    case HLSJS_ERROR_DETAILS.BUFFER_CUE_EMPTY_ON_SB_UPDATE_END:
      return QoSErrorCode.HLSJS_BUFFER_CUE_EMPTY_ON_SB_UPDATE_END;
    case HLSJS_ERROR_DETAILS.INTERNAL_EXCEPTION:
      return QoSErrorCode.HLSJS_INTERNAL_EXCEPTION;
    case HLSJS_ERROR_DETAILS.REMUX_ALLOC_ERROR:
      return QoSErrorCode.HLSJS_REMUX_ALLOC_ERROR;
    case PLAYER_ERROR_DETAILS.DEVIATED_BUFFER_FLUSHING:
      return QoSErrorCode.HLSJS_DEVIATED_BUFFER_FLUSHING;
    default:
      break;
  }
  return QoSErrorCode.UNKNOWN_ERROR_CODE;
};

const getErrorCodeFromErrorLogCode = (error: ErrorEventData) => {
  const errorLogCode = (MEDIA_ERROR_CODES[error.code ?? ''] || error.code || error.type) ?? UNKNOWN_ERROR;
  switch (errorLogCode) {
    case ErrorType.SETUP_ERROR:
      return QoSErrorCode.SETUP_ERROR;
    case DOMException.NOT_SUPPORTED_ERR:
      return QoSErrorCode.DOM_EXCEPTION_NOT_SUPPORTED_ERR;
    case DOMException.ABORT_ERR:
      return QoSErrorCode.DOM_EXCEPTION_ABORT_ERR;
    case DOMException.INVALID_CHARACTER_ERR:
      return QoSErrorCode.DOM_EXCEPTION_INVALID_CHARACTER_ERR;
    case MEDIA_ERROR_CODES[1]: // 'MEDIA_ERR_ABORTED'
      return QoSErrorCode.MEDIA_ERR_ABORTED;
    case MEDIA_ERROR_CODES[2]: // 'MEDIA_ERR_NETWORK'
      return QoSErrorCode.MEDIA_ERR_NETWORK;
    case MEDIA_ERROR_CODES[3]: // 'MEDIA_ERR_DECODE'
      return QoSErrorCode.MEDIA_ERR_DECODE;
    case MEDIA_ERROR_CODES[4]: // 'MEDIA_ERR_SRC_NOT_SUPPORTED'
      return QoSErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED;
    case TIZEN_CONNECTION_FAILED_ERROR:
      return QoSErrorCode.TIZEN_CONNECTION_FAILED_ERROR;
    default:
      break;
  }
  return QoSErrorCode.UNKNOWN_ERROR_CODE;
};

export const convertErrorToUnifiedEnum = (error: ErrorEventData): {
  errorType: QoSErrorTypeValues,
  errorCode: QoSErrorCodeValues
} => {
  const errorType = getErrorType(error);
  let errorCode: QoSErrorCodeValues = QoSErrorCode.UNKNOWN_ERROR_CODE;
  [getErrorCodeFromDetails, getErrorCodeFromErrorLogCode].some(item => {
    return (errorCode = item(error)) !== QoSErrorCode.UNKNOWN_ERROR_CODE;
  });
  return {
    errorType,
    errorCode,
  };
};
