import classNames from 'classnames';
import hoistNonReactStatics from 'hoist-non-react-statics';
import type { ComponentType, FunctionComponent } from 'react';
import React, { Component } from 'react';
import type { DispatchProp } from 'react-redux';
import { connect, useSelector } from 'react-redux';
import type { WithRouterProps } from 'react-router';
import { withRouter } from 'react-router';

import { hasExitApi } from 'client/systemApi/utils';
import {
  debouncedCloseLeftNav,
  setLeftNavOption,
} from 'common/actions/leftNav';
import { ENABLE_OTT_MOUSE_EVENTS } from 'common/constants/constants';
import { OTT_ROUTES } from 'common/constants/routes';
import useAppDispatch from 'common/hooks/useAppDispatch';
import { isSupportEspanolModeSelector } from 'common/selectors/espanolMode';
import { isWebLiveNewsEnableSelector } from 'common/selectors/webLive';
import type { OpenLeftNavFromReason } from 'common/types/ottUI';
import { LeftNavMenuOption } from 'common/types/ottUI';
import type { StoreState } from 'common/types/storeState';
import { isFeatureAvailableInCountry } from 'common/utils/geoFeatures';
import OTTLeftNavContainer from 'ott/components/OTTLeftNavContainer/OTTLeftNavContainer';

import styles from './OTTLeftNavContainer.scss';

interface StateProps {
  hasExitApi: boolean;
  isChannelsVisible: boolean;
  isEspanolModeVisible: boolean;
  isExpanded: boolean;
  isKidsModeEnabled: boolean; // if disabled, kids mode is still visible, could be turned on
  isKidsModeVisible: boolean;
  isLiveVisible: boolean;
  isLoggedIn: boolean;
  isMoviesTVShowsVisible: boolean; // to show/hide movies/tv shows options in left nav
  isSlowDevice: boolean;
  openFromReason?: OpenLeftNavFromReason;
  parentalRating: number;
  pathname: string;
  selectedOption: LeftNavMenuOption;
}

type LeftNavWrapperProps = StateProps & DispatchProp;

export const CONTAINER_REGULAR = OTT_ROUTES.containers.replace(':type', 'regular');
export const CONTAINER_CHANNEL = OTT_ROUTES.containers.replace(':type', 'channel');

interface GetActiveOptionFromLocation {
  pathname: string;
}
export function getActiveOptionFromLocation({ pathname }: GetActiveOptionFromLocation): LeftNavMenuOption | null {
  if (pathname.startsWith(OTT_ROUTES.search)) {
    return LeftNavMenuOption.Search;
  }
  if (pathname === OTT_ROUTES.home || pathname === '') {
    return LeftNavMenuOption.Home;
  }
  if (pathname.startsWith(CONTAINER_REGULAR)) {
    return LeftNavMenuOption.Categories;
  }
  if (pathname.startsWith(CONTAINER_CHANNEL)) {
    return LeftNavMenuOption.Channels;
  }
  if (pathname === OTT_ROUTES.movieMode) {
    return LeftNavMenuOption.MovieMode;
  }
  if (pathname === OTT_ROUTES.tvMode) {
    return LeftNavMenuOption.TVMode;
  }
  if (pathname === OTT_ROUTES.myStuff) {
    return LeftNavMenuOption.MyStuff;
  }
  if (pathname === OTT_ROUTES.espanolMode) {
    return LeftNavMenuOption.EspanolMode;
  }
  if (pathname === OTT_ROUTES.liveMode) {
    return LeftNavMenuOption.LiveTV;
  }
  return null;
}

class LeftNavWrapper extends Component<LeftNavWrapperProps> {
  onChange = (option: LeftNavMenuOption) => {
    this.props.dispatch(setLeftNavOption(option));
  };

  render() {
    const {
      dispatch,
      hasExitApi,
      isChannelsVisible,
      isEspanolModeVisible,
      isExpanded,
      isKidsModeEnabled,
      isKidsModeVisible,
      isLiveVisible,
      isMoviesTVShowsVisible,
      isSlowDevice,
      openFromReason,
      parentalRating,
      pathname,
      selectedOption,
    } = this.props;
    return (
      <OTTLeftNavContainer
        activeOption={getActiveOptionFromLocation({ pathname })}
        dispatch={dispatch}
        hasExitApi={hasExitApi}
        isChannelsVisible={isChannelsVisible}
        isEspanolModeVisible={isEspanolModeVisible}
        isExpanded={isExpanded}
        isKidsModeEnabled={isKidsModeEnabled}
        isKidsModeVisible={isKidsModeVisible}
        isLiveVisible={isLiveVisible}
        isMoviesTVShowsVisible={isMoviesTVShowsVisible}
        isSlowDevice={isSlowDevice}
        onChange={this.onChange}
        openFromReason={openFromReason}
        parentalRating={parentalRating}
        selectedOption={selectedOption}
      />
    );
  }
}

export function mapStateToProps(state: StoreState, { location }: Pick<WithRouterProps, 'location'>): StateProps {
  const {
    auth: { user },
    ottUI: { leftNav: { selectedOption, isExpanded, openFromReason } },
    ui: { isSlowDevice, isKidsModeEnabled, twoDigitCountryCode },
    userSettings: { parentalRating },
  } = state;
  const isLoggedIn = !!user;
  const isMoviesTVShowsVisible = !isKidsModeEnabled && isFeatureAvailableInCountry('movieTVFilters', twoDigitCountryCode);
  const isEspanolModeVisible = isSupportEspanolModeSelector(state);
  const isChannelsVisible = isFeatureAvailableInCountry('channels', twoDigitCountryCode);
  const isLiveVisible = isWebLiveNewsEnableSelector(state);
  return {
    hasExitApi: hasExitApi(),
    isChannelsVisible,
    isEspanolModeVisible,
    isExpanded,
    isKidsModeEnabled,
    isKidsModeVisible: isFeatureAvailableInCountry('kidsMode', twoDigitCountryCode),
    isLiveVisible,
    isLoggedIn,
    isMoviesTVShowsVisible,
    isSlowDevice,
    openFromReason,
    parentalRating,
    pathname: location.pathname,
    selectedOption: selectedOption || LeftNavMenuOption.Home,
  };
}

const LeftNavWrapperContainer = withRouter(connect(mapStateToProps)(LeftNavWrapper));

const shouldEnableMouseEvents = () => {
  return ENABLE_OTT_MOUSE_EVENTS;
};

/**
 * As element position:fixed will not be fixed relative to viewport once its parent has a transform property,
 * in this HOC: withLeftNav will add an extra `mainClassName` prop for inner component to apply style for
 * main content needs to be translated when left nav expands/collapses.
 **/
export interface WithLeftNavProps {
  mainClassName?: string;
}

export default function withLeftNavHOC<P>(InnerComponent: ComponentType<P & WithLeftNavProps>): FunctionComponent<P> {
  const WithLeftNav = (props: P) => {
    const isExpanded = useSelector((state: StoreState) => state.ottUI.leftNav.isExpanded);
    const dispatch = useAppDispatch();
    const mainClassName = classNames(styles.ottContent, {
      [styles.shiftRightForDeadZone]: __IS_COMCAST_PLATFORM_FAMILY__,
    });
    const childComponent = <InnerComponent {...props} mainClassName={mainClassName} />;
    let mouseEvents = {};
    if (shouldEnableMouseEvents()) {
      mouseEvents = {
        onClick: (e: React.MouseEvent<HTMLElement>) => {
          if (isExpanded) {
            // checking if element that was clicked has a parent that has data attribute: data-left-nav-container
            // data-left-nav-container data attribute is attached to the left nav container
            // packages/ott-ui/src/components/LeftNav/LeftNav.tsx
            // close left nav if user clicks outside the left nav container when it is open
            if (!(e.target as HTMLElement).closest('[data-left-nav-container]')) {
              dispatch(debouncedCloseLeftNav());
            }
          }
        },
      };
    }
    return (
      <div
        className={styles.ottContainer}
        {...mouseEvents}
      >
        <LeftNavWrapperContainer />
        {childComponent}
      </div>
    );
  };

  // copy any static properties like `fetchData` over to the wrapping component.
  hoistNonReactStatics(WithLeftNav, InnerComponent);

  return WithLeftNav;
}
