import { createSelector } from 'reselect';

import { LOGIN_WITH_AMAZON_MIN_BUILD_CODE } from 'common/constants/constants';
import { appVersionSelector } from 'common/selectors/fireUtils';
import { isAuthLoginWithAmazonEnabledSelector } from 'common/selectors/remoteConfig';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';

export function isLoginWithAmazonAvailable(buildCode?: number): boolean {
  if (!buildCode) return false;
  return __OTTPLATFORM__ === 'FIRETV_HYB' && buildCode >= LOGIN_WITH_AMAZON_MIN_BUILD_CODE;
}

export const isLoginWithAmazonAvailableSelector = createSelector(
  isAuthLoginWithAmazonEnabledSelector,
  appVersionSelector,
  (isAuthLoginWithAmazonEnabled, appVersion) => {
    if (FeatureSwitchManager.get('ForceLoginWithFlow') === 'amazon') {
      return true;
    }

    if (!isAuthLoginWithAmazonEnabled) {
      return false;
    }

    return isLoginWithAmazonAvailable(appVersion.code);
  }
);
