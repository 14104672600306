import { InputDeviceType } from '@tubitv/analytics/lib/genericEvents';
import type { AnyAction } from 'redux';

import * as actions from 'common/constants/action-types';

import type { OttPlayerState } from '../types/ottPlayer';

export const initialState = {
  progressBarPosition: 0,
  seekSpeed: 0,
  adProgress: {
    position: 0,
    duration: 0,
  },
  ad: {
    adCount: 0,
    adSequence: 0,
  },
  inputDevice: InputDeviceType.UNKNOWN_DEVICE,
  // recording last played contentId when All Episodes button clicked
  lastPlayedContentIdInEpisodes: null,
} as OttPlayerState;

export default function reducer(state: OttPlayerState = initialState, action: AnyAction = {} as AnyAction) {
  switch (action.type) {
    case actions.SET_PROGRESS_BAR_POSITION:
      return {
        ...state,
        progressBarPosition: action.position,
      };
    case actions.SET_INPUT_DEVICE:
      return {
        ...state,
        inputDevice: action.inputDevice,
      };
    case actions.SET_LAST_PLAYED_CONTENT_IN_EPISODES:
      return {
        ...state,
        lastPlayedContentIdInEpisodes: action.id,
      };
    case actions.SET_AD_VIDEO_DATA:
      const {
        adPosition: position,
        adDuration: duration,
        adCount,
        adSequence,
      } = action.data;
      return {
        ...state,
        adProgress: {
          position: parseInt(position, 10),
          duration: parseInt(duration, 10),
        },
        ad: {
          adCount,
          adSequence,
        },
      };
    default:
      return state;
  }
}
