import { Account } from '@tubitv/icons';
import { useToaster } from '@tubitv/ott-ui';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import useAppSelector from 'common/hooks/useAppSelector';

const messages = defineMessages({
  signedInHeader: {
    description: 'header text for toast notification',
    defaultMessage: 'Welcome',
  },
  signedInDescription: {
    description: 'description for toast notification',
    defaultMessage: 'Signed in as {email}',
  },
});

const MobileToOTTSignInToast = () => {
  const intl = useIntl();
  const toaster = useToaster();
  const user = useAppSelector((state) => state.userSettings);
  const showToastForMobileToOTTSignIn = useAppSelector((state) => state.ui.showToastForMobileToOTTSignIn);
  useEffect(() => {
    if (showToastForMobileToOTTSignIn) {
      toaster.displayToast({
        header: intl.formatMessage(messages.signedInHeader),
        description: intl.formatMessage(messages.signedInDescription, { email: user?.email }),
        icon: <Account />,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToastForMobileToOTTSignIn]);

  return null;
};

export default MobileToOTTSignInToast;
