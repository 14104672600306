import { ANALYTICS_COMPONENTS } from '@tubitv/analytics/lib/components';
import { DialogType } from '@tubitv/analytics/lib/dialog';
import {
  Alert,
  Categories24,
  CategoriesFilled24,
  Espanol24,
  EspanolFilled24,
  Home24,
  HomeFilled24,
  Live24,
  LiveFilled24,
  Movies24,
  MoviesFilled24,
  MyStuff24,
  MyStuffFilled24,
  Search24,
  SearchFilled24,
  Settings,
  TvShows24,
  TvShowsFilled24,
} from '@tubitv/icons';
import type { LeftNavMenuOptionConfig } from '@tubitv/ott-ui';
import { AccountMenuOption, BottomOption, LeftNav, MainOption, PROFILE_ICON_SIZE_PX } from '@tubitv/ott-ui';
import type { Location } from 'history';
import capitalize from 'lodash/capitalize';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import type { ReactElement, ReactNode } from 'react';
import React, { Component } from 'react';
import type {
  IntlShape,
  MessageDescriptor,
} from 'react-intl';
import {
  defineMessages,
  injectIntl,
} from 'react-intl';
import {
  connect,
} from 'react-redux';
import type { WithRouterProps } from 'react-router';
import { withRouter } from 'react-router';
import type { Action, AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { lazySetA11y } from 'common/actions/a11y';
import {
  closeLeftNav,
  debouncedCloseLeftNav,
  debouncedOpenLeftNav,
  setLeftNavOption,
} from 'common/actions/leftNav';
import { showExitKidsModeModal } from 'common/actions/modal';
import { KidsModeEligibilityModalTypes, showKidsModeEligibilityModal } from 'common/actions/ottUI';
import { resetApp } from 'common/actions/resetApp';
import { setActiveTileIndex } from 'common/actions/search';
import {
  exitTubi,
  setKidsMode,
  setSettingsSubpanelId,
} from 'common/actions/ui';
import { setGuestUserCoppaState } from 'common/actions/userSettings';
import {
  BACK_EVENT,
  DEFAULT_PROFILE_PIC_URL,
  ENABLE_OTT_MOUSE_EVENTS,
} from 'common/constants/constants';
import { SETTINGS_PANEL } from 'common/constants/constants-message';
import * as eventTypes from 'common/constants/event-types';
import { DISABLE_INFINITE_BACK_LOOP } from 'common/constants/platforms';
import { OTT_ROUTES } from 'common/constants/routes';
import { goToSignInPage } from 'common/features/authentication/actions/auth';
import type { User } from 'common/features/authentication/types/auth';
import {
  UserCoppaStates,
} from 'common/features/authentication/types/auth';
import { isCoppaEnabledSelector, isCoppaExitKidsModeEnabledSelector } from 'common/features/coppa/selectors/coppa';
import { ThemeContext } from 'common/features/theme/context';
import type ApiClient from 'common/helpers/ApiClient';
import tubiHistory from 'common/history';
import { isMajorEventFailsafeActiveSelector } from 'common/selectors/remoteConfig';
import { isParentalRatingTeensOrAdultsSelector } from 'common/selectors/userSettings';
import trackingManager from 'common/services/TrackingManager';
import type {
  OpenLeftNavFromReason,
} from 'common/types/ottUI';
import {
  LeftNavMenuOption,
} from 'common/types/ottUI';
import type { TubiThunkAction, TubiThunkDispatch } from 'common/types/reduxThunk';
import type { StoreState } from 'common/types/storeState';
import { doesAgeGateCookieExist, isUserNotCoppaCompliant, setLockedInKidsModeCookie } from 'common/utils/ageGate';
import { buildDialogEvent } from 'common/utils/analytics';
import { prependEventListener, removeEventListener } from 'common/utils/dom';
import { getCurrentPathname } from 'common/utils/getCurrentPathname';
import { getOTTRemote } from 'common/utils/keymap';
import { generateProfileIconUrl } from 'common/utils/profilePic';
import { isParentalRatingOlderKidsOrLess } from 'common/utils/ratings';
import { trackEvent } from 'common/utils/track';
import type { AgeGateLocationState } from 'ott/features/coppa/containers/AgeGatePage/AgeGatePage';
import type { WithToasterProps } from 'ott/HOCs/withToaster';
import withToaster from 'ott/HOCs/withToaster';
import { trackSelectLeftNav } from 'ott/utils/leftNav';
import { trackNavMenuNavigateWithinPageEvent } from 'ott/utils/navMenu';
import { FidelityLevel, isFidelityLevelMatch } from 'ott/utils/uiFidelity';

import {
  KidsIcon,
} from './Icons';
import styles from './OTTLeftNavContainer.scss';

const REMOTE = getOTTRemote();

interface StateProps {
  isCoppaEnabled: boolean;
  isLoggedIn: boolean;
  username?: string;
  searchKey: string;
  profileIconUrl?: string;
  isCoppaExitKidsModeEnabled: boolean;
  isParentalRatingTeensOrAdults: boolean;
  isFirstSession: boolean;
  isMajorEventFailsafe: boolean;
  shouldShowAccountSubtext: boolean;
  uiFidelity: FidelityLevel
}

interface OwnProps {
  // the option with a gray background when expanded / the page you are on.
  activeOption: LeftNavMenuOption | null;
  // true if the active platform supports programmatic exit
  hasExitApi: boolean;
  // true if the menu is expanded, false if not
  isExpanded: boolean;
  // show kids mode option in left nav if this is true
  isKidsModeVisible: boolean;
  // true if kids mode is enabled and false if not
  isKidsModeEnabled: boolean;
  // true if movies/tv shows buttons should be visible
  isMoviesTVShowsVisible: boolean;
  isEspanolModeVisible: boolean;
  // true if channels button should be visible
  isChannelsVisible: boolean;
  // true if live feature is enabled
  isLiveVisible: boolean;
  // true if user is logged in, false if not
  isLoggedIn: boolean;
  // true to disable transitions, etc
  isSlowDevice: boolean;
  // parental ratings mode
  parentalRating: number;
  // the option with a golden-gate color background
  selectedOption: LeftNavMenuOption;
  // function to call when selected option changed
  onChange: (option: LeftNavMenuOption) => void;
  dispatch: TubiThunkDispatch;
  intl: IntlShape;
  openFromReason?: OpenLeftNavFromReason;
  inLoginWithAmazonExperiment?: boolean;
}

export interface Props extends StateProps, OwnProps, WithRouterProps, WithToasterProps {}

const goToUrlAction = (location: Location, url: string): TubiThunkAction => (dispatch: TubiThunkDispatch): void => {
  dispatch(closeLeftNav());
  const currentPathname = location.pathname;
  if (
    (currentPathname === OTT_ROUTES.home && url !== OTT_ROUTES.home)
    || !url.startsWith(currentPathname)
  ) {
    tubiHistory.push(url);
  }
};

type MenuOptionText = MessageDescriptor | string;

interface MenuOptionConfig {
  value: LeftNavMenuOption;
  text: MenuOptionText | ((props: Props) => MenuOptionText);
  icon?: ReactNode;
  activeIcon?: ReactElement<Record<string, unknown>>;
  component: typeof MainOption | typeof BottomOption | typeof AccountMenuOption;
  action: (props: Props) => AnyAction | TubiThunkAction<AnyAction | TubiThunkAction<ThunkAction<void | Promise<unknown>, StoreState, ApiClient, Action>>> | void;
  shouldHide?: (props: Props) => boolean;
  isDisabled?: (props: Props) => boolean; // if disabled, option is still selectable and interactive
  isFree?: (props: Props) => boolean;
  additionalClass?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

const messages = defineMessages({
  signIn: {
    description: 'sign in option',
    defaultMessage: 'Sign In',
  },
  signOut: {
    description: 'sign out option',
    defaultMessage: 'Sign Out',
  },
  exitKids: {
    description: 'exit kids mode option',
    defaultMessage: 'Exit Kids',
  },
  kids: {
    description: 'enter kids mode option',
    defaultMessage: 'Kids',
  },
  search: {
    description: 'search option',
    defaultMessage: 'Search',
  },
  home: {
    description: 'home option',
    defaultMessage: 'Home',
  },
  movies: {
    description: 'movies content mode option',
    defaultMessage: 'Movies',
  },
  tvShows: {
    description: 'tv shows content mode option',
    defaultMessage: 'TV Shows',
  },
  liveTV: {
    description: 'live TV option',
    defaultMessage: 'Live TV',
  },
  myStuff: {
    description: 'my stuff option',
    defaultMessage: 'My Stuff',
  },
  categories: {
    description: 'categories option',
    defaultMessage: 'Categories',
  },
  // internally these are 'channel', externally to users they are 'networks' because 'channel' would be confusing with the Live content
  channels: {
    description: 'networks option',
    defaultMessage: 'Networks',
  },
  settings: {
    description: 'settings option',
    defaultMessage: 'Settings',
  },
  exit: {
    description: 'exit option',
    defaultMessage: 'Exit',
  },
  optionSelected: {
    description: 'accessibility voice message indicating which option is selected',
    defaultMessage: '{optionText} option selected',
  },
  optionActive: {
    description: 'accessibility voice message indicating which option is active',
    defaultMessage: '{optionText} option active',
  },
  menuOpened: {
    description: 'accessibility voice message indicating how to navigate when the menu is opened',
    defaultMessage: 'Navigation menu opened. Use up and down arrows to navigate.',
  },
  close: {
    description: 'accessibility voice message indicating how to close the menu',
    defaultMessage: 'Press back or right arrow button to close.',
  },
  loginPromptText: {
    description: 'login required for list operation',
    defaultMessage: 'You must be logged in to view your list',
  },
  menuSignIn: {
    description: 'sign in text',
    defaultMessage: 'Sign In',
  },
  free: {
    description: 'free text',
    defaultMessage: 'FREE',
  },
  welcome: {
    description: 'welcome user text',
    defaultMessage: 'Hi {userName}',
  },
  default: {
    description: 'default user name, if user has no name saved to profile',
    defaultMessage: 'User',
  },
  exitAgeGateTitle: {
    description: 'header title of exit kids mode age gate page',
    defaultMessage: 'Confirm Your Age*',
  },
  newLabel: {
    description: 'text for new label',
    defaultMessage: 'NEW',
  },
  featureUnavailable: {
    description: 'feature currently unavailable toast header',
    defaultMessage: '{feature} is currently unavailable',
  },
});

export const MENU_OPTIONS: MenuOptionConfig[] = [
  {
    value: LeftNavMenuOption.Account,
    text: ({ isLoggedIn }: Props) => {
      return isLoggedIn ? messages.signOut : messages.signIn;
    },
    component: AccountMenuOption,
    action: ({ isLoggedIn, dispatch }: Props) => {
      if (!isLoggedIn) {
        /* istanbul ignore next */
        return goToSignInPage();
      }
      dispatch(setSettingsSubpanelId(SETTINGS_PANEL.signOut.id));
      tubiHistory.push(OTT_ROUTES.settings);
    },
    isFree: ({ shouldShowAccountSubtext }) => shouldShowAccountSubtext,
    shouldHide: ({ isKidsModeEnabled }) => (isKidsModeEnabled && isUserNotCoppaCompliant()),
  },
  {
    value: LeftNavMenuOption.Kids,
    text: ({ isKidsModeEnabled }) => {
      return isKidsModeEnabled ? messages.exitKids : messages.kids;
    },
    icon: <KidsIcon />,
    component: MainOption,
    action: ({
      dispatch,
      intl,
      isLoggedIn,
      isKidsModeEnabled,
      isCoppaExitKidsModeEnabled,
      isParentalRatingTeensOrAdults,
      location,
    }) => {
      const isDefaultMode = !isKidsModeEnabled;
      const hasAgeGateCookie = doesAgeGateCookieExist();
      const isAgeGateRequired = !hasAgeGateCookie && isCoppaExitKidsModeEnabled;

      const toggleKidsMode = (targetUrl?: string) => {
        // Use dialog event to track toggling kids mode actions
        const dialogType = isDefaultMode ? DialogType.ENTER_KIDS_MODE : DialogType.EXIT_KIDS_MODE;
        const dialogEventObj = buildDialogEvent(getCurrentPathname(), dialogType);

        if (dialogEventObj) {
          trackEvent(eventTypes.DIALOG, dialogEventObj);
        }

        dispatch(setKidsMode(isDefaultMode));
        return resetApp(location, targetUrl);
      };

      if (isDefaultMode) {
        return toggleKidsMode();
      }

      if (isAgeGateRequired) {
        const ageGateState: AgeGateLocationState = {
          headerText: intl.formatMessage(messages.exitAgeGateTitle),
          forceYearOfBirth: true,
          noBackground: true,
          onUserCompliant: () => {
            setGuestUserCoppaState(dispatch, UserCoppaStates.COMPLIANT);
            dispatch(toggleKidsMode());
          },
          onUserNotCompliant: () => {
            setGuestUserCoppaState(dispatch, UserCoppaStates.NOT_COMPLIANT);
            setLockedInKidsModeCookie();
            dispatch(showKidsModeEligibilityModal(KidsModeEligibilityModalTypes.CANNOT_EXIT));
            tubiHistory.push(OTT_ROUTES.home);
          },
        };
        tubiHistory.push({
          pathname: OTT_ROUTES.ageGate,
          state: ageGateState,
        });
        return;
      }

      // A logged-in user with the parentalRating is teens or adults or a guest user who has
      // passed the age gate, he will exit the kids mode directly without seeing the confirm pop-up.
      // Please note, isCoppaExitKidsModeEnabled contains the logic of determining if the user
      // is a guest user.
      if ((isLoggedIn && isParentalRatingTeensOrAdults) || (isCoppaExitKidsModeEnabled && hasAgeGateCookie)) {
        return toggleKidsMode();
      }

      return showExitKidsModeModal({
        dispatch,
        exit: toggleKidsMode,
      });
    },
    isDisabled: ({ parentalRating }) => {
      return isParentalRatingOlderKidsOrLess(parentalRating);
    },
    shouldHide: ({ isKidsModeEnabled, isKidsModeVisible }) => (isKidsModeEnabled && isUserNotCoppaCompliant()) || !isKidsModeVisible,
  },
  {
    value: LeftNavMenuOption.Search,
    text: messages.search,
    icon: <Search24 />,
    activeIcon: <SearchFilled24 />,
    component: MainOption,
    action: ({ dispatch, location, toaster, intl, isMajorEventFailsafe }) => {
      if (isMajorEventFailsafe) {
        toaster.displayToast({
          header: intl.formatMessage(messages.featureUnavailable, { feature: intl.formatMessage(messages.search) }),
          icon: <Alert />,
        });
        return;
      }
      // If going to the search page from left nav, clear out the previously selected tile in the search results grid
      // so that the keyboard gets focus first.
      dispatch(setActiveTileIndex(null));
      return goToUrlAction(location, OTT_ROUTES.search);
    },
  },
  {
    value: LeftNavMenuOption.Home,
    text: messages.home,
    icon: <Home24 />,
    activeIcon: <HomeFilled24 />,
    component: MainOption,
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.home),
  },
  {
    value: LeftNavMenuOption.Categories,
    text: messages.categories,
    icon: <Categories24 />,
    activeIcon: <CategoriesFilled24 />,
    component: MainOption,
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.containers.replace(':type', 'regular')),
  },
  {
    value: LeftNavMenuOption.MyStuff,
    text: messages.myStuff,
    icon: <MyStuff24 />,
    activeIcon: <MyStuffFilled24 />,
    component: MainOption,
    shouldHide: /* istanbul ignore next */ ({ isKidsModeEnabled }) => {
      return isKidsModeEnabled && isUserNotCoppaCompliant();
    },
    action: /* istanbul ignore next */ ({ location, toaster, intl, isMajorEventFailsafe }) => {
      if (isMajorEventFailsafe) {
        toaster.displayToast({
          header: intl.formatMessage(messages.featureUnavailable, { feature: intl.formatMessage(messages.myStuff) }),
          icon: <Alert />,
        });
        return;
      }
      return goToUrlAction(location, OTT_ROUTES.myStuff);
    },
  },
  {
    value: LeftNavMenuOption.MovieMode,
    text: messages.movies,
    icon: <Movies24 />,
    activeIcon: <MoviesFilled24 />,
    component: MainOption,
    shouldHide: ({ isMoviesTVShowsVisible }) => {
      return !isMoviesTVShowsVisible;
    },
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.movieMode),
  },
  {
    value: LeftNavMenuOption.TVMode,
    text: messages.tvShows,
    icon: <TvShows24 />,
    activeIcon: <TvShowsFilled24 />,
    component: MainOption,
    shouldHide: ({ isMoviesTVShowsVisible }) => {
      return !isMoviesTVShowsVisible;
    },
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.tvMode),
  },
  {
    value: LeftNavMenuOption.LiveTV,
    text: messages.liveTV,
    icon: <Live24 />,
    activeIcon: <LiveFilled24 />,
    component: MainOption,
    shouldHide: ({ isLiveVisible, isKidsModeEnabled }) => {
      return isKidsModeEnabled || !isLiveVisible;
    },
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.liveMode),
  },
  {
    value: LeftNavMenuOption.EspanolMode,
    text: 'Español',
    icon: <Espanol24 />,
    activeIcon: <EspanolFilled24 />,
    component: MainOption,
    shouldHide: ({ isEspanolModeVisible }) => !isEspanolModeVisible,
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.espanolMode),
  },
  {
    value: LeftNavMenuOption.Dev,
    text: 'Dev',
    component: MainOption,
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.dev),
    shouldHide: () => !(__DEVELOPMENT__ || __STAGING__ || __IS_ALPHA_ENV__),
  },
  {
    value: LeftNavMenuOption.Settings,
    text: messages.settings,
    component: BottomOption,
    icon: <Settings />,
    action: ({ location }) => goToUrlAction(location, OTT_ROUTES.settings),
    additionalClass: styles.settingsOption,
  },
];

export class OTTLeftNavContainerClass extends Component<Props> {
  static defaultProps = {
    isExpanded: false,
  };

  static contextType = ThemeContext;

  context!: React.ContextType<typeof ThemeContext>;

  private readonly menuOptions: MenuOptionConfig[] = [];

  constructor(props: Props) {
    super(props);
    this.menuOptions = this.generateMenuOptions();
  }

  componentDidMount() {
    this.handleUpdateA11y();
    const { isExpanded } = this.props;
    if (isExpanded) {
      this.attachEventListeners();
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.handleUpdateA11y(prevProps);
    const { isExpanded } = this.props;
    if (isExpanded === prevProps.isExpanded) return;
    if (isExpanded) {
      this.attachEventListeners();
    } else {
      this.detachEventListeners();
    }
  }

  componentWillUnmount() {
    this.detachEventListeners();
  }

  shouldEnableMouseEvents = () => {
    return ENABLE_OTT_MOUSE_EVENTS;
  };

  generateMenuOptions = () => {
    if (this.shouldEnableMouseEvents()) {
      return MENU_OPTIONS.map((menuOption) => {
        const newMenuOption = {
          ...menuOption,
          onClick: () => {
            if (this.props.isExpanded) {
              this.handleSelect();
            }
          },
          onMouseEnter: () => {
            if (this.props.isExpanded) {
              this.props.onChange(menuOption.value);
            }
          },
        };
        return newMenuOption;
      });
    }
    return MENU_OPTIONS;
  };

  attachEventListeners() {
    prependEventListener(window, 'keydown', this.handleKeyDown);
    prependEventListener(window, BACK_EVENT, this.handleBackEvent);
  }

  detachEventListeners() {
    removeEventListener(window, 'keydown', this.handleKeyDown);
    removeEventListener(window, BACK_EVENT, this.handleBackEvent);
  }

  handleBackEvent = (e: Event) => {
    const { activeOption, dispatch, hasExitApi } = this.props;
    // keep in mind this method will only be called when the nav is expanded
    // because the event listeners only get registered when that is the case
    e.preventDefault();
    e.stopImmediatePropagation();
    if (activeOption !== LeftNavMenuOption.Home) {
      // go to home directly
      dispatch(setLeftNavOption(LeftNavMenuOption.Home));
      tubiHistory.replace(OTT_ROUTES.home);
    } else {
      // should exit Tubi if back click on the open left nav while the focused element is 'Home'
      if (DISABLE_INFINITE_BACK_LOOP) {
        dispatch(exitTubi(hasExitApi));
        return;
      }
      dispatch(debouncedCloseLeftNav());
    }
  };

  handleSelect = (): void => {
    const { dispatch, selectedOption, intl, searchKey } = this.props;
    const menuConfig = this.menuOptions.find(({ value }) => selectedOption === value);
    /* istanbul ignore if: this is just in case, and proved too hard to test */
    if (!menuConfig) {
      throw new Error(`Unknown selection option: ${LeftNavMenuOption[selectedOption]}`);
    }
    dispatch(lazySetA11y(() => Promise.resolve({
      text: intl.formatMessage(messages.optionSelected, { optionText: this.getTextForOptionConfig(menuConfig) }),
    })));

    // track selection of left nav option
    trackSelectLeftNav(selectedOption, { query: searchKey });
    // track navigateToPage event
    trackingManager.createNavigateToPageComponent({
      endX: 0,
      endY: selectedOption,
      componentType: ANALYTICS_COMPONENTS.leftSideNavComponent,
    });
    const menuAction = menuConfig.action(this.props);
    if (menuAction) {
      dispatch(menuAction);
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const { keyCode } = e;
    const { dispatch, onChange } = this.props;
    const currentIndex = this.getSelectedMenuIndex();
    let nextIndex = currentIndex;
    let wasSelected = false;
    switch (keyCode) {
      case REMOTE.arrowLeft:
      case REMOTE.arrowRight:
        if (!e.repeat) {
          dispatch(debouncedCloseLeftNav());
        }
        break;
      case REMOTE.arrowUp:
        nextIndex = findLastIndex(this.menuOptions, this.isMenuItemInteractive, Math.max(0, currentIndex - 1));
        nextIndex = nextIndex === -1 ? currentIndex : nextIndex;
        break;
      case REMOTE.arrowDown:
        nextIndex = findIndex(this.menuOptions, this.isMenuItemInteractive, nextIndex + 1);
        nextIndex = nextIndex === -1 ? findLastIndex(this.menuOptions, this.isMenuItemInteractive) : nextIndex;
        break;
      case REMOTE.enter:
        wasSelected = true;
        break;
      default:
        return;
    }

    if (wasSelected) {
      this.handleSelect();
    } else if (currentIndex !== nextIndex) {
      onChange(this.menuOptions[nextIndex].value);
      dispatch((_: unknown, getState: () => StoreState) => {
        trackNavMenuNavigateWithinPageEvent({
          meansOfNavigation: 'SCROLL',
          navComponentSectionIndex: this.menuOptions[nextIndex].value,
          navComponentType: ANALYTICS_COMPONENTS.leftSideNavComponent,
          overrideVerticalLocation: nextIndex + 1,
        }, getState());
      });
    }
    e.preventDefault();
    e.stopImmediatePropagation();
  };

  getSelectedMenuIndex(): number {
    const { selectedOption } = this.props;
    const selectedOptionPredicate = ({ value, shouldHide }: MenuOptionConfig) => {
      const isVisible = shouldHide ? !shouldHide(this.props) : true;
      return value === selectedOption && isVisible;
    };
    return this.menuOptions.findIndex(selectedOptionPredicate);
  }

  getSelectedMenuOptionConfig(): MenuOptionConfig | undefined {
    return this.menuOptions[this.getSelectedMenuIndex()];
  }

  handleUpdateA11y(prevProps?: Props): void {
    const { dispatch, isExpanded, selectedOption, intl: { formatMessage } } = this.props;
    // if just expanded
    if (isExpanded && !prevProps?.isExpanded) {
      dispatch(lazySetA11y(() => Promise.resolve({
        onceBefore: formatMessage(messages.menuOpened),
        text: formatMessage(messages.optionActive, { optionText: this.getTextForOptionConfig(this.getSelectedMenuOptionConfig()) }),
        onceAfter: formatMessage(messages.close),
      })));
      return; // no point in updating the text twice
    }
    if (prevProps && selectedOption !== prevProps.selectedOption) {
      this.props.dispatch(lazySetA11y(() => Promise.resolve({
        text: this.getTextForOptionConfig(this.getSelectedMenuOptionConfig()),
      })));
    }
  }

  getTextForOptionConfig(option: MenuOptionConfig | undefined): string {
    if (!option) return '';
    const { text } = option;
    const { intl } = this.props;
    const message = typeof text === 'function' ? text(this.props) : text;
    return typeof message === 'string' ? message : intl.formatMessage(message);
  }

  isMenuItemInteractive = ({ shouldHide }: MenuOptionConfig): boolean => {
    if (shouldHide) {
      return !shouldHide(this.props);
    }
    return true;
  };

  handleOnLeftNavClick = () => {
    this.props.dispatch(debouncedOpenLeftNav());
  };

  render() {
    const { isExpanded, selectedOption, activeOption, isSlowDevice, intl,
      username, isLoggedIn, profileIconUrl, shouldShowAccountSubtext, uiFidelity,
    } = this.props;
    const filteredMenuOptions: LeftNavMenuOptionConfig[] = this.menuOptions
      .filter(({ shouldHide }) => !shouldHide || (typeof shouldHide === 'function' && !shouldHide(this.props)))
      .map(({ value, text, icon, activeIcon, component, additionalClass, isDisabled, isFree, onClick, onMouseEnter }) => {
        const isOptionDisabled = typeof isDisabled === 'function' ? isDisabled(this.props) : false;
        const message = typeof text === 'function' ? text(this.props) : text;
        const textStr = typeof message === 'string' ? message : intl.formatMessage(message);
        return {
          value,
          icon,
          activeIcon,
          component,
          text: textStr,
          isDisabled: isOptionDisabled,
          isFree: isFree?.(this.props),
          className: additionalClass,
          onClick,
          onMouseEnter,
        };
      });
    const userName = capitalize(username) || intl.formatMessage(messages.default);
    const signInText = intl.formatMessage(messages.menuSignIn);
    const accountMenuText = isLoggedIn
      ? intl.formatMessage(messages.welcome, { userName })
      : signInText;
    const accountSubtext = shouldShowAccountSubtext ? intl.formatMessage(messages.menuSignIn) : '';
    const animated = isFidelityLevelMatch(uiFidelity, FidelityLevel.High);
    return (
      <LeftNav
        design="overlay"
        className={styles.ottLeftNav}
        noTransition={isSlowDevice}
        addLeftMargin={__IS_COMCAST_PLATFORM_FAMILY__}
        disableTransition={__OTTPLATFORM__ === 'PS4'}
        // PS4 and Sony are known to have problems rendering the full screen
        // position: fixed backdrop
        enableExpandedBackground={__OTTPLATFORM__ !== 'PS4' && __OTTPLATFORM__ !== 'SONY'}
        animated={animated}
        isExpanded={isExpanded}
        selectedOption={selectedOption}
        newLabelText={intl.formatMessage(messages.newLabel)}
        freeLabelText={intl.formatMessage(messages.free)}
        activeOption={activeOption}
        profileIconUrl={profileIconUrl}
        defaultProfilePicURL={DEFAULT_PROFILE_PIC_URL}
        menuOptions={filteredMenuOptions}
        accountMenuText={accountMenuText}
        shouldEnableMouseEvents={this.shouldEnableMouseEvents()}
        onContainerClick={this.handleOnLeftNavClick}
        accountSubtext={accountSubtext}
      />
    );
  }
}

export function mapStateToProps(state: StoreState): StateProps {
  const isCoppaEnabled = isCoppaEnabledSelector(state);
  const isCoppaExitKidsModeEnabled = isCoppaExitKidsModeEnabledSelector(state);
  const isParentalRatingTeensOrAdults = isParentalRatingTeensOrAdultsSelector(state);
  const { userSettings, search: { key: searchKey }, fire: { isFirstSession }, ui: { uiFidelity } } = state;
  const user = state.auth.user as User;
  return {
    isCoppaEnabled,
    isLoggedIn: !!user,
    username: userSettings.first_name || user?.name,
    searchKey,
    isCoppaExitKidsModeEnabled,
    isParentalRatingTeensOrAdults,
    isFirstSession,
    isMajorEventFailsafe: isMajorEventFailsafeActiveSelector(state),
    profileIconUrl: generateProfileIconUrl(userSettings.profilePic || null, PROFILE_ICON_SIZE_PX),
    shouldShowAccountSubtext: !user,
    uiFidelity,
  };
}

const OTTLeftNavContainer = withRouter(connect(mapStateToProps)(withToaster(injectIntl(OTTLeftNavContainerClass))));

export default OTTLeftNavContainer as React.FC<Omit<Partial<Props>, 'dispatch' | 'intl'> & { dispatch?: OwnProps['dispatch'] }>;
