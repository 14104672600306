import type { AnyAction } from 'redux';

import * as actions from 'common/constants/action-types';
import type { PmrState } from 'common/types/pmr';

export const initialState: PmrState = {
  loaded: false,
  contentIds: [],
  contentIdMap: {},
};

export default function PmrReducer(state: PmrState = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.LOAD_PMR_SUCCESS:
      return {
        ...state,
        loaded: true,
        contentIds: action.contentIds,
        contentIdMap: action.contentIdMap,
      };
    default:
      return state;
  }
}
