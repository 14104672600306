import {
  getConfig,
  FIRETV_ONBOARDING_PERSONALIZATION,
} from 'common/experiments/config/ottFireTVTitleOnboardingPersonalization';
import { popperExperimentsSelector } from 'common/selectors/experiments';
import type { StoreState } from 'common/types/storeState';

export const ottFireTVTitleOnboardingPersonalizationSelector = (state: StoreState) => popperExperimentsSelector(state, {
  ...FIRETV_ONBOARDING_PERSONALIZATION,
  config: getConfig(),
});
