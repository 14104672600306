declare global {
  interface Window {
    Tubi: any;
    TUBI_WEB_FQDN: string | undefined;
    DD_SERVICE: string;
    wpf?: {
      player: {
        WPFPlayer: new (el: HTMLElement, config: Record<string, any>) => {
          load: () => void;
          removePlayer: () => void;
        };
      }
    }
  }
}

export const initGlobalMethods = () => {
  if (!window.Tubi) {
    window.Tubi = {};
  }
};

export function exposeToTubiGlobal(exposedObjects: object) {
  if (!__SERVER__ && (!__PRODUCTION__ || __IS_ALPHA_ENV__)) {
    window.Tubi = {
      ...window.Tubi,
      ...exposedObjects,
    };
  }
}
