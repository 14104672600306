import { ANALYTICS_DESTINATION_COMPONENTS } from '@tubitv/analytics/lib/components';
import debounce from 'lodash/debounce';

import * as actions from 'common/constants/action-types';
import { KEY_REPEAT_DEBOUNCE_TIME } from 'common/constants/constants';
import type { LeftNavMenuOption, OpenLeftNavFromReason } from 'common/types/ottUI';
import type { TubiThunkAction, TubiThunkDispatch } from 'common/types/reduxThunk';
import type { StoreState } from 'common/types/storeState';
import { mapLeftNavComponentType } from 'common/utils/analytics';
import { trackCloseLeftNav, trackOpenLeftNav } from 'ott/utils/leftNav';
import { trackNavMenuNavigateWithinPageEvent } from 'ott/utils/navMenu';

export interface OpenLeftNavAction {
  type: typeof actions.OPEN_LEFT_NAV;
  selectedOption?: LeftNavMenuOption;
  openFromReason?: OpenLeftNavFromReason;
}

export interface CloseLeftNavAction {
  type: typeof actions.CLOSE_LEFT_NAV;
}

export interface SetLeftNavOptionAction {
  type: typeof actions.SET_LEFT_NAV_OPTION;
  selectedOption: LeftNavMenuOption | null;
}

interface OpenOrCloseLeftNavParams {
  action: 'open' | 'close';
  desiredOption?: LeftNavMenuOption | null;
  dispatch: TubiThunkDispatch;
  getState: () => StoreState;
}

function openOrCloseLeftNav({ action, desiredOption, dispatch }: OpenOrCloseLeftNavParams) {
  if (action === 'open') {
    dispatch(openLeftNav(desiredOption));
  } else {
    dispatch(closeLeftNav());
  }
}

const debouncedOpenOrCloseLeftNav = debounce(
  openOrCloseLeftNav,
  KEY_REPEAT_DEBOUNCE_TIME,
  { leading: true, trailing: false },
);

/**
 * Open the left nav.
 *
 * @param desiredOption An optional option to select with a visual highlight
 * once the nav is open. If not supplied, the current selected option will be
 * used.
 */
export const openLeftNav = (desiredOption?: LeftNavMenuOption | null, openFromReason?: OpenLeftNavFromReason): TubiThunkAction => {
  return (dispatch: TubiThunkDispatch, getState: () => StoreState) => {
    // open left nav with the desiredOption highlighted
    // or use previously selected option as the active one
    // if desiredOption is not provided.
    const { ottUI: { leftNav: { selectedOption } }, search: { key: query } } = getState();

    // TODO: This option value should be based on position in the left nav, not the value
    const option = desiredOption == null ? selectedOption : desiredOption;
    const extraCtx = query ? { query } : undefined;
    trackOpenLeftNav(option, extraCtx);
    trackNavMenuNavigateWithinPageEvent({
      componentType: mapLeftNavComponentType(option),
      meansOfNavigation: 'BUTTON',
      destinationComponentSectionIndex: option as number,
      destinationComponentType: ANALYTICS_DESTINATION_COMPONENTS.destinationLeftSideNavComponent,
      overrideVerticalLocation: option ? option + 1 : undefined,
    }, getState());

    dispatch({
      type: actions.OPEN_LEFT_NAV,
      selectedOption: option,
      openFromReason,
    });
  };
};

/**
 * Open the left nav for a user interaction like a key or back button press.
 * Mutually debounced along with debouncedCloseLeftNav().
 *
 * @param desiredOption An optional option to select with a visual highlight
 * once the nav is open. If not supplied, the current selected option will be
 * used.
 */
export const debouncedOpenLeftNav = (desiredOption?: LeftNavMenuOption): TubiThunkAction => {
  return (dispatch: TubiThunkDispatch, getState: () => StoreState) => {
    debouncedOpenOrCloseLeftNav({ action: 'open', desiredOption, dispatch, getState });
  };
};

/**
 * Close the left nav.
 */
export const closeLeftNav = (): TubiThunkAction => {
  return (dispatch: TubiThunkDispatch, getState: () => StoreState) => {
    const { ottUI: { leftNav: { selectedOption } }, search: { key: query } } = getState();
    const extraCtx = query ? { query } : undefined;
    trackCloseLeftNav(selectedOption, extraCtx);
    dispatch({
      type: actions.CLOSE_LEFT_NAV,
    });
  };
};

/**
 * Close the left nav for a user interaction like a key or back button press.
 * Mutually debounced along with debouncedOpenLeftNav().
 */
export const debouncedCloseLeftNav = (): TubiThunkAction => {
  return (dispatch: TubiThunkDispatch, getState: () => StoreState) => {
    debouncedOpenOrCloseLeftNav({ action: 'close', dispatch, getState });
  };
};

export const setLeftNavOption = (selectedOptionParam: LeftNavMenuOption | null): SetLeftNavOptionAction => {
  return {
    type: actions.SET_LEFT_NAV_OPTION,
    selectedOption: selectedOptionParam,
  };
};
