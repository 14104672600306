import { now } from '@adrise/utils/lib/time';
import { useRef, useEffect } from 'react';

import { trackEmptyBackground } from 'client/features/playback/track/client-log/trackEmptyBackground';

const useTrackEmptyBackgroundMetric = (isBackgroundImageVisible: boolean, isPreviewPlaying: boolean) => {
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (!isBackgroundImageVisible && !isPreviewPlaying && !startTimeRef.current) {
      startTimeRef.current = now();
    }
  }, [isBackgroundImageVisible, isPreviewPlaying]);

  useEffect(() => {
    if ((isBackgroundImageVisible || isPreviewPlaying) && startTimeRef.current) {
      const timeElapsed = now() - startTimeRef.current;
      trackEmptyBackground({ timeElapsed, isBackgroundImageVisible, isPreviewPlaying });
      startTimeRef.current = null;
    }
  }, [isBackgroundImageVisible, isPreviewPlaying]);
};

export default useTrackEmptyBackgroundMetric;
