import { clearAllExposuresSeen } from 'common/components/ExposureLogOverlay/storeExposures';
import type { FeatureSwitchOptionOnSelect } from 'common/services/FeatureSwitchManager';

const onResetSelect: FeatureSwitchOptionOnSelect = ({ dispatch }) => {
  clearAllExposuresSeen();
  // loading this file async to try to avoid a dependency circular reference.
  return import('common/actions/experiments').then(({ clearAllLoggedExposures }) => { dispatch(clearAllLoggedExposures()); });
};

export default onResetSelect;
