import { useEffect, useRef } from 'react';

function useDebounce<T>(value: T, callback: (value: T) => void, delay: number): void {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      callback(value);
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [value, callback, delay]);
}

export default useDebounce;
