import { DEBUGGER_LAUNCHER_URL } from 'common/constants/constants';
import { getRemoteDebuggerStatus } from 'common/utils/debug';

export const setupRemoteDebugger = () => {
  if ((__STAGING__ || __DEVELOPMENT__ || __IS_ALPHA_ENV__) && getRemoteDebuggerStatus()) {
    const remoteDebuggerScript = document.createElement('script');
    remoteDebuggerScript.src = DEBUGGER_LAUNCHER_URL;
    remoteDebuggerScript.async = true;
    remoteDebuggerScript.dataset.origin = 'debugger';
    document.head.appendChild(remoteDebuggerScript);
  }
};
