import { buildQueryString } from '@adrise/utils/lib/queryString';
import { years } from '@adrise/utils/lib/time';
import Cookie from 'react-cookie';

import { COOKIE_SERVED_OTT_LANDING_PAGE } from 'common/constants/cookies';
import { OTT_ROUTES } from 'common/constants/routes';
import { isLoggedInSelector } from 'common/features/authentication/selectors/auth';
import { isGDPREnabledSelector } from 'common/features/gdpr/selectors/gdpr';
import tubiHistory from 'common/history';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';
import type { TubiStore } from 'common/types/storeState';
import { getCurrentPathname } from 'common/utils/getCurrentPathname';
import { routeContainsKidsMode } from 'common/utils/landingPageTools';
import { isMajorEventOnboardingActive } from 'common/utils/onboarding';
import { getOttOnboardingRoute } from 'ott/utils/onboarding';

export const setupOTTLandingPage = (store: TubiStore) => {
  const state = store.getState();
  const location = tubiHistory.getCurrentLocation();
  if (
    getCurrentPathname() !== OTT_ROUTES.home
      || routeContainsKidsMode(location.search)
      || isGDPREnabledSelector(state)
      || FeatureSwitchManager.isDisabled('LandingPage')
      || location.query.qaSuitest === 'true'
      || isLoggedInSelector(state)
  ) {
    return;
  }

  const servedLandingPageCookie: undefined | string = Cookie.load(COOKIE_SERVED_OTT_LANDING_PAGE);
  if (!servedLandingPageCookie) {
    Cookie.save(COOKIE_SERVED_OTT_LANDING_PAGE, 'true', {
      // User would not see the landing page again
      maxAge: years(100),
      path: '/',
    });
    const redirectBaseUrl = getOttOnboardingRoute(isMajorEventOnboardingActive(state));
    tubiHistory.replace(`${redirectBaseUrl}${buildQueryString(location.query)}`);
  }
};
