/* istanbul ignore file */
import React from 'react';

/**
 * Some of the following icons has nested <svg> tag inside with a Y offset. This is for
 * the purpose of better center aligning with text in the LeftNav.
 *
 * All svg icons are 40x40 in dimension, but the whitespaces around shapes are not even, because:
 *
 * - Files come from designers is not aligned right (Next time we can remind designer to do that).
 * - Some shapes are centered in numbers but not as so for human eyes, because the
 *   weight distributions of the shapes are not even (think about pointy top and flat bottom).
 *
 * We need to center the shapes inside the svg icon based on their 'center of gravity'.
 * In practice, we can just adjust the shapes offset manually till it's correct through our eyes. This is done by
 * adding another <svg> tag with `x` and `y` attributes to wrap around shapes. Or a <g> with `transform` attribute.
 */

export function KidsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="currentColor" d="M5.5 9l.025-.002C5.781 12.349 8.584 15 12 15c3.416 0 6.219-2.651 6.475-6.002L18.5 9C20.43 9 22 7.43 22 5.5S20.43 2 18.5 2a3.474 3.474 0 00-2.992 1.723C14.531 3.258 13.35 3 12 3s-2.531.258-3.508.723A3.474 3.474 0 005.5 2C3.57 2 2 3.57 2 5.5S3.57 9 5.5 9zm13-5c.827 0 1.5.673 1.5 1.5S19.327 7 18.5 7c-.069 0-.135-.011-.202-.02a4.869 4.869 0 00-1.161-2.098A1.499 1.499 0 0118.5 4zM12 5c1.056 0 4.5.252 4.5 3.5 0 2.481-2.019 4.5-4.5 4.5a4.505 4.505 0 01-4.5-4.5C7.5 5.252 10.944 5 12 5zM5.5 4c.606 0 1.127.363 1.363.882A4.858 4.858 0 005.702 6.98c-.067.009-.133.02-.202.02C4.673 7 4 6.327 4 5.5S4.673 4 5.5 4z" />
      <path fill="currentColor" d="M11.817 12h.367a.816.816 0 00.816-.817v-.367a.816.816 0 00-.816-.816h-.367a.817.817 0 00-.817.816v.367c0 .451.366.817.817.817zM16.301 14.978l-1.323 1.323a.999.999 0 101.414 1.414l1.323-1.323c.504-.506 1.386-.506 1.893 0 .253.253.392.589.392.946s-.139.694-.392.947l-1.323 1.322a1.326 1.326 0 01-.947.393H6.662c-.357 0-.694-.14-.947-.393l-1.322-1.322A1.325 1.325 0 014 17.338c0-.358.139-.693.393-.946.505-.506 1.387-.506 1.893 0l1.323 1.323a.999.999 0 101.414-1.414L7.7 14.978c-1.261-1.26-3.46-1.261-4.722 0A3.314 3.314 0 002 17.338c0 .891.347 1.73.978 2.361L4.3 21.022c.631.631 1.47.978 2.362.978h10.677c.892 0 1.731-.348 2.361-.979l1.323-1.322c.63-.63.977-1.469.977-2.361 0-.892-.347-1.73-.978-2.36-1.262-1.262-3.46-1.261-4.721 0z" />
    </svg>
  );
}

export function MyListIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path transform="translate(3.5 2)" fill="currentColor" d="M16 15.28v-8.8c0-1.66-.12-3.54-1.63-4.94C12.92.19 11 0 9.11 0H6.87C5 0 3.07.19 1.63 1.54.12 2.92 0 4.8 0 6.44V17.5c0 .84.45 1.63 1.2 2.09.75.46 1.7.54 2.52.21l.16-.07 3.95-3.77c.1-.06.24-.06.34 0l3.95 3.77c.05.02.1.06.16.07a2.81 2.81 0 002.52-.21A2.47 2.47 0 0016 17.5v-2.22zm-3.17 1.92l-3.44-3.47a2.81 2.81 0 00-2.78 0L3.17 17.2c-.22.13-.5-.02-.5-.27V6.43c0-1.7.22-2.55.84-3.12.62-.58 1.54-.81 3.36-.81H9.1c1.84 0 2.76.21 3.38.8.62.58.84 1.44.84 3.17v10.47c0 .23-.28.38-.5.26z" />
    </svg>
  );
}

