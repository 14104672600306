const mimeTypes = [
  'video/mp4;codecs="avc1.42E01E, mp4a.40.2"',
  'video/mp4;codecs="avc1.58A01E, mp4a.40.2"',
  'video/mp4;codecs="avc1.4D401E, mp4a.40.2"',
  'video/mp4;codecs="avc1.64001E, mp4a.40.2"',
  'video/mp4;codecs="mp4v.20.8, mp4a.40.2"',
  'video/mp4;codecs="mp4v.20.240, mp4a.40.2"',
  'video/3gpp;codecs="mp4v.20.8, samr"',
  'video/ogg;codecs="theora, vorbis"',
  'video/ogg;codecs="theora, speex"',
  'audio/ogg;codecs=vorbis',
  'audio/ogg;codecs=speex',
  'audio/ogg;codecs=flac',
  'video/ogg;codecs="dirac, vorbis"',
  'video/x-matroska;codecs="theora, vorbis"',
  'video/mp4; codecs="avc1.42E01E"',
  'audio/mp4; codecs="mp4a.40.2"',
  'audio/mpeg;codecs="mp3"',
  'video/webm; codecs="vorbis,vp8"',
] as const;

const CANNOT_PLAY = 'no' as const;

type CanPlayResult = CanPlayTypeResult | typeof CANNOT_PLAY;

export type PlayableMediaTypes = Partial<Record<typeof mimeTypes[number], CanPlayResult>>;

// Adapted from https://html5.komplett.cc/code/chap_video/js_canPlayType.html
export function getPlayableMediaTypes(): PlayableMediaTypes {
  const video = document.createElement('VIDEO') as HTMLVideoElement;
  const canPlayType: PlayableMediaTypes = {};
  for (const mimeType of mimeTypes) {
    canPlayType[mimeType] = video.canPlayType(mimeType) || CANNOT_PLAY;
  }
  return canPlayType;
}
