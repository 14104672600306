import type React from 'react';
import { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { hideModal, showNotFoundModal } from 'common/actions/modal';
import { setNotFound } from 'common/actions/ui';
import { OTT_ROUTES } from 'common/constants/routes';
import { FIRETV_ERROR_MODAL_REDESIGN_VALUE } from 'common/experiments/config/ottFireTVErrorModalRedesign';
import useExplorePopular from 'common/features/playback/hooks/useExplorePopular';
import tubiHistory from 'common/history';
import { useAppDispatch } from 'common/hooks/useAppDispatch';

const messages = defineMessages({
  home: {
    description: 'not found modal, button text to go to home page',
    defaultMessage: 'Go to Homepage',
  },
  explore: {
    description: 'Explore Popular Titles button text',
    defaultMessage: 'Explore Popular Titles',
  },
});

const OTTNotFound: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { expGroup, explorePopular, logExposure } = useExplorePopular();
  const showExploreBtn = expGroup !== FIRETV_ERROR_MODAL_REDESIGN_VALUE.CONTROL;
  useEffect(() => {
    logExposure();
    const buttons = [{
      text: intl.formatMessage(messages.home),
      action: () => {
        dispatch(setNotFound(false)); // prevent showing this modal repeatedly
        dispatch(hideModal());
        tubiHistory.replace(OTT_ROUTES.home);
      },
    }];

    if (showExploreBtn) {
      buttons.unshift({
        text: intl.formatMessage(messages.explore),
        action: () => {
          dispatch(setNotFound(false)); // prevent showing this modal repeatedly
          explorePopular();
        },
      });
    }

    dispatch(showNotFoundModal({
      buttons,
      // This function handles back button press when the Not found modal is active
      // Take the user to home page when content not found
      onClose: () => {
        dispatch(setNotFound(false));
        tubiHistory.replace(OTT_ROUTES.home);
      },
    }));
  }, [dispatch, intl, showExploreBtn, logExposure, explorePopular]);

  return null;
};

export default OTTNotFound;
