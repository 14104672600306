import logger from 'common/helpers/logging';

type Reviver = (key: string, value: any) => any;

export function tryJSONParse(text: string, reviver: Reviver, defaultValue?: any): any;
export function tryJSONParse(text: string, defaultValue?: any): any;
export function tryJSONParse(text: string, reviver?: any, defaultValue?: any) {
  if (typeof reviver !== 'function') {
    defaultValue = reviver;
    reviver = undefined;
  }

  try {
    return JSON.parse(text, reviver);
  } catch (e) {
    logger.error({ e, text, reviver }, `Failed to parse ${text}`);
    return defaultValue;
  }
}
