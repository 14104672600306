import { getOTTRemote } from 'common/utils/keymap';

// For Hyb app, when user press back button, the back event is sent on `window` as `keyup` by onHybBackPress in App.tsx
// But for OT SDK, it only listens `keydown` event on `document`
// So we need to re-dispatch the event to document
export const dispatchBackEventForOnetrust = (event: KeyboardEvent) => {
  const REMOTE = getOTTRemote();
  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();
  const backEvent = new KeyboardEvent('keydown', {
    bubbles: true,
    cancelable: true,
  });

  Object.defineProperty(backEvent, 'keyCode', { value: REMOTE.back });
  document.dispatchEvent(backEvent);
};
