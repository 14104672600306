import { createSelector } from 'reselect';

import { WEB_ROUTES } from 'common/constants/routes';
import { PurpleCarpetStatus } from 'common/features/purpleCarpet/type';
import type StoreState from 'common/types/storeState';
import { matchesRoute } from 'common/utils/urlPredicates';

// copied from src/common/features/purpleCarpet/selector.ts
// to solve to cycle deps issue
export const shouldShowPurpleCarpetSelector = (state: StoreState) => state.purpleCarpet.status !== PurpleCarpetStatus.NotAvailable;

// copied from src/common/features/purpleCarpet/selector.ts
// to solve to cycle deps issue
export const shouldShowPurpleCarpetOnHomeScreenSelector = createSelector(
  shouldShowPurpleCarpetSelector,
  (state: StoreState) => state.purpleCarpet.status,
  (_state: StoreState, { pathname }: { pathname: string }) => pathname,
  (isPurpleCarpetEnabled, purpleCarpetStatus, pathname) => {
    if (!matchesRoute(WEB_ROUTES.home, pathname) && !matchesRoute(WEB_ROUTES.landing, pathname)) {
      return false;
    }
    return isPurpleCarpetEnabled && [PurpleCarpetStatus.DuringGame, PurpleCarpetStatus.BeforeGame].includes(purpleCarpetStatus);
  }
);
