import { useToaster } from '@tubitv/ott-ui';
import type { Toaster } from '@tubitv/ott-ui';
import type { FunctionComponent, ComponentType } from 'react';
import React from 'react';

export interface WithToasterProps {
  toaster: Toaster;
}

export default function withToaster<P>(Component: ComponentType<P & WithToasterProps>): FunctionComponent<Omit<P, keyof WithToasterProps>> {
  function ComponentWithToaster(props: Omit<P, keyof WithToasterProps>) {
    const toaster = useToaster();
    return <Component {...props as P} toaster={toaster} />;
  }
  return ComponentWithToaster;
}
