import { isLGTVUsingChrome53, isLGTVUsingChrome68 } from 'client/utils/clientTools';
import type { ExperimentConfig } from 'common/experiments/ExperimentManager';
import { popperExperimentsSelector } from 'common/selectors/experiments';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';
import type { StoreState } from 'common/types/storeState';

let config: ExperimentConfig<boolean, string>;

export const ottPALIntegrationSelector = (state: StoreState): boolean => {
  if (FeatureSwitchManager.isEnabled(['Player', 'EnableGooglePALInAdRequest'])) return true;

  if (__SHOULD_ENABLE_GOOGLE_PAL_INTEGRATION__) return true;

  if (!config) return false;

  if (isLGTVUsingChrome53() || isLGTVUsingChrome68()) return false;

  const result = popperExperimentsSelector(state, {
    namespace: config.namespace,
    parameter: config.parameter,
    config,
  });
  return result;
};
