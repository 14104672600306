export function generateProfileIconUrl(profilePicUrl: string | null, size: number): string | undefined {
  if (!profilePicUrl) return;
  // If the profile pic comes from Facebook, it has a query string containing the width and height,
  // which defaults to 160x160. We want to change that to be the correct size we need, 40px.
  // That way, FB does the resizing for us. :-)
  // If it is not from FB, it will just be the URL for the default one on our CDN,
  // and these replace() calls will do nothing.
  return profilePicUrl
    .replace(/width=(\d+)/, `width=${size}`)
    .replace(/height=(\d+)/, `height=${size}`);
}
