function addLoadedClass() {
  if (!__CLIENT__) return;
  // Set a class on the html element to indicate that a crucial static resource has loaded.
  // This allows us to avoid a flash of white on PS4, for instance.
  try {
    document.body.parentElement!.classList.add('bg-image-loaded');
  } catch (ex) {
    // In case classList.add is not supported or something else goes wrong. Ignore.
  }
}

export default addLoadedClass;
