// hybrid app tracking timestamps

// Cold start, represent the time the Tubi app was launched for the first time in a session.
export const APP_LAUNCH_TS = 'app_launch_ts';
// Warm start, The time the Tubi app was resume from the background
export const APP_RESUME_TS = 'app_resume_ts';
// It only exists on the cold start and equals to webview_latest_start_ts at that time.
export const WEBVIEW_START_TS = 'webview_start_ts';
// It looks like a cold start, but in fact, it is a warm start because the native session is not killed actually.
// The time the Tubi app was re-launched (exit and re-open) in the same session.
export const WEBVIEW_LATEST_START_TS = 'webview_latest_start_ts';
export const INTRO_DOC_TIME_ORIGIN = 'intro_doc_time_origin';
export const INTRO_PAGE_LOAD_TIME = 'intro_page_load_time';
export const INTRO_ANIMATION_START_TS = 'intro_animation_start_ts';
export const INTRO_ANIMATION_END_TS = 'intro_animation_end_ts';
export const INTRO_DOC_DOWNLOAD_TIME = 'intro_doc_download_time';
export const INTRO_DOC_TTFB = 'intro_doc_ttfb';
export const INTRO_VIDEO_LOAD_DURATION = 'intro_video_load_duration';
export const WEB_DOC_LOADED_TS = 'web_doc_loaded_ts';
export const REACT_APP_RENDERED_TS = 'react_app_rendered_ts';
