import pick from 'lodash/pick';

import OTTFireTVAuthSessionMigration from 'common/experiments/config/ottFireTVAuthSessionMigration';
import OTTPs4AuthSessionMigration from 'common/experiments/config/ottPs4AuthSessionMigration';
import OTTSamsungAuthSessionMigration from 'common/experiments/config/ottSamsungAuthSessionMigration';
import OTTXboxoneAuthSessionMigration from 'common/experiments/config/ottXboxoneAuthSessionMigration';
import type { User } from 'common/features/authentication/types/auth';
import { USER_SESSION_STORAGE_KEY } from 'ott/features/authentication/constants';

import { setItem, getItem, removeItem } from './storage';
import { trackUserSessionLogging } from './track';

export { USER_SESSION_LOGGING_TYPES } from './track';

export { trackUserSessionLogging };

export interface UserSession extends Pick<User, 'authType' | 'hasAge' | 'refreshToken' | 'userId'> {
  accessToken: User['token'];
  createdAt?: number; // This field could be undefined because it was missing in the initial PR#20642
  updatedAt: number;
}

const isUserSessionAvailable = () => __OTTPLATFORM__ && __CLIENT__;

// Why these fields are picked from the user object?
// See: https://www.notion.so/tubi/Sync-user-session-data-to-localStorage-10672557e92080ae87f7fb5becc931e1?pvs=4#4ec6559075f2420899bc48422a03e4ee
export const transformUserToUserSession = async (user: User): Promise<UserSession> => {
  const now = Date.now();

  return {
    ...pick(user, ['authType', 'hasAge', 'refreshToken', 'userId']),
    accessToken: user.token,
    createdAt: (await getUserSessionFromLocalStorage(false))?.createdAt || now,
    updatedAt: now,
  };
};

export const transformUserSessionToUser = (userSession: UserSession): User => {
  const { accessToken: token } = userSession;

  return {
    ...pick(userSession, ['authType', 'authType', 'hasAge', 'refreshToken', 'userId']),
    token,
  };
};

export const getUserSessionFromLocalStorage = async (
  isAuthSessionExperimentAvailable = true
): Promise<UserSession | null> => {
  if (!isUserSessionAvailable()) {
    return null;
  }

  if (isAuthSessionExperimentAvailable) {
    const ottFireTVAuthSessionMigration = OTTFireTVAuthSessionMigration();
    const ottPs4AuthSessionMigration = OTTPs4AuthSessionMigration();
    const ottSamsungAuthSessionMigration = OTTSamsungAuthSessionMigration();
    const ottXboxoneAuthSessionMigration = OTTXboxoneAuthSessionMigration();
    const isAuthSessionMigrationEnabled =
      ottFireTVAuthSessionMigration.getValue() ||
      ottPs4AuthSessionMigration.getValue() ||
      ottSamsungAuthSessionMigration.getValue() ||
      ottXboxoneAuthSessionMigration.getValue();

    ottFireTVAuthSessionMigration.logExposure();
    ottPs4AuthSessionMigration.logExposure();
    ottSamsungAuthSessionMigration.logExposure();
    ottXboxoneAuthSessionMigration.logExposure();

    if (!isAuthSessionMigrationEnabled) {
      return null;
    }
  }

  const jsonfiedUser = await getItem(USER_SESSION_STORAGE_KEY);

  if (!jsonfiedUser) {
    return null;
  }

  try {
    return JSON.parse(jsonfiedUser);
  } catch {
    trackUserSessionLogging({
      message: `Failed to parse user session: ${jsonfiedUser}`,
    });
    return null;
  }
};

export const removeUserSessionFromLocalStorage = () => removeItem(USER_SESSION_STORAGE_KEY);

export const saveUserSessionToLocalStorage = async (user: User) => {
  if (!isUserSessionAvailable()) {
    return;
  }

  const userSession = await transformUserToUserSession(user);
  const isSuccess = await setItem(USER_SESSION_STORAGE_KEY, JSON.stringify(userSession));

  if (!isSuccess) {
    trackUserSessionLogging({
      message: 'Failed to save user session to localStorage',
      loggerConfig: {
        shouldSend: false,
      },
    });
  }
};

export const updateUserSessionInLocalStorage = async (updatedFields: Partial<UserSession>) => {
  const userSession = await getUserSessionFromLocalStorage();

  if (!userSession) {
    return;
  }

  const updatedUserSession = {
    ...userSession,
    ...updatedFields,
    updatedAt: Date.now(),
  };

  const isSuccess = await setItem(USER_SESSION_STORAGE_KEY, JSON.stringify(updatedUserSession));

  if (!isSuccess) {
    trackUserSessionLogging({
      message: 'Failed to update user session in localStorage',
    });
  }
};
