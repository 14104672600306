import React, { Component } from 'react';
import type { IntlShape } from 'react-intl';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { WithRouterProps } from 'react-router';
import { withRouter } from 'react-router';
import shallowEqual from 'shallowequal';

import systemApi from 'client/systemApi';
import { EVENTS_TTS_INITIALIZED } from 'client/systemApi/constants';
import * as systemApiUtils from 'client/systemApi/utils';
import logger from 'common/helpers/logging';
import isAndroidTVNativePackageWithTTSApi from 'common/selectors/atvTTS';
import { isSidePanelActiveSelector } from 'common/selectors/ottUI';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import type StoreState from 'common/types/storeState';
import { addEventListener, removeEventListener } from 'common/utils/dom';
import { getOTTRemote } from 'common/utils/keymap';
import { loadPlaybook } from 'common/utils/voiceViewPlaybooks';
import type { TubiIntlShape } from 'i18n/intl';
import { TTS_COOKIE_NAME, TTS_MODE } from 'ott/containers/Dev/TextToSpeech/TextToSpeech';

import styles from './VoiceView.scss';
import { setCookie } from '../../../client/utils/localDataStorage';

const REMOTE = getOTTRemote();

const messages = defineMessages({
  missingA11yText: {
    description: 'message indicating there is no accessibility text provided',
    defaultMessage: 'No a11y text provided',
  },
  howToExit: {
    description: 'how to exit',
    defaultMessage: '(long press the back button to exit)',
  },
});

interface DispatchProps {
  dispatch: TubiThunkDispatch;
}

interface OwnProps {
  /**
   * Currently only used for new horizontal navigation
   */
  enableTransition?: boolean;
    // pathname is only needed in the MSTP (or should I say the playbooks) but it is crucial for VoiceView
  pathname: string;
    // how to output the text. Useful when debugging to see the text visually
  mode?: TTS_MODE[number];
  a11yText?: string;
  intl: TubiIntlShape;
}

interface StateProps {
  text?: string;
  onceBefore?: string;
  onceAfter?: string;
  cancelable?: boolean;
  isNativeTTSOn?: boolean;
  nativeHasTTSApis?: boolean;
}
export type WrapperProps = OwnProps & StateProps & DispatchProps;
type DefaultProps = Required<Pick<WrapperProps, 'a11yText' | 'mode' | 'onceAfter' | 'text' | 'onceBefore' | 'cancelable' | 'isNativeTTSOn' | 'nativeHasTTSApis'>>;

class VoiceView extends Component<WrapperProps, {
  shouldSpeak: boolean;
}> {
  static defaultProps: DefaultProps = {
    a11yText: '',
    mode: TTS_MODE.default,
    onceAfter: '',
    text: '',
    onceBefore: '',
    cancelable: false,
    isNativeTTSOn: false,
    nativeHasTTSApis: false,
  };

  private isCurrentCancelable: boolean | undefined = true;

  // we use a queue to hold the following a11yText when the current speech is not cancelable
  private queue = [];

  private ttsQueue: Partial<WrapperProps>[] = [];

  private longPressTimeout: undefined | ReturnType<typeof setTimeout>;

  constructor(props: WrapperProps) {
    super(props);
    this.state = {
      shouldSpeak: Boolean(!__IS_ANDROIDTV_HYB_PLATFORM__ || this.props.nativeHasTTSApis),
    };
  }

  componentDidMount() {
    systemApi.on(EVENTS_TTS_INITIALIZED, this._speakCurrentText);
    this._speakCurrentText();

    // for fullscreen mode, we need to a way to exit,
    // so here we will detect a longpress of the back button
    if (this.props.mode === TTS_MODE.fullscreen) {
      addEventListener(window, 'keydown', [this._handleKeyPress]);
      addEventListener(window, 'keyup', [this._handleKeyRelease]);
    }
  }

  componentWillUnmount() {

    systemApi.off(EVENTS_TTS_INITIALIZED, this._speakCurrentText);
    removeEventListener(window, 'keydown', this._handleKeyPress);
    removeEventListener(window, 'keyup', this._handleKeyRelease);
  }

  shouldComponentUpdate(nextPros: OwnProps) {
    const { pathname, ...restProps } = this.props;
    const { pathname: nextPathname, ...nextRestProps } = nextPros;

    // do not update when only pathname changes to avoid speaking repeatedly from the previous path
    return !shallowEqual(restProps, nextRestProps);
  }

  componentDidUpdate() {
    this._speakCurrentText();
  }

  _speakCurrentText = () => {
    const { mode = TTS_MODE.default, a11yText = '', cancelable } = this.props;

    if (mode === TTS_MODE.readAloud) {
      systemApiUtils.speakWithSpeechSynthesis(a11yText);
    }

    if (mode === TTS_MODE.default && this._shouldSpeakForPlatform()) {
      if (__OTTPLATFORM__ === 'TIZEN') {
        this.textToSpeechByQueue({ a11yText, cancelable });
        // TODO: Support non-cancelable speech on other platforms, initially we support it on Samsung to meet their QA requirements.
      } else {
        systemApi.textToSpeech?.(a11yText);
      }
    }
  };

  _shouldSpeakForPlatform() {
    return (
      __OTTPLATFORM__ === 'TIZEN'
      || ((__OTTPLATFORM__ === 'VIZIO' || __IS_COMCAST_PLATFORM_FAMILY__ || __OTTPLATFORM__ === 'LGTV') && this.props.isNativeTTSOn)
      || __IS_ANDROIDTV_HYB_PLATFORM__
    );
  }

  _handleKeyPress = ({ keyCode }: KeyboardEvent) => {
    /* istanbul ignore else */
    if (keyCode === REMOTE.back) {
      this.longPressTimeout = setTimeout(() => {
        setCookie(TTS_COOKIE_NAME, TTS_MODE.default);
        window.location.href = '/';
      }, 2000);
    }
  };

  _handleKeyRelease = ({ keyCode }: KeyboardEvent) => {
    /* istanbul ignore else */
    if (keyCode === REMOTE.back) clearTimeout(this.longPressTimeout);
  };

  textToSpeechByQueue = ({ a11yText = '', cancelable }: Partial<WrapperProps>) => {
    // directly speak when the current speech is cancelable
    if (this.isCurrentCancelable) {
      this.textToSpeech({ a11yText, cancelable });
      this.queue = [];
    } else {
      // when the current speech is not cancelable, filter cancelable ones and append the new one to the queue
      this.ttsQueue = this.ttsQueue.filter(tts => !tts.cancelable);
      this.ttsQueue.push({
        a11yText,
        cancelable,
      });
    }
  };

  textToSpeech = ({ a11yText = '', cancelable }: Partial<WrapperProps>) => {
    // add onEnd callback for the non-cancelable speech to handle the queue
    const speechOptions = !cancelable ? { onEnd: this.onSpeechEnd } : {};
    systemApi.textToSpeech?.(a11yText, speechOptions);
    this.isCurrentCancelable = cancelable;
  };

  onSpeechEnd = () => {
    if (this.ttsQueue.length > 0) {
      this.textToSpeech?.(this.ttsQueue.shift()!);
    } else {
      this.isCurrentCancelable = true;
    }
  };

  render() {
    if (!this.state.shouldSpeak) return null;
    // only start speaking once dom is fully loaded; slow platforms
    // have UI issues when trying to speak/load at same time.
    const { mode = TTS_MODE.default, a11yText = '' } = this.props;

    // Edge browser engine won't read out this content if it has role="presentation"
    const a11yElementRoleProps = __OTTPLATFORM__ === 'XBOXONE' ? {} : { role: 'presentation' };
    const a11yElement = (
      <div className={styles[mode]}>
        <div className={styles.content}>
          <p
            className={styles.paragraph}
            id="voiceView"
            aria-atomic="true"
            aria-live="assertive"
            {...a11yElementRoleProps}
          >
            {a11yText || __PRODUCTION__ ? a11yText : <span className={styles.warning}>{this.props.intl.formatMessage(messages.missingA11yText)}</span>}
          </p>
        </div>
      </div>
    );

    switch (mode) {
      case TTS_MODE.fullscreen:
        return (
          <React.Fragment>
            {a11yElement}
            <div className={styles.exitNotice}>{this.props.intl.formatMessage(messages.howToExit)}</div>
          </React.Fragment>
        );
      case TTS_MODE.overlayTop:
        return a11yElement;
      case TTS_MODE.readAloud:
        return null;
      case TTS_MODE.default:
      default:
        // FireTV needs a11yElement, other platforms can just use their system API, so return null for them
        return this._shouldSpeakForPlatform() ? null : a11yElement;
    }
  }
}

/**
 * onceBefore and onceAfter are texts which should only be read when their values have changed
 */
const createA11yText = () => {
  let prevBefore: string;
  let prevAfter: string;
  return (text: string, onceAfter: string, onceBefore: string,) => {
    const a11yText = [
      prevBefore !== onceBefore ? onceBefore : null,
      text,
      prevAfter !== onceAfter ? onceAfter : null,
    ].filter(Boolean).join(' ');
    prevBefore = onceBefore;
    prevAfter = onceAfter;
    return a11yText;
  };
};
const getA11yText = createA11yText();

/**
 * Instead of a one universal MSTP we use loadPlaybook to load a MSTP based on a given pathname
 * And we wrap the whole thing in a try to isolate any error to just the MSTP function
 */
export const mapStateToProps = (state: StoreState, ownProps: OwnProps & { intl: IntlShape } & WithRouterProps) => {
  try {
    const {
      fire: { showModal },
      a11y,
    } = state;
    const isSidePanelActive = isSidePanelActiveSelector(state);
    const playbook = showModal ? null : loadPlaybook(ownProps.location);
    const {
      text,
      onceBefore,
      onceAfter,
      cancelable = true,
    } = (showModal || isSidePanelActive) ? a11y : playbook?.(state, ownProps, ownProps.intl && ownProps.intl.formatMessage)!;
    return {
      text,
      onceBefore,
      onceAfter,
      cancelable,
      isNativeTTSOn: a11y.isNativeTTSOn,
      nativeHasTTSApis: isAndroidTVNativePackageWithTTSApi(state),
    };
  } catch (err) {
    logger.error(err, 'Error in VoiceView');
    return {
      // returning and empty string will cause the last text to continue to be read
      // leading to confusion that they are still on it and can select it
      text: 'Nothing to select. move up and down or left and right!',
      onceBefore: '',
      onceAfter: '',
      cancelable: false,
      isNativeTTSOn: false,
      nativeHasTTSApis: false,
    };
  }
};

export const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps & StateProps) => {
  const { text = '', onceAfter = '', onceBefore = '', ...otherStateProps } = stateProps;
  return {
    ...ownProps,
    ...otherStateProps,
    ...dispatchProps,
    a11yText: getA11yText(text, onceAfter, onceBefore),
  };
};
export const RawVoiceView = VoiceView;
// Must inject intl before connect because we need the intl object for the playbook.
export default withRouter(injectIntl(connect(mapStateToProps, null, mergeProps)(VoiceView)));
