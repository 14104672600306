const skuReg = /ComcastAppPlatform\s(.*)\sFirebolt/;

/**
 * We can get the SKU from user agent for comcast family
 * The user agent looks like this:
 * Mozilla/5.0 (Linux; x86_64 GNU/Linux) AppleWebKit/601.1 (KHTML, like Gecko) Version/8.0 Safari/601.1 WPE ComcastAppPlatform AX013AN Firebolt/0.8.1
 * And "AX013AN" is the SKU
 *
 * UA can optionally be passed in for use on the server if the UA string
 * is known there
 */
export const getModelNameForComcastFamily = (ua?: string) => {
  return (ua || (__CLIENT__ ? navigator?.userAgent : undefined))?.match(skuReg)?.[1];
};
