import { secs } from '@adrise/utils/lib/time';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Tubi from 'common/components/uilib/SvgLibrary/Tubi';
import { NEW_BRAND_SPLASH_IMAGE_PATH } from 'common/constants/splash';
import type StoreState from 'common/types/storeState';
import { abortablePreloadImages } from 'common/utils/preloadImages';
import OTTBackground from 'ott/components/OTTBackground/OTTBackground';

import styles from './TubiSplash.scss';

export interface Props {
  isVisible: boolean;
}

interface InternalProps extends Props {
  isKidsModeEnabled: boolean;
}

/**
 * Fullscreen new wave background image, or the old-style purple/orange gradient + tubi logo in the center
 */
const TubiSplash = ({ isKidsModeEnabled, isVisible }: InternalProps) => {
  const splashImg = NEW_BRAND_SPLASH_IMAGE_PATH;
  useEffect(() => {
    let unsub: VoidFunction = () => undefined;
    const animationType = sessionStorage?.getItem('animationType');
    if (!isVisible && animationType !== 'deeplink') {
      // try and preload the image if it isn't mounted as visible or not in a deeplink, so it will be loaded by the time we need it
      const res = abortablePreloadImages([splashImg], secs(20));
      unsub = () => res.abort();
    }
    return unsub;
  }, [isVisible, splashImg]);
  if (!isVisible) {
    return null;
  }
  if (!isKidsModeEnabled) {
    return <img alt="Tubi Splash Screen" className={styles.tubiSplash} src={splashImg} />;
  }
  return (
    <div className={styles.tubiSplash}>
      <OTTBackground fullScreen vibrant={!isKidsModeEnabled} />
      <Tubi className={styles.tubiLogo} color="white" isKidsModeEnabled={isKidsModeEnabled} />
    </div>
  );
};

function mapStateToProps(state: StoreState): Omit<InternalProps, keyof Props> {
  const { ui: { isKidsModeEnabled } } = state;
  return {
    isKidsModeEnabled,
  };
}

export default React.memo(connect(mapStateToProps)(TubiSplash));
