import React, { Component } from 'react';

import { getCookie, setCookie } from 'client/utils/localDataStorage';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import { addEventListener, removeEventListener } from 'common/utils/dom';
import { getOTTRemote } from 'common/utils/keymap';
import OTTSelectableList from 'ott/components/OTTSelectableList/OTTSelectableList';

const REMOTE = getOTTRemote();
export const TTS_COOKIE_NAME = 'DEV_TTS';

interface Props {
  onLeave: (direction: string) => void;
  dispatch: TubiThunkDispatch;
  isActive: boolean;
}

interface TTSConfig {
  id: string;
  title: string;
}

export enum TTS_MODE {
  default = 'default', // "default" is forced on prod and will respect settings of the device
  fullscreen = 'fullscreen', // cover the whole screen, only a11y text will be displayed
  overlayTop = 'overlay-top', // display a semi-transparent overlay at the top of the screen
  readAloud = 'read-aloud', // use browser api to read a11y text
}

export default class TextToSpeech extends Component<Props> {
  state = {
    activeTTSOptionIndex: 0,
  };

  options: TTSConfig[] = [
    {
      id: TTS_MODE.default,
      title: 'default',
    },
    {
      id: TTS_MODE.fullscreen,
      title: 'fullscreen',
    },
    {
      id: TTS_MODE.overlayTop,
      title: 'overlay-top',
    },
  ];

  constructor(props: Props) {
    super(props);
    if (__CLIENT__) {
      const { speechSynthesis, SpeechSynthesisUtterance } = window;
      if (speechSynthesis && SpeechSynthesisUtterance) {
        this.options.push({
          id: TTS_MODE.readAloud,
          title: 'read-aloud',
        });
      }
    }
  }

  componentDidMount() {
    addEventListener(window, 'keydown', this.handleKeyPress);
    const activeTTSOptionId = getCookie(TTS_COOKIE_NAME) || TTS_MODE.default;
    this.setState({
      activeTTSOptionIndex: this.getActiveIndexById(activeTTSOptionId),
    });
  }

  componentWillUnmount() {
    removeEventListener(window, 'keydown', this.handleKeyPress);
  }

  getActiveIndexById(target: string) {
    const index = this.options.findIndex(({ id }) => id === target);
    return Math.max(index, 0);
  }

  handleKeyPress = (e: KeyboardEvent) => {
    const { keyCode } = e;
    const { onLeave } = this.props;
    if (keyCode === REMOTE.arrowLeft) {
      onLeave?.('ARROW_LEFT');
    }
  };

  onMenuItemChange = (index: number) => {
    this.setState({
      activeTTSOptionIndex: index,
    });
  };

  onMenuItemSelect = () => {
    const activeTTSOptionId = this.options[this.state.activeTTSOptionIndex].id;
    setCookie(TTS_COOKIE_NAME, activeTTSOptionId);
    window.location.replace('/');
  };

  render() {
    const { isActive, dispatch } = this.props;
    const { activeTTSOptionIndex } = this.state;
    return (
      <div>
        <OTTSelectableList
          isActive={isActive}
          activeId={this.options[activeTTSOptionIndex].id}
          displayCount={this.options.length}
          enableCycle
          items={this.options}
          onChange={this.onMenuItemChange}
          selectedIdx={activeTTSOptionIndex}
          onSelect={this.onMenuItemSelect}
          dispatch={dispatch}
          withA11y={false}
        />
      </div>
    );
  }
}
