import { getQueryStringFromUrl, parseQueryString } from '@adrise/utils/lib/queryString';

import { DEEPLINK_KIDSMODE_IDENTIFIER } from 'common/constants/constants';

/**
 * Only get the base URL without query params
 * @param url
 */
export function getBaseUrl(url: string): string {
  return url && url.trim().split(/[?#]/)[0];
}

/**
 * Check if the Home URL with query params `kids_mode`
 * @param url
 */
export function routeContainsKidsMode(url: string): boolean {
  const queryStringPart = getQueryStringFromUrl(url);
  const queryParams = parseQueryString(queryStringPart);
  return queryParams && queryParams.utm_campaign === DEEPLINK_KIDSMODE_IDENTIFIER;
}
