import { getCookie, setCookie } from 'client/utils/localDataStorage';
import { ONE_HUNDRED_YEARS } from 'common/constants/constants';
import { setFirstSeen as setFirstSeenAction } from 'common/features/authentication/actions/auth';
import logger from 'common/helpers/logging';
import type { TubiStore } from 'common/types/storeState';
import { isValidDateString } from 'common/utils/date';

const setFirstSeen = (store: TubiStore, firstSeen: string) => {
  setCookie('firstSeen', firstSeen, ONE_HUNDRED_YEARS);
  store.dispatch(setFirstSeenAction(firstSeen));
};

export const setupFirstSeen = (store: TubiStore) => {
  if (__OTTPLATFORM__ === 'TIZEN') {
    // On samsung, getCookie will always return undefined because it uses
    // the file protocol. We need to do something to support getCookie on
    // samsung when we implement failsafe mode for that platform, but for now,
    // we'll just skip this logic to avoid resetting users' firstSeen cookies.
    return;
  }

  let firstSeen = getCookie('firstSeen');

  if (firstSeen && !isValidDateString(firstSeen)) {
    logger.error(`Invalid firstSeen cookie provided: ${firstSeen}`);
    firstSeen = undefined;
  }

  if (!firstSeen) {
    setFirstSeen(store, new Date().toISOString());
  } else if (firstSeen !== store.getState().auth.firstSeen) {
    // For failsafe mode, sync the firstSeen cookie to the store
    setFirstSeen(store, firstSeen);
  }
};
