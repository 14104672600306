// Listen consent changes and notify native
// so native can disable/enable 3rd SDK based on consent
import { ONETRUST_CONSENT_CHANGE_EVENT_NAME } from 'common/features/gdpr/onetrust/onetrust';
import { updateGDPRConsentBridge } from 'ott/features/gdpr/utils/updateGDPRConsentBridge';
import type { OneTrustConsents } from 'types/onetrust';

const onConsentChange = (e: Event) => {
  updateGDPRConsentBridge((e as CustomEvent<{ consents: OneTrustConsents, tcf: string}>).detail);
};

export const setupConsentChangeListener = () => {
  document.removeEventListener(ONETRUST_CONSENT_CHANGE_EVENT_NAME, onConsentChange);
  document.addEventListener(ONETRUST_CONSENT_CHANGE_EVENT_NAME, onConsentChange);
};
