import omit from 'lodash/omit';

import type { OttSystemAction } from 'common/actions/ottSystem';
import * as actions from 'common/constants/action-types';
import type { OttSystemState } from 'common/types/ottSystem';

export const initialState: OttSystemState = {
  tizenDeeplinkPage: '',
};

function setField(state: OttSystemState, action: OttSystemAction, field: keyof OttSystemState) {
  const value = action[field];
  if (!value) {
    return omit(state, field);
  }
  if (value !== state[field]) {
    return {
      ...state,
      [field]: value,
    };
  }
  return state;
}

export default function OTTSystemReducer(state: OttSystemState = initialState, action?: OttSystemAction): OttSystemState {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case actions.SET_TIZEN_DEEPLINK_PAGE:
      return {
        ...state,
        tizenDeeplinkPage: action.tizenDeeplinkPage,
      };
    case actions.CLEAR_TIZEN_DEEPLINK_PAGE:
      return {
        ...state,
        tizenDeeplinkPage: '',
      };
    case actions.SET_OTT_DEVICE_INFO:
      return {
        ...state,
        deviceFirmware: action.deviceFirmware,
        deviceManufacturer: action.deviceManufacturer,
        deviceModel: action.deviceModel,
      };
    case actions.SET_DEVICE_DEAL:
      return setField(state, action, 'deviceDeal');
    case actions.SET_ISD:
      return setField(state, action, 'isd');
    case actions.SET_RSD:
      return setField(state, action, 'rsd');
    case actions.SET_TALKBACK_ENABLED:
      const { enabled } = action;
      if (enabled !== state.talkBackEnabled) {
        return { ...state, talkBackEnabled: enabled };
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return state;
  }
}
