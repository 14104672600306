/**
 * OTT caption settings
 */
import { defineMessages } from 'react-intl';

export const EDGE_COLOR = '999999'; // keep the long format for adapting the native player

export const OPTIONS = {
  SWITCH: 'switch',
  COLOR: 'color',
  OPACITY: 'opacity',
  SIZE: 'size',
  FONT: 'font',
  BACKGROUND_COLOR: 'background color',
  BACKGROUND_OPACITY: 'background opacity',
  EDGE: 'edge',
  WINDOW_COLOR: 'window color',
  WINDOW_OPACITY: 'window opacity',
  RESET: 'reset',
} as const;

export const SUB_OPTION_VALUE = {
  ON: 'on',
  OFF: 'off',
  WHITE: '255, 255, 255',
  BLACK: '0, 0, 0',
  RED: '255, 0, 0',
  GREEN: '0, 128, 0',
  BLUE: '0, 0, 255',
  YELLOW: '255, 255, 0',
  MAGENTA: '255, 0, 255',
  CYAN: '0, 255, 255',
  OPACITY_OPAQUE: '1',
  OPACITY_75: '0.75',
  OPACITY_50: '0.50',
  OPACITY_25: '0.25',
  OPACITY_0: '0',
  FONT_SMALL: '50%',
  FONT_MEDIUM: '75%',
  FONT_STANDARD: '100%',
  FONT_LARGE: '150%',
  FONT_EXTRA_LARGE: '200%',
  FONT_DEFAULT: 'Vaud',
  FONT_COURIER: 'Courier',
  FONT_LORA: 'Lora',
  FONT_UBUNTU_MONO: 'Ubuntu Mono',
  FONT_ROBOTO: 'Roboto',
  FONT_CASUAL: 'Casual',
  FONT_COURGETTE: 'Courgette',
  FONT_SMALL_CAPS: 'inherit',
  FONT_VARIANT_NORMAL: 'normal',
  EDGES_NONE: 'none',
  EDGES_RAISED: `0 3px 0 #${EDGE_COLOR}`,
  EDGES_DEPRESSED: `0 -3px 0 #${EDGE_COLOR}`,
  EDGES_UNIFORM: `
     -3px 3px 0 #${EDGE_COLOR},
     3px -3px 0 #${EDGE_COLOR},
     3px 3px 0 #${EDGE_COLOR},
     -3px -3px 0 #${EDGE_COLOR}
   `,
  EDGES_DROP_SHADOW_EDGES: `3px 3px 0 #${EDGE_COLOR}`,
} as const;

const messages = defineMessages({
  switch: {
    description: 'title of switch option',
    defaultMessage: 'Captions Off',
  },
  color: {
    description: 'title of switch option',
    defaultMessage: 'Character Color',
  },
  opacity: {
    description: 'title of opacity option',
    defaultMessage: 'Character Opacity',
  },
  size: {
    description: 'title of size option',
    defaultMessage: 'Character Size',
  },
  font: {
    description: 'title of font option',
    defaultMessage: 'Font',
  },
  bgColor: {
    description: 'title of background color option',
    defaultMessage: 'Caption Background Color',
  },
  bgOpacity: {
    description: 'title of background opacity option',
    defaultMessage: 'Caption Background Opacity',
  },
  edge: {
    description: 'title of edge option',
    defaultMessage: 'Caption Edge Attributes',
  },
  windowColor: {
    description: 'title of window color option',
    defaultMessage: 'Caption Window Color',
  },
  windowOpacity: {
    description: 'title of window opacity option',
    defaultMessage: 'Caption Window Opacity',
  },
  reset: {
    description: 'title of edresetge option',
    defaultMessage: 'Reset Default Caption Settings',
  },
  white: {
    description: 'White',
    defaultMessage: 'White',
  },
  black: {
    description: 'Black',
    defaultMessage: 'Black',
  },
  red: {
    description: 'Red',
    defaultMessage: 'Red',
  },
  green: {
    description: 'Green',
    defaultMessage: 'Green',
  },
  blue: {
    description: 'Blue',
    defaultMessage: 'Blue',
  },
  yellow: {
    description: 'Yellow',
    defaultMessage: 'Yellow',
  },
  magenta: {
    description: 'Magenta',
    defaultMessage: 'Magenta',
  },
  cyan: {
    description: 'Cyan',
    defaultMessage: 'Cyan',
  },
  transparent75: {
    description: '75% Transparent',
    defaultMessage: '75% Transparent',
  },
  transparent50: {
    description: '50% Transparent',
    defaultMessage: '50% Transparent',
  },
  transparent25: {
    description: '25% Transparent',
    defaultMessage: '25% Transparent',
  },
  solid: {
    description: 'Solid',
    defaultMessage: 'Solid',
  },
  transparent100: {
    description: '100% Transparent',
    defaultMessage: '100% Transparent',
  },
  noEdge: {
    description: 'No Edge',
    defaultMessage: 'No Edge',
  },
  raised: {
    description: 'Raised',
    defaultMessage: 'Raised',
  },
  depressed: {
    description: 'Depressed',
    defaultMessage: 'Depressed',
  },
  uniform: {
    description: 'Uniform',
    defaultMessage: 'Uniform',
  },
  dropShadowEdges: {
    description: 'Drop Shadow Edges',
    defaultMessage: 'Drop Shadow Edges',
  },
  tubiDefault: {
    description: 'Tubi Default',
    defaultMessage: 'Tubi Default',
  },
  courier: {
    description: 'Courier',
    defaultMessage: 'Courier',
  },
  lora: {
    description: 'Lora',
    defaultMessage: 'Lora',
  },
  ubuntuMono: {
    description: 'Ubuntu Mono',
    defaultMessage: 'Ubuntu Mono',
  },
  roboto: {
    description: 'Roboto',
    defaultMessage: 'Roboto',
  },
  casual: {
    description: 'Casual',
    defaultMessage: 'Casual',
  },
  courgette: {
    description: 'Courgette',
    defaultMessage: 'Courgette',
  },
  smallCaps: {
    description: 'Small Caps',
    defaultMessage: 'Small Caps',
  },
  small: {
    description: 'Small',
    defaultMessage: 'Small',
  },
  medium: {
    description: 'Medium',
    defaultMessage: 'Medium',
  },
  standard: {
    description: 'Standard',
    defaultMessage: 'Standard',
  },
  large: {
    description: 'Large',
    defaultMessage: 'Large',
  },
  extraLarge: {
    description: 'Extra-Large',
    defaultMessage: 'Extra-Large',
  },
  onAvailable: {
    description: 'On (when available)',
    defaultMessage: 'On (when available)',
  },
  off: {
    description: 'Off',
    defaultMessage: 'Off',
  },
});

const BASIC_COLORS = [
  {
    title: messages.white,
    value: SUB_OPTION_VALUE.WHITE,
  },
  {
    title: messages.black,
    value: SUB_OPTION_VALUE.BLACK,
  },
  {
    title: messages.red,
    value: SUB_OPTION_VALUE.RED,
  },
  {
    title: messages.green,
    value: SUB_OPTION_VALUE.GREEN,
  },
  {
    title: messages.blue,
    value: SUB_OPTION_VALUE.BLUE,
  },
  {
    title: messages.yellow,
    value: SUB_OPTION_VALUE.YELLOW,
  },
  {
    title: messages.magenta,
    value: SUB_OPTION_VALUE.MAGENTA,
  },
  {
    title: messages.cyan,
    value: SUB_OPTION_VALUE.CYAN,
  },
];

const FONT_OPACITIES = [
  {
    title: messages.transparent75,
    value: SUB_OPTION_VALUE.OPACITY_25,
  },
  {
    title: messages.transparent50,
    value: SUB_OPTION_VALUE.OPACITY_50,
  },
  {
    title: messages.transparent25,
    value: SUB_OPTION_VALUE.OPACITY_75,
  },
  {
    title: messages.solid,
    value: SUB_OPTION_VALUE.OPACITY_OPAQUE,
  },
];

const OPACITIES = [
  {
    title: messages.transparent100,
    value: SUB_OPTION_VALUE.OPACITY_0,
  },
  ...FONT_OPACITIES,
];

const EDGE = [
  {
    title: messages.noEdge,
    value: SUB_OPTION_VALUE.EDGES_NONE,
    nativeValue: 'none', // the value in native player
  },
  {
    title: messages.raised,
    value: SUB_OPTION_VALUE.EDGES_RAISED,
    nativeValue: 'raised',
  },
  {
    title: messages.depressed,
    value: SUB_OPTION_VALUE.EDGES_DEPRESSED,
    nativeValue: 'depressed',
  },
  {
    title: messages.uniform,
    value: SUB_OPTION_VALUE.EDGES_UNIFORM,
    nativeValue: 'uniform',
  },
  {
    title: messages.dropShadowEdges,
    value: SUB_OPTION_VALUE.EDGES_DROP_SHADOW_EDGES,
    nativeValue: 'drop_shadow',
  },
];

const FONT = [
  {
    title: messages.tubiDefault,
    value: SUB_OPTION_VALUE.FONT_DEFAULT,
    nativeValue: 'default', // the value in native player
  },
  {
    title: messages.courier,
    value: SUB_OPTION_VALUE.FONT_COURIER,
    nativeValue: 'monospaced_serif',
  },
  {
    title: messages.lora,
    value: SUB_OPTION_VALUE.FONT_LORA,
    nativeValue: 'proportional_serif',
  },
  {
    title: messages.ubuntuMono,
    value: SUB_OPTION_VALUE.FONT_UBUNTU_MONO,
    nativeValue: 'monospaced_sanserif',
  },
  {
    title: messages.roboto,
    value: SUB_OPTION_VALUE.FONT_ROBOTO,
    nativeValue: 'proportional_sanserif',
  },
  {
    title: messages.casual,
    value: SUB_OPTION_VALUE.FONT_CASUAL,
    nativeValue: 'casual',
  },
  {
    title: messages.courgette,
    value: SUB_OPTION_VALUE.FONT_COURGETTE,
    nativeValue: 'cursive',
  },
  {
    title: messages.smallCaps,
    value: SUB_OPTION_VALUE.FONT_SMALL_CAPS,
    nativeValue: 'smallcaps',
    style: {
      fontVariant: 'small-caps',
    },
  },
];

const SIZE = [
  {
    title: messages.small,
    value: SUB_OPTION_VALUE.FONT_SMALL,
    nativeValue: 'small', // the value in native player
  },
  {
    title: messages.medium,
    value: SUB_OPTION_VALUE.FONT_MEDIUM,
    nativeValue: 'medium',
  },
  {
    title: messages.standard,
    value: SUB_OPTION_VALUE.FONT_STANDARD,
    nativeValue: 'standard',
  },
  {
    title: messages.large,
    value: SUB_OPTION_VALUE.FONT_LARGE,
    nativeValue: 'large',
  },
  {
    title: messages.extraLarge,
    value: SUB_OPTION_VALUE.FONT_EXTRA_LARGE,
    nativeValue: 'extra_large',
  },
];

const SWITCH = [
  {
    title: messages.onAvailable,
    value: SUB_OPTION_VALUE.ON,
  },
  {
    title: messages.off,
    value: SUB_OPTION_VALUE.OFF,
  },
];

export const CAPTIONS_OPTIONS = {
  switch: {
    id: 'switch',
    title: messages.switch,
    subOptions: SWITCH,
  },
  color: {
    id: 'color',
    title: messages.color,
    subOptions: BASIC_COLORS,
  },
  opacity: {
    id: 'opacity',
    title: messages.opacity,
    subOptions: FONT_OPACITIES,
  },
  size: {
    id: 'size',
    title: messages.size,
    subOptions: SIZE,
  },
  font: {
    id: 'font',
    title: messages.font,
    subOptions: FONT,
  },
  bgColor: {
    id: 'bgColor',
    title: messages.bgColor,
    subOptions: BASIC_COLORS,
  },
  bgOpacity: {
    id: 'bgOpacity',
    title: messages.bgOpacity,
    subOptions: OPACITIES,
  },
  edge: {
    id: 'edge',
    title: messages.edge,
    subOptions: EDGE,
  },
  windowColor: {
    id: 'windowColor',
    title: messages.windowColor,
    subOptions: BASIC_COLORS,
  },
  windowOpacity: {
    id: 'windowOpacity',
    title: messages.windowOpacity,
    subOptions: OPACITIES,
  },
  reset: {
    id: 'reset',
    title: messages.reset,
  },
} as const;
