import type { DeeplinkType } from 'common/utils/deeplinkType';

export interface Error {
  message: string;
  visible: boolean;
  code: string;
}

export interface TextToSpeechOptions {
  onEnd?: () => void;
  flush?: boolean;
}

export interface ContinueWatchingItem {
  isLinearContent?: boolean;
  contentId: string;
  positionInSeconds: number;
  creditsPositionInSeconds?: number;
  duration?: number
}

export interface UpdateCWonDeviceParams {
  shouldSendEvent: boolean;
  removeCWfromDevice?: boolean;
  cwItem?: ContinueWatchingItem;
  playbackState?: FIRETV_PLAYBACK_STATES;
}

export enum FIRETV_PLAYBACK_STATES {
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  EXIT = 'EXIT',
  INTERSTITIAL = 'INTERSTITIAL',
}

export enum FIRETV_MYLIST_ACTION {
  ADD = 'Add',
  REMOVE = 'Remove',
}

export interface PSDeeplinkParameters {
  deeplinkType?: DeeplinkType;
  contentId?: number;
  containerId?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

export enum PageType {
  homePage = 'HOME_PAGE',
  allEpisodePage = 'ALL_EPISODES_PAGE',
  playerPage = 'PLAYER_PAGE',
  background = 'BACKGROUND',
}

export interface OnEnterDetailPageParameters extends Record<string, unknown> {
  contentId: string;
  sourcePage: PageType;
}

export interface OnLeaveDetailPageParameters extends Record<string, unknown> {
  destinationPage: PageType;
}

export interface NotifyNativeCommonEventParameters extends Record<string, unknown> {
  event: 'onWebViewReady' | 'onWebViewResume' |'ContinueWatchingEvent' | 'KidsModeEvent' | 'WatchlistEvent' | 'BrazeImpressionEvent';
  result?: 'success' | 'failure';
  message?: string;
  data?: Record<string, unknown> | Record<string, unknown>[];
  enable?: boolean
}

export interface IamImpressionEvent {
  action: 'logImpression' | 'logClick' | 'logClickButton' | 'logDismiss';
  parameters?: {
    btnId: number
  }
}

export interface SendToOttEventData {
  personalizationConsent: boolean | string;
  IAMString: string
}

export interface HybDeviceInfo {
  device_type: string,
  device_name: string,
  model: string,
  manufacturer: string,
  webview_latest_start_ts: number,
  webview_start_ts?: number,
  app_launch_ts?: number,
  app_resume_ts?: number,
}
