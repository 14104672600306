import jwtDecode from 'jwt-decode';

import { getBridge } from 'client/utils/clientTools';
import { LOG_SUB_TYPE, TRACK_LOGGING } from 'common/constants/error-types';
import { checkEmail } from 'common/features/authentication/actions/auth';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import type { RouteCode } from 'common/types/route-codes';
import { trackLogging } from 'common/utils/track';
import config from 'src/config';

interface OnSuccessBasicResponse {
  id: string;
  displayName: string;
  familyName: string;
  givenName: string;
  profilePictureUri: string;
}

export interface OnSuccessWithTokenResponse extends OnSuccessBasicResponse {
  googleIdToken: string;
}

export interface OnSuccessWithPasswordResponse extends OnSuccessBasicResponse {
  password: string;
}

export type OnSuccessResponse = OnSuccessWithTokenResponse | OnSuccessWithPasswordResponse;

export interface OnErrorResponse {
  errorCode: number;
  errorMsg: string;
}

export type OnSuccess = (resp: OnSuccessResponse) => void;
type OnCancel = () => void;
type OnError = (resp: OnErrorResponse) => void;

interface BridgeCallbacks {
  onGoogleOneTapSignInSuccess?: OnSuccess;
  onGoogleOneTapSignInCancel?: OnCancel;
  onGoogleOneTapSignInError?: OnError;
  onGoogleOneTapSignUpSuccess?: OnSuccess;
  onGoogleOneTapSignUpCancel?: OnCancel;
  onGoogleOneTapSignUpError?: OnError;
}

interface TokenPayload {
  email: string;
}

export interface BridgeFunctions {
  beginSignInWithGoogleOneTap: () => void;
  beginSignUpWithGoogleOneTap: () => void;
  signOutFromGoogleOneTap: () => void;
}

export interface UserCheckerReturn {
  email: string;
  emailExists: boolean;
}

export interface SignInWithGoogleOneTapLocationState {
  from?: string;
  autoStartOneTap?: boolean;
}

// 16 means CANCELED from Google, however, the localized error message is "Cannot find a matching credential".
// All the possible cases for this error 16 could be found below:
// https://stackoverflow.com/questions/61768804/getting-16-cannot-find-a-matching-credential-when-doing-one-tap-sign-in-and-s
// https://developers.google.com/android/reference/com/google/android/gms/common/api/CommonStatusCodes#public-static-final-int-canceled
export const NO_MATCHING_CREDENTIAL_ERROR = 16;

export const initBridgeFunctions = (callbacks: BridgeCallbacks = {}): BridgeFunctions => {
  const { clientID: googleClientId } = config.google;

  const bridge = getBridge({
    debug:
      !__PRODUCTION__ ||
      /* istanbul ignore next */
      __IS_ALPHA_ENV__,
  });

  bridge?.init();

  Object.entries(callbacks).forEach(([name, callback]: [string, any]) => {
    bridge?.registerHandler(name, callback);
  });

  return {
    beginSignInWithGoogleOneTap: () => {
      bridge?.callHandler('beginSignInWithGoogleOneTap', {
        googleClientId,
      });
    },

    beginSignUpWithGoogleOneTap: () => {
      bridge?.callHandler('beginSignUpWithGoogleOneTap', {
        googleClientId,
      });
    },

    signOutFromGoogleOneTap: () => {
      bridge?.callHandler('signOutFromGoogleOneTap');
    },
  };
};

export const userChecker = (
  resp: OnSuccessResponse
) => {
  return async (dispatch: TubiThunkDispatch) => {
    let email;
    if ((resp as OnSuccessWithTokenResponse).googleIdToken) {
      const token = jwtDecode<TokenPayload>((resp as OnSuccessWithTokenResponse).googleIdToken);
      email = token.email;
    } else {
      email = (resp as OnSuccessWithPasswordResponse).id;
    }

    const code = await dispatch(checkEmail(email)) as RouteCode;
    if (code === 'INVALID_FORMAT') {
      throw new Error(code);
    }

    return {
      email,
      emailExists: code === 'TAKEN',
    };
  };
};

export const trackOneTapError = (message: string) => {
  trackLogging({
    type: TRACK_LOGGING.clientInfo,
    subtype: LOG_SUB_TYPE.GOOGLE_ONE_TAP,
    message,
  });
};
