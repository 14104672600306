import React, { useRef } from 'react';

import OTTTransition from 'ott/components/OTTTransition/OTTTransition';

import styles from './OTTCWBackground.scss';

const transitionNames = {
  appear: styles.backgroundAppear,
  appearActive: styles.backgroundAppearActive,
  enter: styles.backgroundEnter,
  enterActive: styles.backgroundEnterActive,
  leave: styles.backgroundLeave,
  leaveActive: styles.backgroundLeaveActive,
};

const OTTCWBackground: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div data-test-id="ott-cw-background">
      <OTTTransition
        transitionName={transitionNames}
        component="div"
        transitionAppear
        timeout={1000}
        nodeRef={ref}
      >
        <div ref={ref} className={styles.background} />
      </OTTTransition>
      <div className={styles.gradient} />
    </div>
  );
};

export default OTTCWBackground;
