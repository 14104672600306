import { useCallback } from 'react';

import { hideModal } from 'common/actions/modal';
import { SET_DEEPLINK_BACK_OVERRIDE } from 'common/constants/action-types';
import { BACK_FROM_CONTAINER_TO_HOME, MOST_POPULAR_CONTAINER_ID } from 'common/constants/constants';
import OTTFireTVErrorModalRedesign from 'common/experiments/config/ottFireTVErrorModalRedesign';
import tubiHistory from 'common/history';
import useAppDispatch from 'common/hooks/useAppDispatch';
import useExperiment from 'common/hooks/useExperiment';
import { actionWrapper } from 'common/utils/action';
import { getContainerUrl } from 'common/utils/urlConstruction';

const useExplorePopular = () => {
  const ottFireTVErrorModalRedesign = useExperiment(OTTFireTVErrorModalRedesign);
  const expGroup = ottFireTVErrorModalRedesign.getValue();
  const dispatch = useAppDispatch();
  const explorePopular = useCallback(() => {
    dispatch(hideModal());
    tubiHistory.replace(getContainerUrl(MOST_POPULAR_CONTAINER_ID, { ott: true }));
    dispatch(actionWrapper(SET_DEEPLINK_BACK_OVERRIDE, {
      data: {
        [BACK_FROM_CONTAINER_TO_HOME]: true,
      },
    }));
  }, [dispatch]);
  const logExposure = useCallback(() => {
    ottFireTVErrorModalRedesign.logExposure();
  }, [ottFireTVErrorModalRedesign]);
  return {
    explorePopular,
    expGroup,
    logExposure,
  };
};

export default useExplorePopular;

