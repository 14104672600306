export const TENSOR_OTT_HOMESCREEN_FIELDS = [
  'availability_duration',
  'ad_languages',
  'backgrounds', //  ott homescreen live channel container require this field
  'channel_logo',
  'credit_cuepoints',
  'description',
  'duration',
  'has_subtitle',
  'id',
  'images',
  'landscape_images', // ott homescreen live channel container require this field. There is no images field for live channel container
  'lang',
  'logo',
  'needs_login',
  'login_reason',
  'num_seasons',
  'programs', // for the case channel tile in featured container row
  'publisher_id',
  'posterarts', // search page need posterarts for live channel
  'ratings',
  'tags',
  'title',
  'type',
  'video_preview_url',
  'year',
  'video_resources',
  'vibes',
  'player_type', // for purple carpet
  'air_datetime', // for purple carpet
  'video_metadata',
  // The following are all used when rendering details and tiles for programs
  // that are associated with a schedule of a linear channel, where the
  // program is an episode of a series:
  'series_id', // to detect that the program is an episode of a series
  'series_title', // to render the episode details
  'series_images', // to render the preview background & tile background
  'is_recurring', // to render the recurring episode detail page peek
];
