import type { AnyAction } from 'redux';

import {
  LOAD_LOCAL_CAPTIONS,
  SET_CAPTION_SETTINGS,
  SET_DEFAULT_CAPTIONS,
  SET_DEFAULT_AUDIO_TRACKS,
  RESET_CAPTION_SETTINGS,
} from 'common/constants/action-types';
import { DEFAULT_CAPTION_LANGUAGE } from 'common/constants/constants';
import type { CaptionSubOption, OTTCaptionSettingsState } from 'common/types/captionSettings';
import { findSubOptionIndex, updateLocalAudioDescription, updateLocalSettings } from 'common/utils/captionTools';
import { OPTIONS, CAPTIONS_OPTIONS, SUB_OPTION_VALUE } from 'ott/constants/captionSettings';

const actions = { LOAD_LOCAL_CAPTIONS, SET_CAPTION_SETTINGS, SET_DEFAULT_CAPTIONS, SET_DEFAULT_AUDIO_TRACKS, RESET_CAPTION_SETTINGS };

// OTTSelectableList requires the format of {id: xxx, title: xxx}, here we use value as ids
export const addIdPropForEachSubOption = (subOptions: CaptionSubOption[]): CaptionSubOption[] => {
  return subOptions.map((subOption) => ({ ...subOption, id: subOption.value }));
};

export const addFontStyle = (subOptions: CaptionSubOption[]): CaptionSubOption[] => {
  return subOptions.map((subOption) => ({
    ...subOption,
    style: { ...(subOption.style || {}), fontFamily: subOption.value },
  }));
};

// NOTE, vizio should NEVER have on/off option, so we remove here. If adding new action that modifies option list...
// consider this
// eslint-disable-next-line import/no-unused-modules -- exported for testing
export const options = [
  OPTIONS.SWITCH,
  OPTIONS.COLOR,
  OPTIONS.OPACITY,
  OPTIONS.SIZE,
  OPTIONS.FONT,
  OPTIONS.BACKGROUND_COLOR,
  OPTIONS.BACKGROUND_OPACITY,
  OPTIONS.EDGE,
  OPTIONS.WINDOW_COLOR,
  OPTIONS.WINDOW_OPACITY,
  OPTIONS.RESET,
].filter(Boolean) as string[];

export const initialState = {
  defaultCaptions: {
    language: DEFAULT_CAPTION_LANGUAGE,
    enabled: false,
  },
  defaultAudioTracks: '',
  options,
  byId: {
    [OPTIONS.SWITCH]: {
      id: OPTIONS.SWITCH,
      title: CAPTIONS_OPTIONS.switch.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.switch.subOptions, SUB_OPTION_VALUE.OFF),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.switch.subOptions),
    },
    [OPTIONS.COLOR]: {
      id: OPTIONS.COLOR,
      title: CAPTIONS_OPTIONS.color.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.color.subOptions, SUB_OPTION_VALUE.WHITE),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.color.subOptions),
    },
    [OPTIONS.OPACITY]: {
      id: OPTIONS.OPACITY,
      title: CAPTIONS_OPTIONS.opacity.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.opacity.subOptions, SUB_OPTION_VALUE.OPACITY_OPAQUE),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.opacity.subOptions),
    },
    [OPTIONS.SIZE]: {
      id: OPTIONS.SIZE,
      title: CAPTIONS_OPTIONS.size.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.size.subOptions, SUB_OPTION_VALUE.FONT_STANDARD),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.size.subOptions),
    },
    [OPTIONS.FONT]: {
      id: OPTIONS.FONT,
      title: CAPTIONS_OPTIONS.font.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.font.subOptions, SUB_OPTION_VALUE.FONT_DEFAULT),
      subOptions: addFontStyle(addIdPropForEachSubOption(CAPTIONS_OPTIONS.font.subOptions)),
    },
    [OPTIONS.BACKGROUND_COLOR]: {
      id: OPTIONS.BACKGROUND_COLOR,
      title: CAPTIONS_OPTIONS.bgColor.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.bgColor.subOptions, SUB_OPTION_VALUE.BLUE),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.bgColor.subOptions),
    },
    [OPTIONS.BACKGROUND_OPACITY]: {
      id: OPTIONS.BACKGROUND_OPACITY,
      title: CAPTIONS_OPTIONS.bgOpacity.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.bgOpacity.subOptions, SUB_OPTION_VALUE.OPACITY_0),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.bgOpacity.subOptions),
    },
    [OPTIONS.EDGE]: {
      id: OPTIONS.EDGE,
      title: CAPTIONS_OPTIONS.edge.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.edge.subOptions, SUB_OPTION_VALUE.EDGES_NONE),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.edge.subOptions),
    },
    [OPTIONS.WINDOW_COLOR]: {
      id: OPTIONS.WINDOW_COLOR,
      title: CAPTIONS_OPTIONS.windowColor.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.windowColor.subOptions, SUB_OPTION_VALUE.BLACK),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.windowColor.subOptions),
    },
    [OPTIONS.WINDOW_OPACITY]: {
      id: OPTIONS.WINDOW_OPACITY,
      title: CAPTIONS_OPTIONS.windowOpacity.title,
      subOptionIndex: findSubOptionIndex(CAPTIONS_OPTIONS.windowOpacity.subOptions, SUB_OPTION_VALUE.OPACITY_50),
      subOptions: addIdPropForEachSubOption(CAPTIONS_OPTIONS.windowOpacity.subOptions),
    },
    [OPTIONS.RESET]: {
      id: OPTIONS.RESET,
      title: CAPTIONS_OPTIONS.reset.title,
    },
  },
  background: { toggle: { active: false, label: '' }, backgroundColor: { activeRGBValue: '' } },
  font: {
    size: { vw: '', label: '' },
    family: {
      type: '',
      value: '',
      label: '',
    },
    fontColor: { activeRGBValue: '' },
  },
  styling: {
    stylingType: { value: '', label: '' },
    stylingColor: { activeRGBValue: '' },
  },
  window: { windowColor: { activeRGBValue: '' } },
};

export default function ottCaptionSettingsReducer(
  state: OTTCaptionSettingsState = initialState,
  action = {} as AnyAction
): OTTCaptionSettingsState {
  let nextState: OTTCaptionSettingsState;
  switch (action.type) {
    case actions.LOAD_LOCAL_CAPTIONS: {
      const { captions, audioTracks, captionsSettings } = action;
      nextState = {
        ...state,
        defaultCaptions: captions,
        defaultAudioTracks: audioTracks,
      };
      Object.keys(captionsSettings).forEach((id) => {
        nextState.byId[id].subOptionIndex = captionsSettings[id];
      });
      break;
    }
    case actions.SET_CAPTION_SETTINGS: {
      const { id, subOptionIndex } = action;
      nextState = {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[id],
            subOptionIndex,
          },
        },
      };
      updateLocalSettings(nextState.defaultCaptions, nextState.byId);
      break;
    }
    case actions.SET_DEFAULT_CAPTIONS: {
      nextState = {
        ...state,
        byId: {
          ...state.byId,
          [OPTIONS.SWITCH]: {
            ...state.byId[OPTIONS.SWITCH],
            title: { defaultMessage: `Captions ${action.enabled ? 'On' : 'Off'}` },
            subOptionIndex: findSubOptionIndex(
              CAPTIONS_OPTIONS.switch.subOptions,
              action.enabled ? SUB_OPTION_VALUE.ON : SUB_OPTION_VALUE.OFF
            ),
          },
        },
        defaultCaptions: {
          enabled: action.enabled,
          language: action.language || state.defaultCaptions.language,
        },
      };
      updateLocalSettings(nextState.defaultCaptions, nextState.byId);
      break;
    }
    case actions.SET_DEFAULT_AUDIO_TRACKS: {
      nextState = {
        ...state,
        defaultAudioTracks: action.audioTracks || '',
      };
      updateLocalAudioDescription(nextState.defaultAudioTracks, action.age);
      break;
    }
    case actions.RESET_CAPTION_SETTINGS: {
      nextState = {
        ...initialState,
        defaultCaptions: state.defaultCaptions,
        byId: {
          ...initialState.byId,
          [OPTIONS.SWITCH]: state.byId[OPTIONS.SWITCH],
        },
      };
      updateLocalSettings(nextState.defaultCaptions, nextState.byId);
      break;
    }
    default: {
      nextState = state;
      break;
    }
  }

  return nextState;
}
