import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import type { Error, UpdateCWonDeviceParams } from 'client/systemApi/types';
import { splitSpeechSynthesisUtteranceContent } from 'client/systemApi/utils';
import { isInJSDOM, isInComcastDevice } from 'client/utils/clientTools';
import { getModelNameForComcastFamily } from 'client/utils/comcast-family';
import { getLastCWItem } from 'client/utils/continue-watching';
import { loadFireboltSDK } from 'client/utils/fireboltSDK';
import { setAnalyticsConfig } from 'common/actions/tracking';
import { ENABLE_NATIVE_TTS, SET_OTT_DEVICE_INFO } from 'common/constants/action-types';
import { COOKIE_ADVERTISER_ID } from 'common/constants/cookies';
import { TRACK_LOGGING, LOG_SUB_TYPE } from 'common/constants/error-types';
import type ApiClient from 'common/helpers/ApiClient';
import logger from 'common/helpers/logging';
import tubiHistory from 'common/history';
import type StoreState from 'common/types/storeState';
import { actionWrapper } from 'common/utils/action';
import { isKidsModeOrIsParentalRatingOlderKidsOrLess } from 'common/utils/ratings';
import { trackLogging } from 'common/utils/track';
import * as sessionDataStorage from 'src/client/utils/sessionDataStorage';

import {
  COMCAST_MONEY_BADGER_EVENT,
  COMCAST_PLAYBACK_STATE_CHANGE,
  IS_COMCAST_DISMISS_LOADING_ANIMATION_CALLED,
} from './constants';
import BaseSystemApi from './systemApi';

declare global {
  interface Window { $badger: any; }
}

// https://developer.comcast.com/x1/apis/contextual-user-info/info
export const enum PartnerId {
  'ComcastXfinity' = 'comcast',
  'SmartTVDevice' = 'xglobal',
  'Cox' = 'cox',
  'CoxTrial' = 'cox-dev',
  'Rogers' = 'rogers',
  'RogersTrial' = 'rogers-dev',
  'Shaw' = 'shaw',
  'ShawTrial' = 'shaw-dev',
  'Videotron' = 'videotron',
  'VideotronTrial' = 'videotron-dev',
}

// flesh this out as we use this API more
export interface ComcastInfo {
  zipcode: string;
  partnerId: PartnerId;
  privacySettings: {
    lmt: 0 | 1;
    us_privacy: '1-N-' | '1-Y-'
  },
}

interface VoiceGuidanceSettings {
  enabled: boolean;
}

interface BadgerSetting {
  enabled: boolean;
  value: string;
}
export interface ComcastAdInfo {
  ifa: string;
  ifa_type: string;
  lmt: string;
}

const UHDDimension = [3840, 2160];

class ComcastFamilySystemApi extends BaseSystemApi {
  private badgerReadyResolve?: (value: undefined) => void;

  private checkBadgerReady: Promise<undefined> = new Promise((resolve) => {
    this.badgerReadyResolve = resolve;
  });

  private dispatch!: ThunkDispatch<StoreState, ApiClient, AnyAction>;

  getState!: () => StoreState;

  private zipcode = '';

  // On Comcast XClass (their smart TV) we need to send the ad attributes to rainmaker
  private adAttributes = '';

  // On Comcast we need to send the ad attributes to rainmaker
  private partnerId = '';

  private advertiserUSPrivacy: '1-N-' | '1-Y-' | undefined = undefined;

  private advertiserOptOut: 0 | 1 | undefined = undefined;

  private advertiserId: string | undefined = sessionDataStorage.getData(COOKIE_ADVERTISER_ID) ?? undefined;

  // dismissLoadingScreen should be called once, this attribute is flag for whether it has been called
  private isComcastDismissLoadingAnimationCalled: string | null;

  // Unique device ID for Comcast generated from firebolt SDK
  private uniqueDeviceId?: string;

  // Constructor is needed to be able to get 100% coverage.

  /* istanbul ignore next: necessary to ignore the '|| this' JS that TS generates */
  constructor() {
    super();

    this.isComcastDismissLoadingAnimationCalled = sessionDataStorage.getData(IS_COMCAST_DISMISS_LOADING_ANIMATION_CALLED);
  }

  init(dispatch: ThunkDispatch<StoreState, ApiClient, AnyAction>, getState: () => StoreState): void {
    this.dispatch = dispatch;
    this.getState = getState;
    const deviceModel = getModelNameForComcastFamily();
    if (deviceModel) {
      dispatch(actionWrapper(SET_OTT_DEVICE_INFO, {
        deviceModel,
      }));
    }
    if (window.$badger?.active()) {
      this.handleLaunch();
    } else {
      document.addEventListener(COMCAST_MONEY_BADGER_EVENT, this.handleLaunch);
    }
  }

  exit(): void {
    if (typeof window.$badger === 'undefined') return;
    window.$badger.userActionMetricsHandler('app shutdown by user');
    window.$badger.shutdown();
  }

  handleLaunch = (): void => {
    /* istanbul ignore next */
    this.badgerReadyResolve?.(undefined);
    this.setBadgerInfo();
    this.fetchAdAttributes(); // it must be after setBadgerInfo() as it rely on this.partnerId
    this.loadAdvertiserInfo();
    this.getVoiceGuidePreference();
    this.onLaunchComplete();
    this.logDeviceCapabilities();
    this.loadFireboltAndLogUid();
  };

  /**
   * @param contentState {String} - new state of the content as described by comcast/cox
   * @link https://developer.comcast.com/site/x1_ott_apps/design/operations/index.gsp#CONTENT_PLAYBACK
   */
  logPlaybackStateChange(contentState: string): void {
    if (typeof window.$badger === 'undefined') return;
    window.$badger.appActionMetricsHandler(COMCAST_PLAYBACK_STATE_CHANGE, { contentState });
  }

  /**
   * On certain models of Comcast (Xclass - their smart TV),
   * we need to get the deviceAdAttributes and pass it to rainmaker
   */
  fetchAdAttributes(): void {
    if (typeof window.$badger === 'undefined') return;

    window.$badger.adPlatform().deviceAdAttributes()
      .success((adAttributes: string) => {
        if (adAttributes) {
          this.adAttributes = adAttributes;
        }
      })
      .failure((error: string) => {
        if (this.partnerId === PartnerId.SmartTVDevice) {
          logger.error(error, 'Error getting badger deviceAdAttributes on Comcast Smart TV Device');
        }
      });
  }

  getAdAttributes(): string {
    return this.adAttributes;
  }

  getPartnerId() {
    return this.partnerId;
  }

  logError({ message, visible, code }: Error): void {
    if (typeof window.$badger === 'undefined') return;
    window.$badger.errorMetricsHandler(message, visible, code);
  }

  showNotification(message: string): void {
    if (typeof window.$badger === 'undefined') return;
    window.$badger.showToaster(message);
  }

  getZipcode() {
    return this.zipcode;
  }

  getBadgerSetting(badgerSetting: string): Promise<BadgerSetting> {
    return new Promise((resolve, reject) => {
      window.$badger.userPreferences().setting(badgerSetting)
        .success((setting: BadgerSetting) => {
          resolve(setting);
        })
        .failure((error: string) => {
          reject(error);
        });
    });
  }

  getDeviceCapabilities(): Promise<Record<string, unknown>> {
    return new Promise((resolve, reject) => {
      window.$badger.deviceCapabilities()
        .success((deviceCapabilities: Record<string, unknown>) => {
          resolve(deviceCapabilities);
        })
        .failure((error: string) => {
          reject(error);
        });
    });
  }

  logDeviceCapabilities() {
    return this.getDeviceCapabilities().then(deviceCapabilities => {
      trackLogging({
        type: TRACK_LOGGING.clientInfo,
        subtype: LOG_SUB_TYPE.DEVICE_CAPABILITIES,
        message: deviceCapabilities,
      });
    }).catch(error => {
      logger.error(error, 'Error getting deviceCapabilities on Comcast family');
    });
  }

  logUniqueDeviceId(uid: string) {
    trackLogging({
      type: TRACK_LOGGING.clientInfo,
      subtype: 'comcast_unique_device_id',
      message: uid,
    });
  }

  /* istanbul ignore next */
  textToSpeech(text: string): void {
    const { speechSynthesis, SpeechSynthesisUtterance } = window;
    if (!__CLIENT__ || !speechSynthesis || !SpeechSynthesisUtterance) return;
    speechSynthesis.cancel();
    splitSpeechSynthesisUtteranceContent(text).forEach((textChunk) => {
      speechSynthesis.speak(new SpeechSynthesisUtterance(textChunk));
    });
  }

  private getVoiceGuidePreference(): void {
    if (typeof window.$badger === 'undefined') {
      logger.error({ error: 'Cannot find Xfinity API' }, 'comcastApiError');
      return;
    }
    window.$badger.userPreferences().voiceGuidance()
      .success((voiceGuidanceSettings: VoiceGuidanceSettings) => {
        if (voiceGuidanceSettings.enabled) this.dispatch(actionWrapper(ENABLE_NATIVE_TTS));
      })
      .failure((error: string) => {
        logger.error({ error }, 'comcastApiError');
      });
  }

  // set values from badger info function call
  private setBadgerInfo(): void {
    if (typeof window.$badger === 'undefined') {
      logger.error('Cannot find Xfinity API');
      return;
    }
    window.$badger.info()
      .success((info: ComcastInfo) => {
        this.zipcode = info.zipcode;
        this.partnerId = info.partnerId;
        this.advertiserOptOut = info.privacySettings?.lmt;
        this.advertiserUSPrivacy = info.privacySettings?.us_privacy;
        this.dispatch(setAnalyticsConfig({ postal_code: info.zipcode }));
      })
      .failure((error: string) => {
        logger.error(error, 'Error getting badger info on Comcast/Cox');
      });
  }

  async loadFireboltAndLogUid() {
    try {
      const fireboltSDK = await loadFireboltSDK();
      this.uniqueDeviceId = await fireboltSDK.Device.uid();
      this.logUniqueDeviceId(this.uniqueDeviceId);

      /**
       * @temporary
       * https://docs.developer.comcast.com/docs/120-core-device#type
       * collects and logs the device type field for tracking purposes. TODO: delete.
       */
      const deviceType = await fireboltSDK.Device.type();
      trackLogging({
        type: TRACK_LOGGING.clientInfo,
        subtype: 'comcast_device_type',
        message: deviceType,
      });
    } catch (e) {
      logger.error(e, 'Error load Firebolt SDK and log uid');
    }
  }

  getAdvertiserOptOut() {
    return this.advertiserOptOut;
  }

  getAdvertiserUSPrivacy() {
    return this.advertiserUSPrivacy;
  }

  loadAdvertiserInfo() {
    if (typeof window.$badger === 'undefined' || this.advertiserId) return;

    window.$badger.adPlatform().advertisingId()
      .success((adInfo: ComcastAdInfo) => {
        this.advertiserId = adInfo.ifa;
        this.dispatch(setAnalyticsConfig({ advertiser_id: this.getAdvertiserId() }));
      })
      .failure((error: string) => {
        logger.error(error, `Error getting badger advertisingId on Comcast/Cox, partnerId: ${this.partnerId}`);
      });
  }

  getAdvertiserId() {
    return this.advertiserId;
  }

  /* istanbul ignore next */
  private onLaunchComplete(args = {}): void {
    if (typeof window.$badger === 'undefined') return;
    if (this.isComcastDismissLoadingAnimationCalled === 'true') {
      // to be on the safe side, we mannuly remove the state when it is called once
      // of course this is for removing the shared state when the home page is redirected from intro page
      sessionDataStorage.removeData(IS_COMCAST_DISMISS_LOADING_ANIMATION_CALLED);
      this.isComcastDismissLoadingAnimationCalled = null;
      return;
    }
    // docs: https://x1apps.comcast.com/reference#dismissloadingscreen-1
    window.$badger.dismissLoadingScreen(args);
  }

  async updateCWonDevice({ removeCWfromDevice, cwItem, shouldSendEvent }: UpdateCWonDeviceParams = { shouldSendEvent: false }) {
    if (!shouldSendEvent || isKidsModeOrIsParentalRatingOlderKidsOrLess(this.getState())) {
      return;
    }

    try {
      const isWatchHistoryEnabled = (await this.getBadgerSetting(window.$badger.PREFERENCES.SHARE_WATCH_HISTORY_STATUS)).enabled;
      if (!isWatchHistoryEnabled) return;

      if (removeCWfromDevice && cwItem) {
        const { contentId, positionInSeconds: progress } = cwItem;
        window.$badger.accountLink().mediaEvent({
          contentId,
          completed: true,
          progress,
          progressUnits: 'seconds',
        });
      } else {
        let contentId = '';
        let position = 0;
        const lastItem = await getLastCWItem(tubiHistory.getCurrentLocation(), this.getState, this.dispatch);
        if (lastItem) {
          contentId = lastItem.cwContentId;
          position = lastItem.historyItem.position;
        }
        if (contentId) {
          window.$badger.accountLink().mediaEvent({
            contentId,
            completed: false,
            progress: position,
            progressUnits: 'seconds',
          });
        }
      }
    } catch (error) {
      logger.error(error, 'comcastApiError Error updating PMR with CW item');
    }
  }

  async getVideoDimension(): Promise<number[]> {
    await this.checkBadgerReady;
    const deviceCapabilities = await this.getDeviceCapabilities();
    if (Array.isArray(deviceCapabilities.videoDimensions)) {
      return deviceCapabilities.videoDimensions;
    }
    return [0, 0];
  }

  async support4KDisplay(): Promise<boolean> {
    /* istanbul ignore next */
    if (!isInJSDOM() && !isInComcastDevice()) {
      return false;
    }
    await this.checkBadgerReady;
    return this.getDeviceCapabilities().then((deviceCapabilities) => {
      const videoDimensions = deviceCapabilities.videoDimensions;
      const support4K = Array.isArray(videoDimensions) && videoDimensions.length === 2
            && videoDimensions[0] >= UHDDimension[0]
            && videoDimensions[1] >= UHDDimension[1];
      return support4K;
    });
  }

  support4KDecode(): Promise<boolean> {
    return this.support4KDisplay();
  }

  onSignIn(): void {
    try {
      if (__OTTPLATFORM__ === 'COMCAST') {
        window.$badger.accountLink().signIn();
      }
    } catch (error) {
      logger.error(error, 'comcastApiError Error calling accountLink signIn');
    }
  }

  onSignOut(): void {
    try {
      if (__OTTPLATFORM__ === 'COMCAST') {
        window.$badger.accountLink().signOut();
      }
    } catch (error) {
      logger.error(error, 'comcastApiError Error calling accountLink signOut');
    }
  }
}

export default ComcastFamilySystemApi;
