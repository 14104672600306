import jwt_decode from 'jwt-decode';
import { v4 as uuid } from 'uuid';

import { getCookie, setCookie } from 'client/utils/localDataStorage';
import { ONE_HUNDRED_YEARS } from 'common/constants/constants';
import { setDeviceId as setDeviceIdAction } from 'common/features/authentication/actions/auth';
import ApiClient from 'common/helpers/ApiClient';
import type { TubiStore } from 'common/types/storeState';
import { getRefreshTokenFromStorage, setDeviceIdForTokenRequests } from 'common/utils/token';

const client = new ApiClient();

const setDeviceId = (store: TubiStore, deviceId: string) => {
  setDeviceIdForTokenRequests(deviceId);
  setCookie('deviceId', deviceId, ONE_HUNDRED_YEARS);
  store.dispatch(setDeviceIdAction(deviceId));
};

export const setupDeviceId = (store: TubiStore) => {
  try {
    // TODO @cbengtson remove this logic after logs indicate users are no longer seeing a mismatch
    /* istanbul ignore next */
    if (__OTTPLATFORM__ === 'TIZEN') {
      const state = store.getState();
      const { auth } = state;
      const isLoggedIn = !!auth.user;
      const token = isLoggedIn ? auth.user?.token : getRefreshTokenFromStorage();
      if (token) {
        const { device_id } = jwt_decode<any>(token);
        if (device_id !== auth.deviceId) {
          client.sendBeacon('/oz/log', {
            data: {
              errorStatus: '204',
              errorMessage: `token: ${device_id} cookie: ${auth.deviceId} useragent: ${typeof navigator !== 'undefined' && navigator.userAgent}`,
              customLogMessage: `${isLoggedIn ? 'Logged In' : 'Guest'} deviceId mismatch between cookie and token`,
            },
          });
        }
      }
    }
  /* istanbul ignore next */
  } catch (error) {
    // do nothing, just don't break the app
  }
  if (__OTTPLATFORM__ === 'TIZEN') {
    // On samsung, getCookie will always return undefined because it uses
    // the file protocol. We need to do something to support getCookie on
    // samsung when we implement failsafe mode for that platform, but for now,
    // we'll just skip this logic to avoid resetting users' device_id cookies.
    return;
  }

  const deviceId = getCookie('deviceId');
  const storedDeviceId = store.getState().auth.deviceId;

  if (!deviceId) {
    setDeviceId(store, uuid());
  } else if (deviceId !== storedDeviceId) {
    // The failsafe page comes with a deviceId in the store state that was
    // generated at compile time. This deviceId will need to be replaced, so
    // replace the mismatched deviceId in the store with the correct deviceId
    // in the cookie.
    setDeviceId(store, deviceId);
  }
};
