import type ApiClient from 'common/helpers/ApiClient';

/**
 * Possibly fire a tracking pixel if the URL passed in is non-empty
 */
export function fireTrackingPixel(client: ApiClient, url: string): '' | Promise<any> {
  const replacedUrl = (url || '')
    // Handle the cache-buster "macro" that may be present in some of the pixel URLs
    .replace('(ADRISE:CB)', `${Date.now()}`);
  // According to CCS people, a simple GET request should be sufficient for the pixel URLs.
  // Apparently they don't have to be actual image requests.
  return replacedUrl && client.get(replacedUrl).catch(() => { /* suppress errors with pixel requests, we explicitly don't care */ });
}
