import { useCallback } from 'react';

import { showUnsupportedPurpleCarpetDeviceModal } from 'common/actions/modal';
import { isPurpleCarpetSupportedSelector } from 'common/features/purpleCarpet/selector';
import useAppDispatch from 'common/hooks/useAppDispatch';
import useAppSelector from 'common/hooks/useAppSelector';

export const useUnsupportedDeviceModal = (id?: string) => {
  const isSupported = useAppSelector(isPurpleCarpetSupportedSelector);
  const dispatch = useAppDispatch();
  const showUnsupportedDeviceModal = useCallback(() => {
    if (!isSupported) {
      dispatch(showUnsupportedPurpleCarpetDeviceModal({ id }));
    }
  }, [isSupported, dispatch, id]);
  return { showUnsupportedDeviceModal, isSupported };
};
