import type { AnyAction } from 'redux';

import type { FireState, InAppMessage } from 'common/types/fire';

import {
  APP_RESET_START,
  APP_RESET_SUCCESS,
  APP_RESET_FAIL,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_OTT_SELECTED_CONTAINER,
  SET_OTT_SELECTED_SECTION,
  SET_SELECTED_SERIES_EPISODE,
  RESET_OTT_CONTAINER_INDEX_MAP,
  WEBMAF_VIDEO_ENTER,
  WEBMAF_VIDEO_EXIT,
  BATCH_ADD_VIDEOS_AND_REMOVE_OLD,
  SET_NATIVE_APP_VERSION,
  SET_SDK_VERSION,
  SET_MODEL_CODE,
  SET_IS_FIRST_SESSION,
  HOT_DEEPLINK_START,
  HOT_DEEPLINK_FINISHED,
  SET_EXIT_SIGNUP_MODAL_STATUS,
  SET_SIGN_OUT_TOAST_STATUS,
  SET_IN_APP_MESSAGE_CONTENT,
  DISMISS_IN_APP_MESSAGE_CONTENT,
  SET_IN_APP_MESSAGE_TOAST_VISIBLE,
  SET_VIDEO_QUALITY_ON_LOAD,
} from '../constants/action-types';
import { FEATURED_CONTAINER_ID, GRID_SECTION } from '../constants/constants';

const actions = {
  APP_RESET_START,
  APP_RESET_SUCCESS,
  APP_RESET_FAIL,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_OTT_SELECTED_CONTAINER,
  SET_OTT_SELECTED_SECTION,
  SET_SELECTED_SERIES_EPISODE,
  RESET_OTT_CONTAINER_INDEX_MAP,
  WEBMAF_VIDEO_ENTER,
  WEBMAF_VIDEO_EXIT,
  BATCH_ADD_VIDEOS_AND_REMOVE_OLD,
  SET_NATIVE_APP_VERSION,
  SET_SDK_VERSION,
  SET_MODEL_CODE,
  SET_IS_FIRST_SESSION,
  HOT_DEEPLINK_START,
  HOT_DEEPLINK_FINISHED,
  SET_EXIT_SIGNUP_MODAL_STATUS,
  SET_SIGN_OUT_TOAST_STATUS,
  SET_IN_APP_MESSAGE_CONTENT,
  DISMISS_IN_APP_MESSAGE_CONTENT,
  SET_IN_APP_MESSAGE_TOAST_VISIBLE,
  SET_VIDEO_QUALITY_ON_LOAD,
};

export const initialContainerUi: FireState['containerUI'] = {
  containerId: FEATURED_CONTAINER_ID,
  // can be NAV_LIST_SECTION or GRID_SECTION
  section: GRID_SECTION,
};

// episode position will be set in mapState
const initialEpisodeUI = {
  seriesId: null,
  activeSeasonIndex: null,
  activeEpisodeIndex: null,
  seasonTabActive: false,
};

const initialInAppMessage: InAppMessage = {
  isToastVisible: false,
  showToast: false,
  showPrompt: false,
  messageContents: null,
};

/**
 * modalEl is the React Element usually wrapped in <Modal> component
 * contId and gridIndex are used to position 'selector' element
 * @type {{showModal: boolean, modalEl: null, playing: boolean, grid: *[]}}
 */
export const initialState = {
  showModal: false,
  modal: undefined,
  containerUI: initialContainerUi,
  episodeUI: initialEpisodeUI,
  webmafVideoIsPlaying: false,
  // top level component can block transitions during appReset
  appResetInProgress: false,
  // hyb app versions info, include native semver, clientVersion(combine of native and webview versions), etc
  appVersion: {},
  // todo(liam) unify app version, sdk version, client version, hyb app version, etc
  // float
  sdkVersion: 0,
  modelCode: '',
  isFirstSession: false,
  hotDeeplinkInProgress: false,
  hasShownExitSignUpModal: false,
  inAppMessage: initialInAppMessage,
  shouldSetVideoQualityOnLoad: false,
} as unknown as FireState;

export default function fireReducer(state: FireState = initialState, action = {} as AnyAction): FireState {
  switch (action.type) {
    case actions.APP_RESET_START:
      return {
        ...state,
        appResetInProgress: true,
      };
    case actions.APP_RESET_SUCCESS:
    case actions.APP_RESET_FAIL:
      return {
        ...state,
        appResetInProgress: false,
      };
    case actions.HOT_DEEPLINK_START:
      return {
        ...state,
        hotDeeplinkInProgress: true,
      };
    case actions.HOT_DEEPLINK_FINISHED:
      return {
        ...state,
        hotDeeplinkInProgress: false,
      };
    case actions.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        modal: action.modal,
      };
    case actions.HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        modal: undefined,
      };
    case actions.SET_OTT_SELECTED_CONTAINER:
      return {
        ...state,
        containerUI: {
          ...state.containerUI,
          containerId: action.containerId,
        },
      };
    case actions.SET_OTT_SELECTED_SECTION:
      return {
        ...state,
        containerUI: {
          ...state.containerUI,
          section: action.section,
        },
      };
    case actions.SET_SELECTED_SERIES_EPISODE:
      return {
        ...state,
        episodeUI: {
          ...state.episodeUI,
          ...action.episodeUI,
        },
      };
    case actions.RESET_OTT_CONTAINER_INDEX_MAP:
      return {
        ...state,
        containerUI: initialContainerUi,
      };
    case actions.WEBMAF_VIDEO_ENTER:
      return {
        ...state,
        webmafVideoIsPlaying: true,
      };
    case actions.WEBMAF_VIDEO_EXIT:
      return {
        ...state,
        webmafVideoIsPlaying: false,
      };
    case actions.BATCH_ADD_VIDEOS_AND_REMOVE_OLD:
      return {
        ...state,
        containerUI: initialContainerUi,
      };
    case actions.SET_NATIVE_APP_VERSION:
      return {
        ...state,
        appVersion: action.version,
      };
    case actions.SET_SDK_VERSION:
      return {
        ...state,
        sdkVersion: action.sdkVersion,
      };
    case actions.SET_MODEL_CODE:
      return {
        ...state,
        modelCode: action.modelCode,
      };
    case actions.SET_IS_FIRST_SESSION:
      return {
        ...state,
        isFirstSession: action.state,
      };
    case actions.SET_VIDEO_QUALITY_ON_LOAD:
      return {
        ...state,
        shouldSetVideoQualityOnLoad: action.payload,
      };
    case actions.SET_EXIT_SIGNUP_MODAL_STATUS:
      return {
        ...state,
        hasShownExitSignUpModal: action.hasShown,
      };
    case actions.SET_SIGN_OUT_TOAST_STATUS:
      return {
        ...state,
        showSignOutToast: action.payload,
      };
    case actions.SET_IN_APP_MESSAGE_CONTENT:
      return {
        ...state,
        inAppMessage: action.payload,
      };
    case actions.DISMISS_IN_APP_MESSAGE_CONTENT:
      return {
        ...state,
        inAppMessage: initialInAppMessage,
      };
    case actions.SET_IN_APP_MESSAGE_TOAST_VISIBLE: {
      const inAppMessageState = { ...state.inAppMessage, isToastVisible: action.payload };
      return {
        ...state,
        inAppMessage: inAppMessageState,
      };
    }
    default:
      return state;
  }
}
