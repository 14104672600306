import { PlayIcon } from '@tubitv/ott-ui';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { WithRouterProps } from 'react-router';
import { withRouter } from 'react-router';

import { loadContainer, setContainerContext } from 'common/actions/container';
import Tubi from 'common/components/uilib/SvgLibrary/Tubi';
import { SCREENSAVER_CONTAINER_ID } from 'common/constants/constants';
import { TRACK_LOGGING, LOG_SUB_TYPE } from 'common/constants/error-types';
import { OTT_ROUTES } from 'common/constants/routes';
import logger from 'common/helpers/logging';
import tubiHistory from 'common/history';
import { screensaverBgSelector, screensaverMetadataSelector } from 'common/selectors/container';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import type { StoreState } from 'common/types/storeState';
import { prependEventListener, removeEventListener } from 'common/utils/dom';
import { isOTTKeys, getOTTRemote } from 'common/utils/keymap';
import { matchesUrlTemplate } from 'common/utils/location';
import { preloadImages } from 'common/utils/preloadImages';
import { trackLogging } from 'common/utils/track';
import OTTBackground from 'ott/components/OTTBackground/OTTBackground';
import OTTBackgroundGradient from 'ott/components/OTTBackgroundGradient/OTTBackgroundGradient';
import { GenreList } from 'ott/components/OTTContentMetaComponents/OTTContentMetaComponents';

import styles from './OTTScreensaver.scss';

const REMOTE = getOTTRemote();
const INTERVAL_TIMING_MS = 5000;

const messages = defineMessages({
  cta: {
    description: 'cta text',
    defaultMessage: 'PRESS{icon}FOR MORE DETAILS',
  },
});

type metadataType = {
  title: string;
  genres?: string[];
  url: string;
};

interface StateProps {
  backgrounds: string[];
  metadata: metadataType[];
  isKidsModeEnabled: boolean;
  pathname: string;
}

interface OwnProps {
  setPathnameRedirectFromScreensaver: (url: string) => void;
}

export interface OTTScreensaverProps extends StateProps, OwnProps, WithRouterProps {
  dispatch: TubiThunkDispatch;
}

interface OTTScreensaverState {
  activeIdx: number;
}

export class OTTScreensaver extends Component<OTTScreensaverProps, OTTScreensaverState> {
  private interval: ReturnType<typeof setTimeout> | null;

  static defaultProps = {
    backgrounds: [],
  };

  constructor(props: OTTScreensaverProps) {
    super(props);
    this.interval = null;
    this.state = {
      activeIdx: 0,
    };
  }

  componentDidMount() {
    trackLogging({
      type: TRACK_LOGGING.clientInfo,
      level: 'info',
      message: 'OTTScreenSaver Started',
      subtype: LOG_SUB_TYPE.UI,
    });
    const { dispatch, backgrounds, metadata, setPathnameRedirectFromScreensaver, location } = this.props;
    if (!backgrounds.length) {
      dispatch(loadContainer({ location, id: SCREENSAVER_CONTAINER_ID }));
      logger.error({ containerId: SCREENSAVER_CONTAINER_ID }, 'Screensaver content is empty');
    }

    preloadImages(backgrounds);

    setPathnameRedirectFromScreensaver(metadata[0]?.url);

    this.interval = setInterval(this.onTick, INTERVAL_TIMING_MS);

    prependEventListener(window, 'keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    trackLogging({
      type: TRACK_LOGGING.clientInfo,
      level: 'info',
      message: 'OTTScreenSaver Stopped',
      subtype: LOG_SUB_TYPE.UI,
    });
    removeEventListener(window, 'keydown', this.handleKeydown);
    /* istanbul ignore else */
    if (this.interval) clearInterval(this.interval);
  }

  handleKeydown = (e: KeyboardEvent) => {
    e.stopImmediatePropagation();

    const { keyCode } = e;
    const { dispatch, metadata, pathname, backgrounds } = this.props;
    let isNavToNewPage = false;
    // metadata only has value on Samsung
    if (!isOTTKeys(keyCode) || metadata.length === 0) return;

    const { activeIdx } = this.state;
    const { url: pathnameRedirectFromScreensaver } = metadata[activeIdx];
    const isPlayButton = keyCode === REMOTE.play || keyCode === REMOTE.playPause;
    if (isPlayButton && pathname !== pathnameRedirectFromScreensaver) {
      dispatch(setContainerContext(SCREENSAVER_CONTAINER_ID));
      // Replace the url if the current page is video/series page to avoid storing multiple video/series detail pages in the browser history.
      if (matchesUrlTemplate(pathname, OTT_ROUTES.series) || matchesUrlTemplate(pathname, OTT_ROUTES.video)) {
        tubiHistory.replace(pathnameRedirectFromScreensaver);
      } else {
        tubiHistory.push(pathnameRedirectFromScreensaver);
      }
      isNavToNewPage = true;
    } else if (keyCode === REMOTE.arrowRight) {
      const newIndex = (activeIdx + 1) % backgrounds.length;
      this.updateBackground(newIndex);
      this.resetTimer();
    } else if (keyCode === REMOTE.arrowLeft) {
      const newIndex = (activeIdx - 1) < 0 ? backgrounds.length - 1 : activeIdx - 1;
      this.updateBackground(newIndex);
      this.resetTimer();
    }
    trackLogging({
      type: TRACK_LOGGING.clientInfo,
      level: 'info',
      message: {
        msg: 'OTTScreenSaver onKeyDown',
        keyCode,
        pathFrom: pathname,
        pathTo: isNavToNewPage ? pathnameRedirectFromScreensaver : pathname,
        isNavToNewPage,
        isPlayButton,
      },
      subtype: LOG_SUB_TYPE.UI,
    });
  };

  onTick = () => {
    const { activeIdx } = this.state;
    const { backgrounds } = this.props;
    this.updateBackground((activeIdx + 1) % backgrounds.length);
  };

  updateBackground = (newIndex: number) => {
    const { activeIdx } = this.state;
    const { metadata, setPathnameRedirectFromScreensaver } = this.props;
    setPathnameRedirectFromScreensaver(metadata[activeIdx]?.url);
    this.setState({ activeIdx: newIndex });
  };

  resetTimer = () => {
    /* istanbul ignore else */
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(this.onTick, INTERVAL_TIMING_MS);
  };

  render() {
    const { backgrounds, metadata, isKidsModeEnabled } = this.props;
    const { activeIdx } = this.state;

    return (
      <div className={styles.screenSaver}>
        <OTTBackground
          key={backgrounds.length}
          gradientClass={styles.noGradient}
          fullScreen
          hideBackgroundCircleCrop
          imageUrls={backgrounds}
          imageUrlIndex={activeIdx}
          transitionTimer={10 * 1000}
          transitionAppear
        />
        {
          metadata.length > 0
            ? <React.Fragment>
              <OTTBackgroundGradient
                fullScreen
                isKidsModeEnabled={isKidsModeEnabled}
                isScreenSaver
              />
              <div className={styles.metadata}>
                <div className={styles.cta}>
                  <FormattedMessage {...messages.cta} values={{ icon: <PlayIcon /> }} />
                </div>
                <div className={styles.title}>{metadata[activeIdx].title}</div>
                <GenreList genres={metadata[activeIdx].genres} cls={styles.genre} />
              </div>
            </React.Fragment> : null
        }
        <Tubi className={isKidsModeEnabled ? styles.kidsLogo : styles.logo} isKidsModeEnabled={isKidsModeEnabled} />
      </div>
    );
  }
}

export const mapStateToProps = (state: StoreState, { location }: Pick<WithRouterProps, 'location'>): StateProps => {
  const {
    ui: {
      isKidsModeEnabled,
    },
  } = state;

  return {
    backgrounds: screensaverBgSelector(state),
    metadata: __OTTPLATFORM__ === 'TIZEN' ? screensaverMetadataSelector(state) : [],
    isKidsModeEnabled,
    pathname: location.pathname,
  };
};

const connectedComponent = withRouter(connect(mapStateToProps)(OTTScreensaver));

export default connectedComponent;
