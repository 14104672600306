import type { EventTags } from 'client/utils/performance';
import { markEnd, markStart, measure, sendEvent, sendPerformanceLogging } from 'client/utils/performance';
import { SCROLL_DELAY_SHOULD_COLLECT_TIMES } from 'common/constants/performance-metrics';

type NavigationDetailOptions = {
  containerId?: string;
  index?: number;
  ifBgImageMatchActiveContent?: boolean;
};

type NavigationDetails = {
  duration: number;
} & NavigationDetailOptions;

export class UINavigationPerformanceTrackingManager {

  private static instance: UINavigationPerformanceTrackingManager;

  private record: Record<string, Record<string, NavigationDetails[]>> = {}; // { page: { metric: [{ duration: xx }] } }

  private maxNavigationCollectedCount: number = SCROLL_DELAY_SHOULD_COLLECT_TIMES;

  static getInstance(): UINavigationPerformanceTrackingManager {
    if (!UINavigationPerformanceTrackingManager.instance) {
      UINavigationPerformanceTrackingManager.instance = new UINavigationPerformanceTrackingManager();
    }
    return UINavigationPerformanceTrackingManager.instance;
  }

  getData(): Record<string, Record<string, NavigationDetails[]>> {
    return this.record;
  }

  start(metricName: string) {
    markStart(metricName);
  }

  end(metricName: string, page: string, details?: NavigationDetailOptions, detailsMetricName?: string, tags?: EventTags) {
    if (!page || !metricName) {
      throw new Error('Invalid parameters. Please provide a valid page and metricName.');
    }

    markEnd(metricName);
    const duration = measure(metricName);

    if (!duration) {
      throw new Error(`Recording was not started or no matching start for ${metricName} on ${page}.`);
    }

    if (!this.record[page]?.[metricName]) {
      this.record[page] = {
        ...this.record[page],
        [metricName]: [],
      };
    }

    this.record[page][metricName].push({
      ...details || {},
      duration: Number(duration.toFixed(2)),
    });

    if (this.record[page][metricName].length >= this.maxNavigationCollectedCount) {
      this.send(page, metricName, detailsMetricName, tags);
    }
  }

  private send(page: string, metricName: string, detailsMetricName?: string, tags?: EventTags): void {
    const total = this.record[page][metricName].reduce((t, v) => t + v.duration, 0);
    const averageDelay = (total / this.record[page][metricName].length).toFixed(2);

    sendEvent(
      {
        name: metricName,
        value: Number(averageDelay),
      },
      tags
    );

    if (detailsMetricName) {
      sendPerformanceLogging({
        name: detailsMetricName,
        value: this.record[page][metricName],
      });
    }

    this.record[page][metricName] = [];
  }
}

