export const ACCESSIBILITY_FIRST_NAME_FIELD_ID = 28533548108443;
export const ACCESSIBILITY_LAST_NAME_FIELD_ID = 28533583423387;
export const ACCESSIBILITY_EMAIL_ADDRESS_FIELD_ID = 28533556034203;
export const ACCESSIBILITY_FEEDBACK_FIELD_ID = 28533683430811;
export const ACCESSIBILITY_FORM_ID = 28533549250715;
export const LEGAL_GROUP_ID = 22379681431579;

export const ACCESSIBILITY_ARTICLE_ID = 15056366740763;

export const ACCESSIBILITY_TAG = 'topic-accessibility';
export const ACCESSIBILITY_SUBJECT = 'Accessibility Feedback Process';
export const ACCESSIBILITY_ANONYMOUS_EMAIL = 'accessibility-anonymous@anonymous.com';

export const ALLOWED_ACCESSIBILITY_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'feedback',
];
