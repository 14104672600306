import { getBridge, isBridgeAvailable, setupDsBridgeForDevEnv } from 'client/utils/clientTools';
import { FREEZED_EMPTY_FUNCTION } from 'common/constants/constants';
import { ONETRUST_CONSENT } from 'common/features/gdpr/onetrust/constants';
import logger from 'common/helpers/logging';
import type { OneTrustConsents } from 'types/onetrust';

export function updateGDPRConsentBridge({ consents, tcf }:{consents: OneTrustConsents, tcf: string}) {
  if (!isBridgeAvailable()) {
    return;
  }
  if (__DEVELOPMENT__) {
    setupDsBridgeForDevEnv({
      updateGDPRConsent: FREEZED_EMPTY_FUNCTION,
    });
  }
  try {
    /* istanbul ignore next */
    const bridge = getBridge({ debug: !__PRODUCTION__ || __IS_ALPHA_ENV__ });
    const consentMap: Record<string, boolean> = {};
    consents.forEach(({ id, consent }) => {
      consentMap[id] = !!consent;
    });
    bridge.callHandler('updateGDPRConsent', {
      consents: {
        analytics: !!consentMap[ONETRUST_CONSENT.analytics],
        functional: !!consentMap[ONETRUST_CONSENT.functional],
        personalized_advertising: !!consentMap[ONETRUST_CONSENT.personalized_advertising],
        marketing: !!consentMap[ONETRUST_CONSENT.marketing],
      },
      tcf,
    });
  } catch (e) {
    logger.error('Update consent for native failed', e);
  }
}
