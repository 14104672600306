import zipWith from 'lodash/zipWith';
import type { Store } from 'redux';

import type { ExperimentID, ExperimentTreatment } from 'common/experiments/ExperimentManager';
import ExperimentManager from 'common/experiments/ExperimentManager';
import logger from 'common/helpers/logging';
import type { StoreState } from 'common/types/storeState';
import { dispatchKeyboardEvent } from 'common/utils/dom';
import { getOTTRemote } from 'common/utils/keymap';
import rolloutOverride from 'common/utils/rolloutOverride';
import PlayerDemoConfigManager from 'ott/containers/Dev/PlayerDemo/ConfigManager';

interface ExperimentInfo {
  id: ExperimentID;
  value: unknown;
  canOverride: boolean;
  isOverridden: boolean;
  treatment: string | null;
  treatments: ExperimentTreatment<unknown, string>[];
}

interface PlayerConfig {
  path: string[];
  value: string | number;
}

function getTubiDebugBridge(): Promise<RemoteDebugger['debugBridge'] | undefined> {
  if (window.remoteDebuggerLauncher?.getDebugBridge()) {
    return Promise.resolve(window.remoteDebuggerLauncher.getDebugBridge());
  }
  return new Promise((resolve) => {
    window.addEventListener('remoteDebuggerCreated', () => {
      resolve(window.remoteDebuggerLauncher?.getDebugBridge());
    });
  });
}

export const init = async (store: Store) => {
  const tubiDebugBridge = await getTubiDebugBridge();

  /* istanbul ignore next */
  if (!tubiDebugBridge) {
    logger.error('There is no tubi debug bridge');
    return;
  }

  tubiDebugBridge.addNamespace('Experiment', {
    getRolloutOverrideStatus(): boolean {
      return rolloutOverride.getStatus();
    },
    setRolloutOverrideStatus(status: boolean): void {
      rolloutOverride.setStatus(status);
    },
    async getAllExperiments(): Promise<ExperimentInfo[]> {
      const experimentsArr = ExperimentManager()
        .getExperiments()
        .filter((exp) => exp.shouldFetchNamespace);
      const treatmentsArr = await Promise.all(experimentsArr.map((exp) => exp.getTreatments()));
      const experimentStatus = await ExperimentManager().validateExperimentConfigs(experimentsArr);
      return zipWith(experimentsArr, treatmentsArr, (experiment, treatments) => {
        return {
          id: experiment.id,
          namespace: experiment.namespace,
          experimentName: experiment.configuredExperimentName,
          value: experiment.getValue(),
          isDeployed: experimentStatus[experiment.id].isDeployed,
          isActive: experimentStatus[experiment.id].isActive,
          canOverride: experiment.canOverride(),
          isOverridden: experiment.isOverriddenOrWhitelisted(),
          treatment: experiment.treatment,
          treatmentFromServer: experiment.treatmentFromServer,
          treatments,
        };
      });
    },
    overrideExperiment(id: ExperimentID, treatment: string): boolean {
      const experiment = ExperimentManager().getExperiment(id);
      if (!experiment) return false;
      experiment.enableOverride(treatment);
      return true;
    },
    disableOverride(id: ExperimentID) {
      const experiment = ExperimentManager().getExperiment(id);
      if (!experiment) return false;
      experiment.disableOverride();
      return true;
    },
    whitelist(id: ExperimentID, treatment: string): Promise<void> {
      const experiment = ExperimentManager().getExperiment(id);
      if (experiment) {
        return experiment.whitelist(treatment);
      }
      return Promise.resolve();
    },
    refresh(): Promise<void> {
      return ExperimentManager().fetchAllNamespaces();
    },
  });

  tubiDebugBridge.addNamespace('Player', {
    getPlayerConfig() {
      return {
        settings: PlayerDemoConfigManager.getSettings(),
        config: PlayerDemoConfigManager.PLAYER_DEMO_CONFIG,
      };
    },
    setPlayerConfig(config: PlayerConfig[]) {
      config.forEach(item => {
        PlayerDemoConfigManager.set(item.path, item.value);
      });
    },
  });

  tubiDebugBridge.addNamespace('Redux', {
    getState(): StoreState {
      return store.getState();
    },
  });

  tubiDebugBridge.addNamespace('KeyboardDispatcher', {
    run(e: KeyboardEventInit & { remoteKey?: string; type?: string }): void {
      const { remoteKey, type, ...rest } = e;
      if (remoteKey && type) {
        const keyCode = getOTTRemote()[remoteKey];
        if (!Number.isNaN(keyCode)) {
          dispatchKeyboardEvent(window, type, { keyCode, ...rest });
        }
        logger.debug({ keyCode, type, ...rest }, '[REMOTE DEBUGGER] remote keyboard event');
      }
    },
  });
};
