import type { AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { supportsVoiceViewAccessibility } from 'client/utils/clientTools';
import { CLEAR_A11Y, SET_A11Y } from 'common/constants/action-types';
import type { A11yState } from 'common/types/a11y';

import type ApiClient from '../helpers/ApiClient';
import type StoreState from '../types/storeState';

export const setA11y = ({ text, onceBefore, onceAfter, cancelable }: Partial<A11yState>) => ({
  type: SET_A11Y,
  text,
  onceBefore,
  onceAfter,
  cancelable,
});

export const clearA11y = () => ({ type: CLEAR_A11Y });

export type IfEnabledCallback =
  | (() => Promise<Partial<A11yState>>)
  | ((currentState: A11yState) => Promise<Partial<A11yState>>);

export const lazySetA11y = (ifEnabledCallback: IfEnabledCallback): ThunkAction<Promise<void>, StoreState, ApiClient, AnyAction> => {
  return (dispatch, getState) => {
    if (!supportsVoiceViewAccessibility()) return Promise.resolve();
    return ifEnabledCallback(getState().a11y).then((a11yState) => {
      const { text, onceBefore, onceAfter, cancelable } = a11yState;
      const {
        a11y: { text: stateText, onceBefore: stateOnceBefore, onceAfter: stateOnceAfter, cancelable: stateCancelable },
      } = getState();
      const params = {
        // if text is not specified in the returned object,
        // just reuse the current value.
        text: text != null ? text : stateText,
        onceBefore: onceBefore != null ? onceBefore : stateOnceBefore,
        onceAfter: onceAfter != null ? onceAfter : stateOnceAfter,
        cancelable,
      };
      if (
        params.text !== stateText
        || params.onceBefore !== stateOnceBefore
        || params.onceAfter !== stateOnceAfter
        || params.cancelable !== stateCancelable
      ) {
        dispatch({
          type: SET_A11Y,
          ...params,
        });
      }
    });
  };
};

/**
 * Conditionally replace a single property of the a11y store state if the current value matches.
 * @param property 'onceBefore', 'onceAfter' or 'text'.
 * @param replaceTarget The condition (either substring or regex) that the current value must match.
 * @param replacement The replacement for the `replaceTarget` if it matches.
 */
export const replaceA11yText = (property: keyof A11yState, replaceTarget: string | RegExp, replacement: string) => {
  return lazySetA11y((currentState): Promise<Partial<A11yState>> => {
    const currentValue = currentState[property];
    // if no current string to replace, don't change anything
    if (typeof currentValue !== 'string') return Promise.resolve(currentState);
    const valueMatches = typeof replaceTarget === 'string' ? currentValue.indexOf(replaceTarget) > -1 : currentValue.match(replaceTarget);
    if (!valueMatches) return Promise.resolve(currentState);
    const newState = {
      [property]: currentValue.replace(replaceTarget, replacement),
    };
    return Promise.resolve(newState);
  });
};
