import React from 'react';

/**
 * This is just a shell component that loads the code for the real component if on Android TV.
 * Otherwise, it does nothing.
 * @constructor
 */
const TalkbackWarningLoader: React.FC = () => {
  if (__IS_ANDROIDTV_HYB_PLATFORM__) {
    const Contents = require('./TalkbackWarning').default;
    return <Contents />;
  }
  return null;
};

export default TalkbackWarningLoader;
