import type { AccountPageType } from '@tubitv/analytics/lib/pages';
import type { Location } from 'history';
import trimEnd from 'lodash/trimEnd';
import type { ReactNode } from 'react';
import type React from 'react';
import type { IntlFormatters, MessageDescriptor, WrappedComponentProps } from 'react-intl';
import { defineMessages } from 'react-intl';
import type { Action } from 'redux';

import { isLinear } from 'client/features/playback/predicates/isLinear';
import { CONTAINER_TYPES, NAV_LIST_SECTION, SERIES_CONTENT_TYPE, VIDEO_CONTENT_TYPE } from 'common/constants/constants';
import { sponsorshipMessages, commonVoiceMessages } from 'common/constants/constants-message';
import { OTT_ROUTES } from 'common/constants/routes';
import { containerSelector } from 'common/selectors/container';
import type { A11yState } from 'common/types/a11y';
import type { ContainerType, Sponsorship, Container } from 'common/types/container';
import type { ChannelEPGInfo } from 'common/types/epg';
import type { ContentItem } from 'common/types/fire';
import type { ContentDetailPageNavOption } from 'common/types/ottUI';
import type { StoreState } from 'common/types/storeState';
import type { Video, VideoRating, VideoType } from 'common/types/video';
import { secondsToHoursAndMinutes } from 'common/utils/timeFormatting';
import type { RouteTemplate } from 'common/utils/urlPredicates';
import { matchesRoute } from 'common/utils/urlPredicates';
import type { SelectableItem } from 'ott/components/OTTSelectableList/OTTSelectableList';
import type { Section } from 'ott/components/SettingsMainBody/SettingsMainBody';
import type { ContainerDetailProps } from 'ott/containers/ContainerDetail/ContainerDetail';
import type { ContainerProps } from 'ott/containers/Containers/Containers';

const messages = defineMessages({
  button: {
    description: 'accessibility voice message indicating this is a button',
    defaultMessage: 'button',
  },
  buttonSubgroupHelp: {
    description: 'accessibility voice message announcing navigation within a button subgroup',
    defaultMessage: 'Press left and right arrows to change to other options',
  },
  movie: {
    description: 'accessibility voice message indicating the type of content is movie',
    defaultMessage: 'movie',
  },
  series: {
    description: 'accessibility voice message indicating the type of content is series',
    defaultMessage: 'series',
  },
  season: {
    description: 'accessibility voice message indicating the season of series',
    defaultMessage: 'season',
  },
  genre: {
    description: 'accessibility voice message indicating what the current content\'s genre is',
    defaultMessage: 'genre',
  },
  rated: {
    description: 'accessibility voice message indicating what the current content\'s rating is',
    defaultMessage: 'rated',
  },
  dot: {
    description: 'accessibility voice message indicating the part of activation web page url',
    defaultMessage: 'dot',
  },
  slash: {
    description: 'accessibility voice message indicating the part of activation web page url',
    defaultMessage: 'slash',
  },
  listItems: {
    description: 'accessibility voice message indicating button list position, one example of the full sentence is: '
      + '2 out of more than 5 list items',
    defaultMessage: 'list items',
  },
  outOf: {
    description: 'accessibility voice message indicating button list position, one example of the full sentence is: '
      + '2 out of more than 5 list items',
    defaultMessage: 'out of',
  },
  moreThan: {
    description: 'accessibility voice message indicating button list position, one example of the full sentence is: '
      + '2 out of more than 5 list items',
    defaultMessage: 'more than',
  },
  beginningOfList: {
    description: 'accessibility voice message indicating the user has reached the beginning of the list.',
    defaultMessage: 'You are at the beginning of this list',
  },
  endOfList: {
    description: 'accessibility voice message indicating the user has reached the beginning of the list.',
    defaultMessage: 'You\'ve reached the end of this list',
  },
  hasClosedCaptions: {
    description: 'accessibility voice message indicating content has closed captions',
    defaultMessage: 'has closed captions',
  },
  category: {
    description: 'accessibility voice message indicating the type of the current category',
    defaultMessage: 'category',
  },
  categoryFirstCharUpperCase: {
    description: 'accessibility voice message indicating the type of the current category, '
      + 'the first character is capitalized because it\'s at the beginning',
    defaultMessage: 'Category',
  },
  channel: {
    description: 'accessibility voice message indicating the type of the current category',
    defaultMessage: 'network',
  },
  channelFirstCharUpperCase: {
    description: 'accessibility voice message indicating the type of the current category, '
      + 'the first character is capitalized because it\'s at the beginning',
    defaultMessage: 'Network',
  },
  goToChannelDetailPage: {
    description: 'accessibility voice message indicating that '
      + 'the user can select the current item to go to network detail page',
    defaultMessage: 'select to go to network detail page',
  },
  titles: {
    description: 'accessibility voice message indicating button list position, one example of the full sentence is: '
      + '2 out of more than 5 titles',
    defaultMessage: 'titles',
  },
  noActivationCode: {
    description: 'accessibility voice message indicating that no activation code available',
    defaultMessage: 'No activation code available',
  },
  searchResultsForKeyword: {
    description: 'accessibility voice message indicating that search results will be read',
    defaultMessage: 'search results for keyword',
  },
  trendingSearches: {
    description: 'accessibility voice message indicating that trending searches will be read',
    defaultMessage: 'trending searches',
  },
  ottHomeNavPlaybookOnceBefore: {
    description: 'accessibility voice message indicating what the current page is',
    defaultMessage: 'Category Nav Menu',
  },
  ottHomeNavPlaybookOnceAfter: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'use up and down to move between categories',
  },
  ottHomeGridPlaybookOnceAfter: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'Use left and right to move between titles.',
  },
  playbackPlaybookOnceAfter: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'You can press select to control play/pause, or use left and right to move between control buttons',
  },
  seriesEpisodesPlaybookOnceAfter: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'Use up and down to move between seasons, press right for next episode, press back to go to seasons list',
  },
  seriesSeasonsPlaybookOnceAfter: {
    description: 'accessibility voice message indicating what the current page is',
    defaultMessage: 'Use up and down to move between seasons, press right for episodes, press back to return series page',
  },
  activationPlaybookOnceBeforePart1: {
    description: 'activation page accessibility voice message, the full sentence is: '
      + 'Activation Page. Register or Sign In using a Web Browser. No Credit Card Required. Free Forever. '
      + 'Visit tubi.tv/activate from a browser on another device and enter the code below',
    defaultMessage: 'Activation Page. Register or Sign In using a Web Browser. No Credit Card Required. Free Forever. Visit',
  },
  activationPlaybookOnceBeforePart2: {
    description: 'activation page accessibility voice message, the full sentence is: '
      + 'Activation Page. Register or Sign In using a Web Browser. No Credit Card Required. Free Forever. '
      + 'Visit tubi.tv/activate from a browser on another device and enter the code below',
    defaultMessage: 'from a browser on another device and enter the code below',
  },
  activationPlaybookTextPart1: {
    description: 'accessibility voice message indicating what the activation code is',
    defaultMessage: 'The activation code is',
  },
  activationPlaybookTextPart2: {
    description: 'accessibility voice message indicating what the activation code is',
    defaultMessage: 'Again, the activation code is',
  },
  activationPlaybookOnceAfterPart1: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'This screen will automatically refresh once your device is linked.',
  },
  activationPlaybookOnceAfterPart2: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'Press Back to leave the activation page.',
  },
  searchGridPlaybookOnceBeforePart1: {
    description: 'accessibility voice message, the full sentence is: '
      + 'Movie tiles grid is now active. Navigating in trending searches.',
    defaultMessage: 'Movie tiles grid is now active. Navigating in',
  },
  searchGridPlaybookOnceBeforePart2: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'Use directional keys to navigate grid',
  },
  categoryGridPlaybookOnceBefore: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'tiles grid is now active. Use directional keys to navigate grid.',
  },
  categoryDetailsPlaybookOnceBefore: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'tiles grid is now active. Use directional keys to navigate grid.',
  },
  listPlaybookText: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'Submenu available. Press select or right to move into submenu.',
  },
  listPlaybookOnceBefore: {
    description: 'accessibility voice message indicating how the user can interact with the current page',
    defaultMessage: 'Press up and down to navigate between options.',
  },
  directedBy: {
    description: 'accessibility voice message indicating the director list',
    defaultMessage: 'Directed by',
  },
  starring: {
    description: 'accessibility voice message indicating the actor list',
    defaultMessage: 'Starring',
  },
  ageGateOnceBefore: {
    description: 'accessibility voice message indicating what the current page is',
    defaultMessage: 'Confirm Your Age Page. To continue, please verify your year of birth.'
      + '2D Keyboard is now active. Use the arrow keys to navigate and enter to select.',
  },
  ageGateStartWatchingButton: {
    description: 'accessibility voice message indicating the start watching button',
    defaultMessage: 'Start Watching button',
  },
  ageGateDeleteButton: {
    description: 'accessibility voice message indicating the delete button',
    defaultMessage: 'Delete button',
  },
  ageGateCurrentYearOfBirth: {
    description: 'accessibility voice message indicating the current input',
    defaultMessage: 'You were born in {yearOfBirth}.',
  },
  ageGateCurrentAge: {
    description: 'accessibility voice message indicating the current input',
    defaultMessage: 'You were {age} years old.',
  },
  ageGateOnStartWatching: {
    description: 'accessibility voice message indicating how to interact ',
    defaultMessage: 'Press Enter to continue.',
  },
  ageGateContinue: {
    description: 'accessibility voice message indicating how to interact',
    defaultMessage: 'Continue enter or Navigate down to the start watching button.',
  },
  pauseAdPlaybookPreAltTextMessage: {
    description: 'accessibility voice message announcing pause ads read out prior to alt tag or in place of alt tag when it does not exist',
    defaultMessage: 'Advertisement on pause screen',
  },
  pauseAdPlaybookOnceAfter: {
    description: 'accessibility voice message indicating how the user can interact with pause ads',
    defaultMessage: 'Press the Back button to close the ad',
  },
  pauseAdPlaybookClose: {
    description: 'accessibility voice message which plays when user closes pause ad',
    defaultMessage: 'Press play to continue watching',
  },
});

export const safeFormat = (message: MessageDescriptor, formatMessage?: IntlFormatters['formatMessage']): string => {
  return (formatMessage ? formatMessage(message) : message.defaultMessage as string) || '';
};

/**
 * utility function, Use when the playbook conforms to the a11y store and can just be fetch from there
 * @param {Object} state
 */
export const getFromA11yStore = ({ a11y: { text, onceBefore, onceAfter, cancelable } }: StoreState):
  A11yState => ({ text, onceBefore, onceAfter, cancelable });

export const contentRatingsToString = (
  ratings: VideoRating[],
  formatMessage?: IntlFormatters['formatMessage']
): string => {
  const rating = (ratings[0] || {}).value;
  const textRated = safeFormat(messages.rated, formatMessage);
  return rating ? `${textRated} ${rating}` : '';
};

export const contentTypeToString = (
  type: VideoType | ContainerType,
  formatMessage?: IntlFormatters['formatMessage']
): string => {
  switch (type) {
    case VIDEO_CONTENT_TYPE:
      return safeFormat(messages.movie, formatMessage);
    case SERIES_CONTENT_TYPE:
      return safeFormat(messages.series, formatMessage);
    default: return '';
  }
};

export const contentCastsToString = (
  castType: string,
  castList?: string[],
  maxLength?: number,
): string => {
  if (!castList) return '';
  const list = maxLength ? castList.slice(0, maxLength) : castList;
  return `${castType} ${list.join(', ')}`;
};

export const contentGenreToString = (
  tags: string[],
  formatMessage?: IntlFormatters['formatMessage']
): string => {
  const genreText = safeFormat(messages.genre, formatMessage);
  return tags.length ? `${genreText} ${tags.join(' ')}` : '';
};

export const getSponsorshipText = (
  sponsorship: Sponsorship | null | undefined,
  { prefix = '', suffix = '' }: { prefix?: string, suffix?: string } = {},
  formatMessage?: IntlFormatters['formatMessage']
): string => {
  if (!sponsorship) return '';
  const broughtToYouBy = safeFormat(sponsorshipMessages.broughtToYouBy, formatMessage);
  return `${prefix}${broughtToYouBy} ${sponsorship.brand_name}${suffix}`;
};

/**
 * Another utility to help with creating a string for items in a list
 * @param {Number} idx 0 based index that the user is on in the list
 * @param {Number|String} count total known count of the items in the list
 * @param {Object} [options]
 * @param {Boolean} options.allLoaded do not known full count yet as only a portion is loaded
 * @param {String} options.itemNaming what do you want to call the items (e.g. movies, titles, shoes)
 */

interface ListOptions {
  allLoaded?: boolean;
  itemNaming?: string;
  hasNotification?: boolean;
  beginningAndEndOnly?: boolean;
}
export const positionInList = (
  idx: number,
  count?: number,
  options: ListOptions = {},
  formatMessage?: IntlFormatters['formatMessage']
) => {
  const textListItems = safeFormat(messages.listItems, formatMessage);
  const textOutOf = safeFormat(messages.outOf, formatMessage);
  const textMoreThan = safeFormat(messages.moreThan, formatMessage);

  const { allLoaded = true, itemNaming = textListItems, hasNotification = true, beginningAndEndOnly } = options;
  const positionText = beginningAndEndOnly
    ? ''
    : `${idx + 1} ${textOutOf}${!allLoaded ? ` ${textMoreThan}` : ''} ${count} ${itemNaming},`;
  if (hasNotification && count && allLoaded && idx === count - 1) {
    return `${[positionText, safeFormat(messages.endOfList, formatMessage)].filter(Boolean).join(' ')},`;
  }
  if (hasNotification && idx === 0) {
    return `${[positionText, safeFormat(messages.beginningOfList, formatMessage)].filter(Boolean).join(' ')},`;
  }
  return positionText;
};

interface VideoDetailOptions {
  includeSubtitles?: boolean;
  omitDescription?: boolean;
  omitGenre?: boolean;
  omitYear?: boolean;
  showCasts?: boolean;
}
export const getVideoDetailText = (
  video: Video,
  options: VideoDetailOptions = {},
  formatMessage?: IntlFormatters['formatMessage']
): string => {
  const { title, duration, has_subtitle: hasSubtitle, description, ratings, type, tags, year, actors, directors } = video;
  const { includeSubtitles = __OTTPLATFORM__ === 'TIZEN', omitDescription, omitGenre, omitYear, showCasts } = options;
  const isSeries = type === SERIES_CONTENT_TYPE;
  const textHasClosedCaptions = safeFormat(messages.hasClosedCaptions, formatMessage);

  const detailText = [
    title,
    !omitYear && year ? `year ${year}` : '',
    isSeries ? '' : secondsToHoursAndMinutes(duration, formatMessage),
    includeSubtitles && hasSubtitle ? textHasClosedCaptions : '',
    contentRatingsToString(ratings || [], formatMessage),
    omitGenre ? '' : contentGenreToString(tags || [], formatMessage),
    omitDescription ? '' : description,
    // there is a width limit for the UI text so we only list 4 names to match the UI text
    showCasts ? contentCastsToString(safeFormat(messages.directedBy, formatMessage), directors, 4) : '',
    showCasts ? contentCastsToString(safeFormat(messages.starring, formatMessage), actors, 4) : '',
  ].filter(Boolean).join(', ');
  return `${trimEnd(detailText, ', ')}${showCasts ? '.' : ''}`;
};

/**
 * Util for creating the readable text for any tile on OTT.
 * @param activeContent - byId[contentId] + idx of item in containing list/row
 * @param contentCount - total # of items that the content is a part of
 * @param positionOptions - options to pass to positionInList
 * @returns {string} - resulting 'text' object for a11y store
 */
type ActiveContent = ContentItem;
export const getActiveTitleText = (
  activeContent?: ActiveContent & { idx: number },
  contentCount?: number,
  formatMessage?: IntlFormatters['formatMessage'],
  listOptions: ListOptions = {}
) => {
  if (!activeContent) {
    return '';
  }

  const { type, idx } = activeContent;
  const isLinearContent = isLinear(activeContent);
  const videoDetailOptions: VideoDetailOptions = {
    omitGenre: isLinearContent,
    omitYear: isLinearContent,
    omitDescription: isLinearContent,
    includeSubtitles: isLinearContent || __OTTPLATFORM__ === 'TIZEN',
    showCasts: false,
  };
  const sponsorshipText = getSponsorshipText((activeContent as Container).sponsorship, { prefix: ', ' }, formatMessage);
  const titleText = [
    contentTypeToString(type, formatMessage),
    getVideoDetailText({
      ...activeContent,
      title: `${activeContent.title}${sponsorshipText}`,
    } as Video, videoDetailOptions, formatMessage),
    positionInList(idx, contentCount, listOptions, formatMessage),
  ].filter(Boolean).join(', ');
  return `${trimEnd(titleText, ', ')}.`;
};

export const ottHomeNavPlaybook = (
  state: StoreState,
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const { container, ottUI } = state;
  const { activeContainerId } = ottUI.debouncedGridUI;
  const { title: containerTitle, description: containerDesc, sponsorship } = container.containerIdMap[activeContainerId];

  const textContainer = safeFormat(messages.category, formatMessage);
  const sponsorshipText = getSponsorshipText(sponsorship, { suffix: ', ' }, formatMessage);
  const textOttHomeNavPlaybookOnceBefore = `${safeFormat(messages.ottHomeNavPlaybookOnceBefore, formatMessage)}, `;
  const textOttHomeNavPlaybookOnceAfter = safeFormat(messages.ottHomeNavPlaybookOnceAfter, formatMessage);

  return {
    text: `${containerTitle} ${textContainer}, ${sponsorshipText}${containerDesc}`,
    onceBefore: textOttHomeNavPlaybookOnceBefore,
    onceAfter: textOttHomeNavPlaybookOnceAfter,
  };
};

export const ottHomeGridPlaybook = (
  location: Location,
  state: StoreState,
  props: WrappedComponentProps,
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const {
    video: { byId },
    ottUI: { debouncedGridUI },
  } = state;
  const { containerChildrenIdMap, containerIdMap, containerLoadIdMap } = containerSelector(state, { pathname: location.pathname });

  // getting active container
  const { gridIndex, activeContainerId } = debouncedGridUI;
  const activeCat = containerIdMap[activeContainerId];

  if (!activeCat || !containerChildrenIdMap[activeContainerId]) {
    return { text: '', onceBefore: '', onceAfter: '' };
  }

  const { title: containerTitle, type: containerType, sponsorship } = activeCat;
  // getting activeTitle
  const activeContentId = containerChildrenIdMap[activeContainerId][gridIndex];
  const isChannel = containerType === CONTAINER_TYPES.CHANNEL;
  const sponsorshipText = getSponsorshipText(sponsorship, { prefix: ', ' }, formatMessage);
  let text = '';
  if (isChannel && gridIndex === 0) { // the first tile in channels is not a title
    text = safeFormat(messages.goToChannelDetailPage, formatMessage);
  } else {
    const activeContent = { ...byId[activeContentId], idx: gridIndex };
    // are all content in container loaded
    const containerLoadState = (containerLoadIdMap || {})[activeContainerId];
    const allLoaded = !containerLoadState?.cursor;
    // current number of loaded titles in container
    const containerContentCount = containerChildrenIdMap[activeContainerId].length;
    text = getActiveTitleText(
      activeContent,
      containerContentCount,
      props.intl.formatMessage,
      // Only indicate beginning or end of container when describing position
      { allLoaded, beginningAndEndOnly: true, itemNaming: safeFormat(messages.titles, formatMessage) }
    );
  }

  return {
    text,
    onceBefore: `${containerTitle} ${isChannel
      ? safeFormat(messages.channel, formatMessage)
      : safeFormat(messages.category, formatMessage)}${sponsorshipText}.`,
    onceAfter: `${safeFormat(messages.ottHomeGridPlaybookOnceAfter, formatMessage)} ${safeFormat(commonVoiceMessages.pressBackForMenu, formatMessage)}`,
  };
};

export const ottHomeMainPlaybook = (
  location: Location,
  state: StoreState,
  props: WrappedComponentProps,
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const { section } = state.ottUI.debouncedGridUI;
  // user is on the container nav
  if (section === NAV_LIST_SECTION) return ottHomeNavPlaybook(state, formatMessage);
  return ottHomeGridPlaybook(location, state, props, formatMessage);
};

export enum DetailPageButtonValue {
  Liked = 1,
  Disliked = 2,
  LikeOrDislike = 3,
  YMAL = 4,
}
interface VideoDetailListButtonBase {
  value?: DetailPageButtonValue;
  action?: VoidFunction | (() => Action);
  icon?: ReactNode;
  disabled?: boolean;
  percentComplete?: number;
  description?: string;
  isSubgroupActive?: boolean;
  subgroup?: {
    hoverIndex?: number;
    buttons?: VideoDetailListButton[];
  }
  onMouseEnter?: (e: React.MouseEvent) => void;
  onClick?: () => void;
  onSubgroupNavigation?: (newIndex: number) => void;
  /* Called when button becomes active in the detail page. Not currently
  implemented for subgroup buttons. */
  onNavigatedTo?: () => void;
  onNavigatedFrom?: () => void;
  navOptionForTracking?: ContentDetailPageNavOption,
}

interface VideoDetailListButtonNormal extends VideoDetailListButtonBase {
  text: string;
  a11yText?: string;
}
interface VideoDetailListButtonWithA11yText extends VideoDetailListButtonBase {
  text?: ReactNode;
  a11yText: string;
}

// if `text` is `ReactNode`, then we must have a `a11yText` for a11y
export type VideoDetailListButton = VideoDetailListButtonNormal | VideoDetailListButtonWithA11yText;

const getVideoDetailButtonA11yDescription = (
  button: VideoDetailListButton,
  formatMessage?: IntlFormatters['formatMessage'],
): string => [
  `${button.a11yText || button.text} ${safeFormat(messages.button, formatMessage)}`,
  button.description,
].filter(Boolean).join(', ');

export const videoDetailPlaybook = (
  buttons: VideoDetailListButton[],
  activeIdx: number,
  video: Video,
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const activeButton = buttons[activeIdx];
  let text = '';
  let isSubgroup = false;
  if (activeButton) {
    if (activeButton.subgroup && activeButton.isSubgroupActive) {
      const { hoverIndex, buttons } = activeButton.subgroup;
      if (buttons && hoverIndex !== undefined) {
        const subgroupActiveButton = buttons[hoverIndex];
        text = getVideoDetailButtonA11yDescription(subgroupActiveButton);
        isSubgroup = true;
      }
    } else {
      text = getVideoDetailButtonA11yDescription(activeButton);
    }
  }
  const onceBefore = getVideoDetailText(video, { showCasts: true }, formatMessage);
  const onceAfter = isSubgroup ? safeFormat(messages.buttonSubgroupHelp, formatMessage) : '';

  return { text, onceBefore, onceAfter };
};

export const playbackPlaybook = (
  video: Video | ChannelEPGInfo,
  text: string,
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const onceBefore = `${video.title}. ${contentRatingsToString(video.ratings || [], formatMessage)}.`;
  const onceAfter = safeFormat(messages.playbackPlaybookOnceAfter, formatMessage);
  return { text, onceBefore, onceAfter };
};

export const pauseAdPlaybook = (
  altText: string,
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const textBase = safeFormat(messages.pauseAdPlaybookPreAltTextMessage, formatMessage);
  const text = altText ? `${textBase}. ${altText}.` : textBase;
  return {
    text,
    onceBefore: '',
    onceAfter: safeFormat(messages.pauseAdPlaybookOnceAfter, formatMessage),
  };
};

export const pauseAdClosePlaybook = (formatMessage?: IntlFormatters['formatMessage']): A11yState => {
  return {
    text: safeFormat(messages.pauseAdPlaybookClose, formatMessage),
    onceBefore: '',
    onceAfter: '',
  };
};

export const seriesEpisodesPlaybook = (
  episodeTitle: string,
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const onceBefore = '';
  const onceAfter = safeFormat(messages.seriesEpisodesPlaybookOnceAfter, formatMessage);

  return { text: episodeTitle, onceBefore, onceAfter };
};

export const activationPlaybook = (
  activationCode = '',
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  if (!activationCode || activationCode.length !== 6) {
    return { onceBefore: '', text: safeFormat(messages.noActivationCode, formatMessage), onceAfter: '' };
  }
  const spacedActivationCode = activationCode.split('').join(', ');
  const textDot = safeFormat(messages.dot, formatMessage);
  const textSlash = safeFormat(messages.slash, formatMessage);
  const spacedUrl = `t, u, b, i, ${textDot}, t, v, ${textSlash}, activate`;

  const onceBefore = `${safeFormat(messages.activationPlaybookOnceBeforePart1, formatMessage)} `
    + `${spacedUrl} ${safeFormat(messages.activationPlaybookOnceBeforePart2, formatMessage)}. `;

  const text = `${safeFormat(messages.activationPlaybookTextPart1, formatMessage)} ${spacedActivationCode}. `
    + `${safeFormat(messages.activationPlaybookTextPart2, formatMessage)}...${spacedActivationCode}. `;

  const onceAfter = `${safeFormat(messages.activationPlaybookOnceAfterPart1, formatMessage)} `
  + `${safeFormat(messages.activationPlaybookOnceAfterPart2, formatMessage)}`;

  return { onceBefore, text, onceAfter };
};

export interface SearchProps {
  keyword: string;
  searchResult: string[];
  recommendations: string[];
  byId: { [id: string]: Video };
  selectedTileIdx: number;
}

export const searchGridPlaybook = (
  searchProps: SearchProps & WrappedComponentProps,
  contentId: string
): A11yState => {
  const { keyword, searchResult, recommendations, byId, selectedTileIdx, intl: { formatMessage } } = searchProps;
  const activeContent = { ...byId[contentId], idx: selectedTileIdx };

  const resultLength = searchResult.length;
  const haveRecommendations = !keyword && !!recommendations.length;
  let tilesHeading = '';

  if (resultLength) {
    tilesHeading = `${safeFormat(messages.searchResultsForKeyword, formatMessage)} ${keyword}...`;
  } else if (haveRecommendations) {
    tilesHeading = safeFormat(messages.trendingSearches, formatMessage);
  }

  const tileListLength = resultLength || recommendations.length;

  const onceBefore = tilesHeading
    ? (`${safeFormat(messages.searchGridPlaybookOnceBeforePart1, formatMessage)} ${tilesHeading}.`
      + ` ${safeFormat(messages.searchGridPlaybookOnceBeforePart2, formatMessage)}.`)
    : '';
  const text = getActiveTitleText(activeContent, tileListLength, formatMessage);
  return { text, onceBefore, onceAfter: '' };
};

export const containerGridPlaybook = (containerProps: ContainerProps): A11yState => {
  const { activeContainerGridIndex, containerIdMap, containerIds, intl: { formatMessage } } = containerProps;
  const contId = containerIds[activeContainerGridIndex];
  const activeContent = { ...containerIdMap[contId], idx: activeContainerGridIndex };

  const containersLength = containerIds.length;
  const typeStr = activeContent.type === 'channel'
    ? safeFormat(messages.channelFirstCharUpperCase, formatMessage)
    : safeFormat(messages.categoryFirstCharUpperCase, formatMessage);

  const onceBefore = `${typeStr} ${safeFormat(messages.categoryGridPlaybookOnceBefore, formatMessage)} ${safeFormat(commonVoiceMessages.pressBackForMenu, formatMessage)}`;
  const text = getActiveTitleText(activeContent, containersLength, formatMessage);
  return { text, onceBefore, onceAfter: '' };
};

export const containerDetailsPlaybook = (props: ContainerDetailProps & WrappedComponentProps): A11yState => {
  const { activeContainerVideoGridId, containerTitle, containerType, videoById, videoIds, sponsorship, intl: { formatMessage } } = props;
  const activeContent = {
    ...videoById[(activeContainerVideoGridId || videoIds[0])], // use first video id if activeContainerVideoGridId is blank
    idx: Math.max(videoIds.indexOf(activeContainerVideoGridId), 0),
  };

  const typeStr = containerType === 'channel'
    ? safeFormat(messages.channel, formatMessage)
    : safeFormat(messages.category, formatMessage);
  const sponsorshipText = getSponsorshipText(sponsorship, { prefix: ', ', suffix: ', ' }, formatMessage);

  const onceBefore = `${containerTitle} ${typeStr}${sponsorshipText} ${safeFormat(messages.categoryDetailsPlaybookOnceBefore, formatMessage)}`;
  const text = getActiveTitleText(activeContent, videoIds.length, formatMessage);
  return { text, onceBefore, onceAfter: '' };
};

/**
 *
 * @param list {Array} - array of items used to build OTTSelectableList
 * @param activeIdx {Number} - The current active item idx
 * @param preface {String} - any special preface to go in onceBefore
 * @returns {{onceBefore: string, onceAfter: string, text: string}}
 */

export interface Selection extends SelectableItem {
  description?: string;
  action?: VoidFunction;
  subOptions?: [];
  hasSubOptions?: boolean;
  pageType?: AccountPageType;
  staticPanel?: boolean;
  showSectionOnly?: boolean;
  hasAnimation?: boolean;
  hasTextToRead?: boolean;
  extraA11yText?: string;
  sections?: Section[];
}

export const listPlaybook = (
  list: Selection[],
  activeIdx: number,
  preface = '',
  formatMessage?: IntlFormatters['formatMessage']
): A11yState => {
  const activeSetting = list[activeIdx];
  const { subOptions, hasSubOptions, title, description } = activeSetting;
  const text = `${title}. `
    + `${subOptions || hasSubOptions
      ? safeFormat(messages.listPlaybookText, formatMessage) : ''}${description || ''}`;
  const onceBefore = `${preface ? `${preface} ` : ''}${safeFormat(messages.listPlaybookOnceBefore, formatMessage)} `;
  return { text, onceBefore, onceAfter: '' };
};

export const ageGatePlaybook = ({
  numPadKey,
  isSubmitButtonActive,
  isDeleteButtonActive,
  isYearOfBirthVariant,
  yearOfBirth,
  age,
  warningMessage,
  footerMessage,
  formatMessage,
}: {
  numPadKey: number;
  isSubmitButtonActive: boolean;
  isDeleteButtonActive: boolean;
  isYearOfBirthVariant: boolean;
  yearOfBirth: string;
  age: string | undefined;
  warningMessage: string;
  footerMessage: string;
  formatMessage: IntlFormatters['formatMessage'];
}) => {
  let activeKey = `${numPadKey}`;
  if (isSubmitButtonActive) {
    activeKey = formatMessage(messages.ageGateStartWatchingButton);
  } else if (isDeleteButtonActive) {
    activeKey = formatMessage(messages.ageGateDeleteButton);
  }

  let currentInput = '';
  if (isYearOfBirthVariant && yearOfBirth) {
    currentInput = formatMessage(messages.ageGateCurrentYearOfBirth, { yearOfBirth });
  } else if (age) {
    currentInput = formatMessage(messages.ageGateCurrentAge, { age });
  }

  let hint = '';
  if (warningMessage) {
    hint = warningMessage;
  } else if (isSubmitButtonActive) {
    hint = formatMessage(messages.ageGateOnStartWatching);
  } else if (currentInput) {
    hint = formatMessage(messages.ageGateContinue);
  }

  const text = `${activeKey}. ${currentInput} ${hint}`;
  return {
    onceBefore: formatMessage(messages.ageGateOnceBefore),
    text,
    onceAfter: footerMessage,
  };
};

/**
 * We are abstracting the process of getting the right text prop into different playbooks based on the route
 * essentially having a different MSTP function for each path in our app
 * @param {String} pathname
 * @return {Function} an MSTP function for the given pathname
 */
export const loadPlaybook = (location: Location): (
  state: StoreState,
  props: WrappedComponentProps,
  formatMessage?: IntlFormatters['formatMessage']
  ) => A11yState => {
  if ([
    OTT_ROUTES.home,
    OTT_ROUTES.movieMode,
    OTT_ROUTES.tvMode,
    OTT_ROUTES.espanolMode,
    OTT_ROUTES.myStuff,
  ].some(route => matchesRoute(route, location.pathname))) {
    return ottHomeMainPlaybook.bind(null, location);
  }

  const routesUsingStore: RouteTemplate[] = [
    OTT_ROUTES.liveMode,
    OTT_ROUTES.video,
    OTT_ROUTES.series,
    OTT_ROUTES.episodeList,
    OTT_ROUTES.player,
    OTT_ROUTES.activate,
    OTT_ROUTES.containers,
    OTT_ROUTES.containerDetail,
    OTT_ROUTES.parentalPassWord,
    OTT_ROUTES.search,
    OTT_ROUTES.settings,
    OTT_ROUTES.livePlayer,
    OTT_ROUTES.onboarding,
    OTT_ROUTES.landing,
    OTT_ROUTES.personalizationTitle,
    OTT_ROUTES.ageGate,
    OTT_ROUTES.enterEmailWithEscape,
    OTT_ROUTES.consentContinueWatching,
  ];
  if (routesUsingStore.some((route) => matchesRoute(route, location.pathname))) {
    return (state) => getFromA11yStore(state);
  }

  return () => ({ text: '', onceBefore: '', onceAfter: '' });
};
