import { setUserLanguageLocale } from 'common/actions/ui';
import type { TubiStore } from 'common/types/storeState';
import { loadScript } from 'common/utils/dom';
import type { LanguageLocaleType } from 'i18n/constants';
import { SUPPORTED_LANGUAGE_LOCALE } from 'i18n/constants';
import { determineLocaleFromBrowser } from 'i18n/utils';

declare global {
  interface Window {
    i18nPolyfillsByLocale?: Record<LanguageLocaleType, string>;
    i18nMessageScripts?: Record<LanguageLocaleType, string>;
  }
}

export const setupFailsafeI18n = async (store: TubiStore) => {
  const locale: LanguageLocaleType = determineLocaleFromBrowser();
  store.dispatch(setUserLanguageLocale(locale));
  const promises: Promise<void>[] = [];
  if (__ISOTT__) {
    promises.push(loadScript(window.i18nPolyfillsByLocale![locale]));
  }
  if (locale !== SUPPORTED_LANGUAGE_LOCALE.EN_US) {
    promises.push(loadScript(window.i18nMessageScripts![locale]));
  }

  await Promise.all(promises);
};
