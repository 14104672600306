import type { Device } from '@tubitv/analytics/lib/client';

import systemApi from 'client/systemApi';
import { setupDsBridgeForDevEnv, isBridgeAvailable, getBridge } from 'client/utils/clientTools';
import { setAnalyticsConfig } from 'common/actions/tracking';
import { SET_OTT_DEVICE_INFO } from 'common/constants/action-types';
import logger from 'common/helpers/logging';
import { actionWrapper } from 'common/utils/action';
import { getDebugLog } from 'common/utils/debug';

import type { RawComponent } from './App';

const debug = getDebugLog('ottApp');

export async function hybCDM(this: InstanceType<typeof RawComponent>, deviceInfoCallback?: (...args: any[]) => void) {
  let bridge;
  let deviceInfo;

  // @note if you want to test dsbridge for firetv locally, either hardcode isDsBridgeAvailable or set a cookie `o_cnav=2.13.10_151`
  if (isBridgeAvailable()) {
    if (__DEVELOPMENT__) {
      setupDsBridgeForDevEnv({
        getDeviceInfo: /* istanbul ignore next */ () => ({
          result: {
            device_type: 'FireTV',
            device_name: 'FireTV Dev Device',
            app_launch_ts: Date.now(),
            app_resume_ts: Date.now(),
            webview_latest_start_ts: Date.now(),
          },
        }),
      });
    }
    bridge = getBridge({ debug: !__PRODUCTION__ || __IS_ALPHA_ENV__ });

    try {
      bridge.registerHandler('onBackPress', this.onHybBackPress);
      bridge.init();
      deviceInfo = systemApi.getDeviceInfo() || await bridge.callHandler('getDeviceInfo') || {};
      if (__IS_ANDROIDTV_HYB_PLATFORM__) {
        const {
          model: deviceModel,
          manufacturer: deviceManufacturer,
        } = deviceInfo as Device;

        const { dispatch } = this.props;
        dispatch(actionWrapper(SET_OTT_DEVICE_INFO, {
          deviceModel,
          deviceManufacturer,
        }));
        // Update device model and manufacturer in analytics config
        dispatch(setAnalyticsConfig());
      }

      debug('getDeviceInfo', deviceInfo);
    } catch (err) {
      logger.error(err, 'dsbridge: error when call getDeviceInfo');
      return;
    }
  }

  if (typeof deviceInfoCallback === 'function') {
    deviceInfoCallback(deviceInfo);
  }

  return bridge;
}
