import type { IResult } from 'ua-parser-js';

import { LOG_SUB_TYPE, TRACK_LOGGING } from 'common/constants/error-types';
import { trackLogging } from 'common/utils/track';
import type { PlayableMediaTypes } from 'ott/utils/getPlayableMediaTypes';

export function trackPlaybackCapabilities({
  playableMediaTypes,
  ua,
}: {
  playableMediaTypes: PlayableMediaTypes;
  ua: IResult;
}): void {
  trackLogging({
    level: 'info',
    type: TRACK_LOGGING.clientInfo,
    subtype: LOG_SUB_TYPE.PLAYBACK_CAPABILITIES,
    message: {
      playableMediaTypes,
      userAgent: `${ua.browser.name} ${ua.browser.major}`,
    },
  });
}
