import { AppMode } from '@tubitv/analytics/lib/client';
import classNames from 'classnames';
import React, { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { getAppMode } from 'common/utils/appMode';

import styles from './SvgLibrary.scss';
import SvgIcon from '../SvgIcon/SvgIcon';

interface Props {
  className?: string;
  color?: 'white' | 'black' | 'primary';
  isKidsModeEnabled?: boolean;
  isEspanolModeEnabled?: boolean;
  appMode?: AppMode;
}

const messages = defineMessages({
  logo: {
    description: 'accessibility label for the Tubi logo',
    defaultMessage: 'Tubi logo',
  },
});

// todo(liam) update fill styling and comment when web redesign is ready
// This is the logo that with the 'tubitv' name only
const Tubi = ({
  color = 'white',
  className,
  isKidsModeEnabled,
  isEspanolModeEnabled,
  appMode = getAppMode({ isKidsModeEnabled, isEspanolModeEnabled }),
  ...restOfProps
}: Props) => {
  const { formatMessage } = useIntl();
  const cls = classNames(styles.tubi, styles[color], className, styles.newTubiLogo);
  const title = <title>{formatMessage(messages.logo)}</title>;

  switch (appMode) {
    case AppMode.DEFAULT_MODE:
      return (
        <SvgIcon className={cls} viewBox="0 0 139 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          {title}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96.7005 38.8629V34.5064C99.0123 37.6771 103.11 39.817 107.9 39.817C116.442 39.817 121.102 34.2794 121.102 23.2203C121.102 13.2958 115.932 6.66991 108.163 6.66991C103.23 6.66991 99.179 8.68038 96.7005 11.9294V-0.000732422H81.2107V38.8629H96.7005ZM105.795 23.7189C105.795 28.5117 103.841 31.1055 101 31.1055C98.1851 31.1055 96.1522 28.4846 96.1522 23.7189C96.1522 18.9532 98.1338 16.3035 101 16.3035C103.867 16.3035 105.795 18.9261 105.795 23.7189ZM40.192 8.25869V29.1096C40.192 36.2661 44.5542 39.7724 51.512 39.7724C57.0638 39.7724 60.9242 37.4759 63.26 33.6068V38.8775H78.2962V8.25869H62.8063V26.4503C62.8063 29.303 60.804 30.4633 58.9459 30.4633C57.0878 30.4633 55.6578 29.351 55.6578 26.6677V8.25869H40.192ZM123.536 8.25869H139.001V38.8136H123.536V8.25869ZM37.9492 14.8986C37.9492 19.4247 34.2685 23.0939 29.7281 23.0939C25.1878 23.0939 21.5071 19.4247 21.5071 14.8986C21.5071 10.3725 25.1878 6.70331 29.7281 6.70331C34.2685 6.70331 37.9492 10.3725 37.9492 14.8986ZM20.2068 6.59991H20.2075V22.0538C20.2354 25.8363 22.1034 27.4057 25.8615 27.4057C27.1633 27.4057 28.9092 27.0973 29.7556 26.4452V37.629C26.91 39.0338 24.7201 39.8169 20.8612 39.8169C20.8612 39.8169 20.4641 39.8203 19.817 39.7824L19.8019 39.8189C10.4521 39.8189 5.26266 33.8227 5.42619 24.2594V21.5392H0.000976562V8.25869H5.32936V-0.000732422H20.2068V6.59991Z"
            fill="currentcolor"
          />
        </SvgIcon>
      );
    case AppMode.KIDS_MODE:
      return (
        <SvgIcon width="256" height="60" viewBox="0 0 256 60" className={cls} {...restOfProps}>
          {title}
          <path d="M6.72863 31.8845V28.4308H0.818604V15.4714H6.72863V7.39673H21.4261V29.5981C21.4261 33.4223 24.0364 34.7495 26.9112 34.7495C28.509 34.7495 30.1067 34.4297 31.651 33.8994V44.2045C28.509 45.7449 24.9955 46.4884 21.5329 46.4884C12.5343 46.4884 6.72863 41.5477 6.72863 31.882V31.8845ZM23.2909 22.0589C23.2909 17.7577 26.2727 14.6238 30.7453 14.6238C35.2178 14.6238 38.1996 17.7577 38.1996 22.0589C38.1996 26.3602 35.2178 29.4941 30.7453 29.4941C26.2727 29.4941 23.2909 26.3602 23.2909 22.0589ZM40.1179 35.0184V15.4714H54.8153V33.9019C54.8153 36.876 56.1993 37.9925 58.0642 37.9925C60.0333 37.9925 61.6845 36.876 61.6845 33.9019V15.4714H76.382V45.6408H62.1094V40.2763C59.9265 44.1005 57.1025 46.4909 51.0856 46.4909C45.0687 46.4909 40.1153 43.3037 40.1153 35.0184H40.1179ZM93.9568 40.2763V45.6408H79.2594V7.39927H93.9568V20.8384C95.9794 17.811 99.1214 14.6238 104.5 14.6238C111.743 14.6238 117.546 20.0416 117.546 30.5574C117.546 41.0731 111.743 46.4934 104.5 46.4934C99.1214 46.4934 95.9794 43.3062 93.9568 40.2789V40.2763ZM102.851 30.5574C102.851 25.7765 101.147 23.1222 98.1648 23.1222C95.1831 23.1222 93.4785 25.7791 93.4785 30.5574C93.4785 35.3356 95.1297 37.9925 98.1648 37.9925C101.2 37.9925 102.851 35.3356 102.851 30.5574ZM119.785 15.4714H134.482V45.6408H119.785V15.4714Z" fill="#FFFF13" />
          <path d="M195.36 14.7937H185.387V46.4248H195.36V14.7937Z" fill="white" />
          <path d="M221.117 18.1957C220.15 16.9523 219.041 15.9855 217.792 15.2953C216.151 14.3893 214.268 13.9351 212.141 13.9351C209.394 13.9351 206.911 14.6203 204.695 15.9906C202.479 17.3609 200.728 19.3072 199.444 21.827C198.156 24.3469 197.515 27.2219 197.515 30.4472C197.515 33.6725 198.169 36.6922 199.477 39.2324C200.784 41.775 202.601 43.7518 204.929 45.1678C207.257 46.5812 209.859 47.2892 212.739 47.2892C214.777 47.2892 216.606 46.8249 218.224 45.8961C219.7 45.0485 220.906 43.8533 221.847 42.3079V46.4265H231.088V0.00878906H221.115V18.1957H221.117ZM218.723 37.4104C217.614 38.0727 216.352 38.4051 214.932 38.4051C213.513 38.4051 212.307 38.0727 211.177 37.4104C210.048 36.7481 209.172 35.8193 208.552 34.6241C207.931 33.4314 207.62 32.1043 207.62 30.6452C207.62 29.186 207.931 27.8589 208.552 26.6662C209.172 25.4735 210.048 24.5448 211.177 23.8799C212.307 23.2176 213.558 22.8852 214.932 22.8852C216.306 22.8852 217.558 23.2278 218.687 23.9129C219.817 24.5981 220.692 25.5268 221.313 26.6992C221.934 27.8716 222.244 29.2089 222.244 30.7111C222.244 32.2134 221.934 33.4847 221.313 34.6571C220.692 35.8295 219.827 36.7455 218.72 37.4078L218.723 37.4104Z" fill="white" />
          <path d="M254.792 31.8367C254.016 30.644 253.098 29.7254 252.034 29.0859C250.971 28.4464 249.617 27.7714 247.979 27.0634C247.269 26.797 246.605 26.5102 245.984 26.2007C245.363 25.8911 244.888 25.5612 244.554 25.2059C244.221 24.8532 244.056 24.4548 244.056 24.0133C244.056 23.3941 244.333 22.8866 244.888 22.4882C245.442 22.0898 246.162 21.8918 247.048 21.8918C248.068 21.8918 249.152 22.1456 250.304 22.6531C251.457 23.1606 252.52 23.8585 253.494 24.7415V16.2533C250.836 14.7054 248.065 13.9314 245.185 13.9314C241.728 13.9314 238.924 14.8602 236.774 16.7177C234.625 18.5752 233.551 20.9834 233.551 23.9447C233.551 25.7134 233.917 27.1827 234.648 28.3551C235.378 29.5274 236.253 30.4435 237.273 31.1058C238.293 31.7681 239.578 32.4558 241.13 33.1613C241.837 33.4709 242.514 33.8033 243.158 34.156C243.799 34.5113 244.287 34.8742 244.621 35.2497C244.954 35.6253 245.119 36.0567 245.119 36.5439C245.119 37.2062 244.829 37.7493 244.254 38.168C243.677 38.5892 242.857 38.7973 241.794 38.7973C240.374 38.7973 238.868 38.4319 237.273 37.7036C235.678 36.9753 234.413 36.0364 233.485 34.8843V44.4333C234.77 45.3189 236.255 46.004 237.94 46.4887C239.624 46.9759 241.374 47.217 243.191 47.217C247.002 47.217 250.083 46.2223 252.431 44.2328C254.779 42.2433 255.955 39.6347 255.955 36.4069C255.955 34.5493 255.565 33.0243 254.792 31.8316V31.8367Z" fill="white" />
          <path d="M175.612 32.0383C178.116 30.4472 179.988 28.2015 181.23 25.3086C182.469 22.4132 183.046 18.9088 182.957 14.7979H172.852C172.941 17.7593 172.585 20.2359 171.788 22.2254C170.989 24.2149 169.738 25.707 168.033 26.7017C166.667 27.4985 165.039 27.9756 163.146 28.1329V0.00878906H153.173V46.4264H163.146V37.1744L171.257 46.4264H183.356L171.157 33.9288C172.801 33.5076 174.287 32.8782 175.612 32.0358V32.0383Z" fill="white" />
        </SvgIcon>
      );
    case AppMode.ESPANOL_MODE:
      return (
        <SvgIcon width="136" height="86" viewBox="0 0 136 86" fill="none" className={cls} {...restOfProps}>
          {title}
          <path d="M6.85422 24.7491V21.2723H0.917969V8.22298H6.85422V0.0942078H21.6143V22.4492C21.6143 26.3004 24.2349 27.6367 27.1224 27.6367C28.7274 27.6367 30.3306 27.316 31.8818 26.7805V37.1555C28.7274 38.7067 25.1968 39.4555 21.72 39.4555C12.6814 39.4555 6.85254 34.4812 6.85254 24.7491H6.85422ZM23.4861 14.8559C23.4861 10.5246 26.4811 7.36847 30.9736 7.36847C35.4661 7.36847 38.4611 10.5246 38.4611 14.8559C38.4611 19.1873 35.4661 22.3434 30.9736 22.3434C26.4811 22.3434 23.4861 19.1873 23.4861 14.8559ZM40.385 27.9053V8.22466H55.145V26.7822C55.145 29.7772 56.5351 30.9003 58.407 30.9003C60.3863 30.9003 62.0433 29.7772 62.0433 26.7822V8.22466H76.8033V38.601H62.4713V33.2002C60.2788 37.0514 57.445 39.4571 51.4013 39.4571C45.3576 39.4571 40.385 36.2489 40.385 27.9053ZM94.4526 33.2002V38.601H79.6925V0.0942078H94.4526V13.6237C96.4839 10.575 99.6401 7.36679 105.041 7.36679C112.313 7.36679 118.144 12.8212 118.144 23.4111C118.144 34.001 112.315 39.4555 105.041 39.4555C99.6401 39.4555 96.4839 36.2473 94.4526 33.1986V33.2002ZM103.384 23.4128C103.384 18.5997 101.673 15.9253 98.6781 15.9253C95.6831 15.9253 93.9724 18.5997 93.9724 23.4128C93.9724 28.2259 95.6311 30.9003 98.6781 30.9003C101.725 30.9003 103.384 28.2259 103.384 23.4128ZM120.39 8.22466H135.15V38.601H120.39V8.22298V8.22466Z" fill="currentColor" />
          <path d="M11.9847 75.8938C10.2989 74.9605 8.97566 73.672 8.01399 72.0272C7.05233 70.3834 6.57202 68.5305 6.57202 66.4684C6.57202 64.5177 7.01764 62.7689 7.90995 61.2228C8.8012 59.6768 10.034 58.464 11.6095 57.5864C13.1839 56.7088 14.9737 56.2695 16.9801 56.2695C19.2377 56.2695 21.1736 56.7992 22.79 57.8575C24.4054 58.9169 25.5836 60.401 26.3225 62.3085C27.0603 64.2172 27.3052 66.44 27.054 68.975H9.20584V64.6281H22.8321L21.2861 65.7148C21.2577 64.7679 21.0559 63.9386 20.6796 63.2282C20.3034 62.5177 19.7947 61.9743 19.1536 61.5981C18.5125 61.2218 17.7747 61.0337 16.9381 61.0337C16.1015 61.0337 15.3847 61.2365 14.7856 61.6401C14.1865 62.0447 13.7262 62.6301 13.4067 63.3953C13.0861 64.1615 12.9264 65.0748 12.9264 66.1331C12.9264 67.3597 13.1355 68.3833 13.5538 69.2052C13.9721 70.0271 14.5912 70.6472 15.4141 71.0655C16.236 71.4838 17.246 71.6929 18.4441 71.6929C20.5335 71.6929 22.5536 71.0655 24.5053 69.8116V75.4124C23.3071 76.0536 22.15 76.5276 21.0359 76.8334C19.9208 77.1403 18.792 77.2937 17.6506 77.2937C15.5612 77.2937 13.6726 76.8271 11.9868 75.8938H11.9847Z" fill="white" />
          <path d="M30.6683 76.7933C29.6089 76.4875 28.6756 76.0555 27.8674 75.4974V69.4783C28.4528 70.2035 29.2463 70.7952 30.25 71.2545C31.2526 71.7138 32.1996 71.944 33.0919 71.944C33.7603 71.944 34.2764 71.8126 34.6379 71.5467C34.9995 71.2818 35.1813 70.9403 35.1813 70.523C35.1813 70.2172 35.0773 69.945 34.8681 69.7085C34.659 69.472 34.3521 69.2419 33.9485 69.019C33.5438 68.7962 33.1192 68.5871 32.6736 68.3926C31.6983 67.947 30.8901 67.5151 30.2489 67.0968C29.6078 66.6785 29.0582 66.1004 28.5978 65.3626C28.1375 64.6248 27.9084 63.6978 27.9084 62.5827C27.9084 60.7161 28.5841 59.1974 29.9357 58.0266C31.2873 56.8558 33.0499 56.2714 35.2233 56.2714C37.0342 56.2714 38.7757 56.7591 40.4479 57.7344V63.0851C39.8351 62.528 39.1657 62.0887 38.4415 61.7682C37.7163 61.4487 37.0342 61.2879 36.3931 61.2879C35.835 61.2879 35.3831 61.4129 35.0352 61.6641C34.6863 61.9153 34.5129 62.2359 34.5129 62.6258C34.5129 62.9043 34.6169 63.1555 34.8261 63.3783C35.0352 63.6011 35.3337 63.8103 35.7247 64.0058C36.1146 64.2012 36.5329 64.382 36.9785 64.5491C38.0095 64.9947 38.8598 65.4204 39.5282 65.824C40.1967 66.2286 40.7747 66.8067 41.2624 67.5581C41.7501 68.3107 41.9939 69.2723 41.9939 70.4421C41.9939 72.4768 41.255 74.1206 39.7784 75.3745C38.3007 76.6283 36.3647 77.2557 33.9684 77.2557C32.826 77.2557 31.7256 77.1023 30.6662 76.7954L30.6683 76.7933Z" fill="white" />
          <path d="M43.3979 56.8148H49.2079V62.0393L47.4107 67.1388L49.6682 72.1962V85.5303H43.399V56.8148H43.3979ZM51.7156 76.4181C50.6846 75.8327 49.807 74.9835 49.0818 73.8684L44.2335 66.9296L48.9987 59.6987C49.6399 58.612 50.4617 57.7691 51.4644 57.17C52.4671 56.571 53.6095 56.2714 54.8917 56.2714C56.731 56.2714 58.3821 56.7244 59.8451 57.6293C61.3081 58.5353 62.4432 59.7891 63.2514 61.3909C64.0596 62.9936 64.4632 64.8255 64.4632 66.8876C64.4632 68.9497 64.0659 70.7269 63.2724 72.3003C62.4779 73.8747 61.3775 75.1012 59.9702 75.9788C58.5629 76.8564 56.9958 77.2957 55.268 77.2957C53.93 77.2957 52.7456 77.0035 51.7156 76.4181ZM55.8954 71.0265C56.5912 70.6082 57.1346 70.0301 57.5255 69.2923C57.9154 68.5545 58.1109 67.7116 58.1109 66.7636C58.1109 65.8156 57.9154 65.0158 57.5255 64.2769C57.1346 63.5391 56.5912 62.9537 55.8954 62.5217C55.1986 62.0898 54.4041 61.8733 53.5128 61.8733C52.6216 61.8733 51.868 62.0824 51.1722 62.5007C50.4754 62.919 49.9247 63.4971 49.5211 64.2349C49.1165 64.9737 48.9147 65.8166 48.9147 66.7636C48.9147 67.7105 49.1165 68.5125 49.5211 69.2503C49.9247 69.9891 50.4754 70.5745 51.1722 71.0054C51.868 71.4374 52.6625 71.6539 53.5548 71.6539C54.4471 71.6539 55.1986 71.4448 55.8954 71.0265Z" fill="white" />
          <path d="M68.6867 76.355C67.5852 75.7559 66.7287 74.9277 66.1159 73.8683C65.5032 72.81 65.1963 71.6255 65.1963 70.3159C65.1963 69.0064 65.4958 67.8429 66.0949 66.8256C66.694 65.8092 67.5369 65.022 68.6236 64.464C69.7103 63.9069 70.9495 63.6284 72.3442 63.6284C73.8208 63.6568 74.9843 64.012 75.8345 64.6941C76.6838 65.3773 77.2072 66.3736 77.4016 67.6832L76.566 67.7252L76.6501 65.886C76.7331 64.465 76.3369 63.3572 75.4593 62.5627C74.5817 61.7681 73.3342 61.3719 71.7188 61.3719C70.1034 61.3719 68.653 61.7902 67.3719 62.6257V57.4432C69.044 56.6634 70.8685 56.2724 72.8476 56.2724C75.8566 56.2724 78.2256 57.067 79.9534 58.6551C81.6813 60.2431 82.5452 62.4166 82.5452 65.1755V76.7533H76.9024V72.0722L77.5298 73.4931C77.1388 74.8037 76.5408 75.757 75.7326 76.3561C74.9244 76.9551 73.8376 77.2547 72.4724 77.2547C71.1071 77.2547 69.7902 76.9551 68.6898 76.3561L68.6867 76.355ZM75.5833 72.4263C75.9596 72.2172 76.2591 71.925 76.482 71.5488C76.7048 71.1725 76.8162 70.7479 76.8162 70.2739C76.8162 69.8283 76.7121 69.4247 76.503 69.0621C76.2938 68.7005 75.9943 68.4136 75.6044 68.2055C75.2134 67.9964 74.7688 67.8923 74.2664 67.8923C73.7641 67.8923 73.3184 67.9964 72.9285 68.2055C72.5375 68.4147 72.2391 68.7005 72.0299 69.0621C71.8208 69.4247 71.7167 69.843 71.7167 70.3159C71.7167 71.0411 71.9532 71.6255 72.4272 72.0711C72.9001 72.5167 73.5139 72.7395 74.2664 72.7395C74.7678 72.7395 75.2071 72.6355 75.5833 72.4263Z" fill="white" />
          <path d="M84.3599 56.8154H90.5882V62.2911L89.6685 61.2464C90.2256 59.631 91.0895 58.3972 92.2603 57.5469C93.4311 56.6977 94.8658 56.272 96.5652 56.272C98.8501 56.272 100.689 56.9899 102.083 58.4245C103.476 59.8602 104.172 61.8035 104.172 64.2555V76.7529H97.9031V65.6344C97.9031 64.4089 97.59 63.4472 96.9625 62.7504C96.3351 62.0547 95.4638 61.7057 94.3497 61.7057C93.2356 61.7057 92.3013 62.0683 91.6329 62.7925C90.9644 63.5177 90.6302 64.5203 90.6302 65.8015V76.7529H84.361V56.8154H84.3599ZM95.2273 54.0986C94.8363 53.9598 94.4327 53.7791 94.0155 53.5552C93.737 53.4165 93.4648 53.2977 93.201 53.2C92.9361 53.1022 92.6786 53.0539 92.4274 53.0539C91.9534 53.0539 91.6055 53.1863 91.3827 53.4511C91.1589 53.716 91.0338 53.9735 91.0065 54.2247H87.4541C87.4814 52.5252 87.8514 51.202 88.5618 50.254C89.2723 49.307 90.2403 48.833 91.4668 48.833C92.0238 48.833 92.4905 48.8961 92.8667 49.0212C93.243 49.1462 93.6676 49.3491 94.1416 49.6276C94.4474 49.7674 94.7333 49.8924 94.9982 50.0039C95.263 50.1153 95.5342 50.171 95.8138 50.171C96.2037 50.171 96.5242 50.0669 96.7754 49.8578C97.0266 49.6486 97.1653 49.3638 97.1937 49.0012H100.955C100.871 50.6176 100.426 51.9062 99.6173 52.8678C98.8091 53.8295 97.7781 54.3098 96.5242 54.3098C96.0502 54.3098 95.6183 54.2404 95.2284 54.1007L95.2273 54.0986Z" fill="white" />
          <path d="M110.713 75.9367C109.014 75.0318 107.683 73.7779 106.721 72.1752C105.76 70.5734 105.279 68.7689 105.279 66.7625C105.279 64.7561 105.76 62.9946 106.721 61.3919C107.683 59.7901 109.013 58.5363 110.713 57.6303C112.413 56.7254 114.308 56.2724 116.398 56.2724C118.488 56.2724 120.382 56.7254 122.083 57.6303C123.782 58.5363 125.113 59.7901 126.075 61.3919C127.036 62.9946 127.517 64.7845 127.517 66.7625C127.517 68.7405 127.036 70.5734 126.075 72.1752C125.113 73.7779 123.782 75.0318 122.083 75.9367C120.383 76.8416 118.488 77.2946 116.398 77.2946C114.308 77.2946 112.413 76.8416 110.713 75.9367ZM118.843 71.0884C119.553 70.6565 120.117 70.0647 120.536 69.3122C120.954 68.5597 121.163 67.7105 121.163 66.7625C121.163 65.8145 120.96 65.0073 120.557 64.2548C120.152 63.5023 119.589 62.9169 118.864 62.4996C118.138 62.0813 117.331 61.8722 116.439 61.8722C115.547 61.8722 114.697 62.0813 113.973 62.4996C113.248 62.9179 112.677 63.5023 112.259 64.2548C111.841 65.0073 111.632 65.8429 111.632 66.7625C111.632 67.6821 111.841 68.5597 112.259 69.3122C112.677 70.0647 113.248 70.6565 113.973 71.0884C114.697 71.5204 115.519 71.7358 116.439 71.7358C117.359 71.7358 118.132 71.5204 118.843 71.0884Z" fill="white" />
          <path d="M128.877 47.4932H135.146V76.752H128.877V47.4932Z" fill="white" />
        </SvgIcon>
      );
    default:
      return null;
  }

};

export default memo(Tubi);
