import {
  FIRETV_DEBOUNCE_BACKGROUND_IMAGE,
  getConfig,
} from 'common/experiments/config/ottFireTVDebounceBackgroundImageRerun';
import { popperExperimentsSelector } from 'common/selectors/experiments';
import type { StoreState } from 'common/types/storeState';

export const ottFireTVDebounceBackgroundExperimentSelector = (state: StoreState) => popperExperimentsSelector(state, {
  ...FIRETV_DEBOUNCE_BACKGROUND_IMAGE,
  config: getConfig(),
});

