import { SERIES_CONTENT_TYPE } from 'common/constants/constants';
import type { HistoryState } from 'common/types/history';
import type { Series } from 'common/types/series';
import type { Video } from 'common/types/video';
import { convertSeriesIdToContentId } from 'common/utils/dataFormatter';
import { getFirstEpisodeIdFromSeries } from 'common/utils/getFirstEpisodeIdFromSeries';
import { getResumeInfo } from 'common/utils/getResumeInfo';
import { isHistoryValue } from 'common/utils/history';

interface GetVideoInfoForPlayingParams {
  content: Video | Series;
  byId: { [key: string]: Video | Series };
  historyIdMap: HistoryState['contentIdMap'];
}

/**
 * Return content ID and playing position
 */
export const getVideoInfoForPlaying = ({ content, byId, historyIdMap }: GetVideoInfoForPlayingParams) => {
  const isSeries = content.type === SERIES_CONTENT_TYPE;
  const contentId = isSeries ? convertSeriesIdToContentId(content.id) : content.id;
  // Play from beginning if doesn't have history info
  let resumeContentId = getFirstEpisodeIdFromSeries(content) || contentId;
  let resumePosition = 0;

  // Resume if has history info
  if (historyIdMap && contentId in historyIdMap) {
    const history = historyIdMap[contentId];
    const resumeInfo = getResumeInfo({
      byId,
      contentId,
      history,
      isSeries,
    });
    if (resumeInfo.contentId) {
      // Content is fully loaded
      resumeContentId = resumeInfo.contentId;
      resumePosition = resumeInfo.position;
      /* istanbul ignore else */
    } else if (isHistoryValue(history) && history.contentType === 'series') {
      // Series content is not fully loaded
      const item = history.episodes[history.position];
      /* istanbul ignore else */
      if (item) {
        resumeContentId = String(item.contentId);
        resumePosition = item.position;
      }
    }
  }

  return {
    resumeContentId,
    resumePosition,
  };
};
