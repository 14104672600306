import { createSelector } from 'reselect';

import { LOGIN_WITH_GOOGLE_ONE_TAP_MIN_BUILD_CODE } from 'common/constants/constants';
import { appVersionSelector } from 'common/selectors/fireUtils';
import { isAuthGoogleOnetapEnabledSelector } from 'common/selectors/remoteConfig';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';

const isLoginWithGoogleOneTapAvailable = (buildCode: number = 0): boolean => {
  return __OTTPLATFORM__ === 'ANDROIDTV' && buildCode >= LOGIN_WITH_GOOGLE_ONE_TAP_MIN_BUILD_CODE;
};

export const isLoginWithGoogleOneTapAvailableSelector = createSelector(
  isAuthGoogleOnetapEnabledSelector,
  appVersionSelector,
  (isAuthGoogleOnetapEnabled, appVersion) => {
    if (FeatureSwitchManager.get('ForceLoginWithFlow') === 'googleOneTap') {
      return true;
    }

    if (!isAuthGoogleOnetapEnabled) {
      return false;
    }

    return isLoginWithGoogleOneTapAvailable(appVersion.code);
  }
);
