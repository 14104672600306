import { useLayoutEffect, useMemo, useRef } from 'react';

type Fn<ARGS extends any[], R> = (...args: ARGS) => R;

// A Hook to define an event handler with an always-stable function identity.
// This is a RFC for now, we should use it only when necessary
// @see https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
const useEvent = <A extends any[], R>(fn: Fn<A, R>): Fn<A, R> => {
  const ref = useRef<Fn<A, R>>(fn);
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useMemo(() => (...args: A): R => {
    const { current } = ref;
    return current(...args);
  }, []);
};

export default useEvent;
