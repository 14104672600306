import { defineMessages } from 'react-intl';

export const topNavMessages = defineMessages({
  movies: {
    description: 'movies content mode option on the top nav',
    defaultMessage: 'Movies',
  },
  tvShows: {
    description: 'TV shows content mode option on the top nav',
    defaultMessage: 'TV Shows',
  },
  liveTV: {
    description: 'Live TV option on the top nav',
    defaultMessage: 'Live TV',
  },
  myStuff: {
    description: 'My stuff option on the top nav',
    defaultMessage: 'My Stuff',
  },
  tubiKids: {
    description: 'text on the top nav',
    defaultMessage: 'Tubi Kids',
  },
  espanol: {
    description: 'link text for site navigation header to enter español mode',
    defaultMessage: 'Español',
  },
});
