import { ActionLevel } from '@adrise/player/lib';

/**
 * An explicit pause is one which results from user action, whether or not
 * the intentional aim of that action is to pause the player. This includes
 * player overlay buttons, remote interactions, and voice commands, but also
 * importantly includes pauses resulting from visibility changes that the
 * user caused to take place.
 */
export function isPauseExplicit(level: ActionLevel): boolean {
  return level < ActionLevel.CODE;
}
