import type {
  ANALYTICS_COMPONENTS } from '@tubitv/analytics/lib/components';
import {
  ANALYTICS_DESTINATION_COMPONENTS,
} from '@tubitv/analytics/lib/components';
import type { MeansOfNavigationType } from '@tubitv/analytics/lib/navigateWithinPage';
import type { ValueOf } from 'ts-essentials';

import { CONTENT_MODES } from 'common/constants/constants';
import { COMPONENT_INTERACTION_EVENT, NAVIGATE_WITHIN_PAGE } from 'common/constants/event-types';
import tubiHistory from 'common/history';
import { containerSelector, ottSideMenuSelector } from 'common/selectors/container';
import { currentContentModeSelector } from 'common/selectors/contentMode';
import type StoreState from 'common/types/storeState';
import type {
  MapTopNavOptionToAnalyticsSectionFunc } from 'common/utils/analytics';
import {
  buildComponentInteractionEvent,
  buildNavigateWithinEventObject,
} from 'common/utils/analytics';
import { findIndex } from 'common/utils/collection';
import { getCurrentPathname } from 'common/utils/getCurrentPathname';
import { trackEvent } from 'common/utils/track';

export const trackEnterTopNav = (
  selectedIndex: number | undefined,
  mapOptionToAnalyticsSectionFunc: MapTopNavOptionToAnalyticsSectionFunc,
) => {
  if (__SERVER__) return;

  // ComponentInteractionEvent
  const event = buildComponentInteractionEvent({
    pathname: getCurrentPathname(),
    userInteraction: 'TOGGLE_ON',
    section: mapOptionToAnalyticsSectionFunc(selectedIndex),
    component: 'TOP_NAV',
  });
  if (event) {
    trackEvent(COMPONENT_INTERACTION_EVENT, event);
  }
};

export const trackLeaveTopNav = (
  selectedIndex: number | undefined,
  mapOptionToAnalyticsSectionFunc: MapTopNavOptionToAnalyticsSectionFunc,
) => {
  if (__SERVER__) return;

  // ComponentInteractionEvent
  const event = buildComponentInteractionEvent({
    pathname: getCurrentPathname(),
    userInteraction: 'TOGGLE_OFF',
    section: mapOptionToAnalyticsSectionFunc(selectedIndex),
    component: 'TOP_NAV',
  });
  if (event) {
    trackEvent(COMPONENT_INTERACTION_EVENT, event);
  }
};

export const trackSelectTopNav = (
  selectedIndex: number | undefined,
  mapOptionToAnalyticsSectionFunc: MapTopNavOptionToAnalyticsSectionFunc,
) => {
  if (__SERVER__) return;

  // ComponentInteractionEvent
  const event = buildComponentInteractionEvent({
    pathname: getCurrentPathname(),
    userInteraction: 'CONFIRM',
    section: mapOptionToAnalyticsSectionFunc(selectedIndex),
    component: 'TOP_NAV',
  });
  if (event) {
    trackEvent(COMPONENT_INTERACTION_EVENT, event);
  }
};

export const trackBackTopNav = (
  selectedIndex: number | undefined,
  mapOptionToAnalyticsSectionFunc: MapTopNavOptionToAnalyticsSectionFunc,
) => {
  if (__SERVER__) return;

  // ComponentInteractionEvent
  const event = buildComponentInteractionEvent({
    pathname: getCurrentPathname(),
    userInteraction: 'BACK',
    section: mapOptionToAnalyticsSectionFunc(selectedIndex),
    component: 'TOP_NAV',
  });
  if (event) {
    trackEvent(COMPONENT_INTERACTION_EVENT, event);
  }
};

export const trackSkipTopNav = (
  selectedIndex: number | undefined,
  mapOptionToAnalyticsSectionFunc: MapTopNavOptionToAnalyticsSectionFunc,
) => {
  if (__SERVER__) return;

  // ComponentInteractionEvent
  const event = buildComponentInteractionEvent({
    pathname: getCurrentPathname(),
    userInteraction: 'SKIP',
    section: mapOptionToAnalyticsSectionFunc(selectedIndex),
    component: 'TOP_NAV',
  });
  if (event) {
    trackEvent(COMPONENT_INTERACTION_EVENT, event);
  }
};

export interface TrackNavMenuNavigateWithinPageEventParams {
  componentType?: ValueOf<typeof ANALYTICS_COMPONENTS>;
  destinationComponentType?: ValueOf<typeof ANALYTICS_DESTINATION_COMPONENTS>;
  destinationComponentSectionIndex?: number;
  meansOfNavigation: MeansOfNavigationType;
  componentSectionIndex?: number;
  navComponentSectionIndex?: number;
  navComponentType?: ValueOf<typeof ANALYTICS_COMPONENTS>;
  overrideHorizontalLocation?: number;
  overrideVerticalLocation?: number;
  mapTopNavOptionToAnalyticsSectionFunc?: MapTopNavOptionToAnalyticsSectionFunc;
}

// track NavigateWithinPageEvent for nav menu, top nav or left nav
export const trackNavMenuNavigateWithinPageEvent = ({
  componentType,
  destinationComponentType,
  destinationComponentSectionIndex,
  meansOfNavigation,
  navComponentSectionIndex,
  navComponentType,
  overrideHorizontalLocation,
  overrideVerticalLocation,
  mapTopNavOptionToAnalyticsSectionFunc,
}: TrackNavMenuNavigateWithinPageEventParams, state: StoreState) => {
  let {
    fire: {
      containerUI: {
        containerId: activeContainerId,
      },
    },
  } = state;
  const {
    ui: {
      containerIndexMap,
    },
    ottUI: {
      leftNav: {
        selectedOption: leftNavSelectedIndex,
      },
    },
    search: { key: query },
  } = state;

  const extraCtx = query ? { query } : undefined;

  const location = tubiHistory.getCurrentLocation();
  const { containerChildrenIdMap } = containerSelector(state, { pathname: location.pathname });
  const containersInMenu = ottSideMenuSelector(state, { pathname: location.pathname });

  let activeContainerIndex = findIndex(containersInMenu, cat => cat.id === activeContainerId);
  let activeGridIndex = containerIndexMap[activeContainerId] || 0;

  let activeContentId = '';
  if (containerChildrenIdMap[activeContainerId] && containerChildrenIdMap[activeContainerId][activeGridIndex]) {
    activeContentId = containerChildrenIdMap[activeContainerId][activeGridIndex];
  }

  const activeContentMode = currentContentModeSelector(state, { pathname: location.pathname });
  const isEPGActive = activeContentMode === CONTENT_MODES.linear;

  // If it's on EPG page, we need get the active index from the different place
  if (isEPGActive) {
    const {
      ottUI: {
        epg: {
          focusedContentId,
          focusedContainerId,
          focusedProgramIndex,
          topNav: {
            activeContentMode,
          },
        },
      },
      epg: {
        contentIdsByContainer,
      },
    } = state;
    activeContainerIndex = contentIdsByContainer[activeContentMode].findIndex(item => item.container_slug === focusedContainerId);
    activeGridIndex = focusedProgramIndex;
    activeContainerId = focusedContainerId;
    activeContentId = focusedContentId;
  }

  if (destinationComponentType === ANALYTICS_DESTINATION_COMPONENTS.destinationLeftSideNavComponent
    && destinationComponentSectionIndex === undefined) {
    destinationComponentSectionIndex = leftNavSelectedIndex as number;
  }

  // Need to add 1 because our index starts with 0 and analytics wants the index starting 1.
  const matrix = {
    startX: Math.max(activeGridIndex, 0) + 1,
    startY: Math.max(activeContainerIndex, 0) + 1,
    endX: Math.max(activeGridIndex, 0) + 1,
    endY: Math.max(activeContainerIndex, 0) + 1,
  };

  const navigateWithinEventObj = buildNavigateWithinEventObject({
    pageUrl: getCurrentPathname(),
    matrix,
    meansOfNavigation,
    containerSlug: activeContainerId,
    contentId: activeContentId,
    isSeries: activeContentId !== undefined && activeContentId !== null && activeContentId[0] === '0',
    componentType,
    destinationComponentType,
    destinationComponentSectionIndex,
    navComponentSectionIndex,
    navComponentType,
    overrideHorizontalLocation,
    overrideVerticalLocation,
    extraCtx,
    mapTopNavOptionToAnalyticsSectionFunc,
  });

  // Build and send the navigate withn page event
  trackEvent(NAVIGATE_WITHIN_PAGE, navigateWithinEventObj);
};
