import { createSelector } from 'reselect';

import { isAuthMagicLinkEnabledSelector } from 'common/selectors/remoteConfig';
import { isSamsungBefore2017 } from 'common/utils/tizenTools';

const OTHER_ENABLED_OTT_PLATFORMS = [
  'VIZIO',
  'XBOXONE',
  'PS4',
  'PS5',
  'HISENSE',
  'SONY',
  'LGTV',
  'COX',
  'ROGERS',
  'SHAW',
  'TIVO',
];

export const isOTTMagicLinkEnabledSelector = createSelector(
  isAuthMagicLinkEnabledSelector,
  (isAuthMagicLinkEnabled) => {
    if (!isAuthMagicLinkEnabled) {
      return false;
    }

    if (__OTTPLATFORM__ === 'TIZEN' && !isSamsungBefore2017()) {
      return true;
    }

    return OTHER_ENABLED_OTT_PLATFORMS.includes(__OTTPLATFORM__);
  }
);
