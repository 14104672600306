import type { Action } from 'redux';
import type { ValueOf } from 'ts-essentials';

import {
  SET_DEFAULT_CAPTIONS,
  SET_DEFAULT_AUDIO_TRACKS,
  SET_CAPTION_SETTINGS,
  LOAD_LOCAL_CAPTIONS,
  RESET_CAPTION_SETTINGS,
} from 'common/constants/action-types';
import { TRACK_LOGGING, LOG_SUB_TYPE } from 'common/constants/error-types';
import { getAudioTrackLabelFromLocale } from 'common/utils/audioTracks';
import { findSubOptionIndex, loadCaptionSettings } from 'common/utils/captionTools';
import { trackLogging } from 'common/utils/track';
import { OPTIONS, CAPTIONS_OPTIONS, SUB_OPTION_VALUE } from 'ott/constants/captionSettings';

import type { TubiThunkAction } from '../types/reduxThunk';

const actions = { SET_DEFAULT_CAPTIONS, SET_DEFAULT_AUDIO_TRACKS, SET_CAPTION_SETTINGS, LOAD_LOCAL_CAPTIONS, RESET_CAPTION_SETTINGS };

/**
 * Setting user's captions selection on store to persists for the session
 * Todo (yuhao) set this based on UAPI response when API is available
 * @param {String} captions
 */
export function setDefaultCaptions({ language, enabled }: { language?: string, enabled: boolean }) {
  return {
    ...(language && language.toLowerCase() !== 'off' ? { language } : {}),
    enabled,
    type: actions.SET_DEFAULT_CAPTIONS,
  };
}

export function setDefaultAudioTrack(audioTracks: string, age?: number) {
  return {
    audioTracks,
    age,
    type: actions.SET_DEFAULT_AUDIO_TRACKS,
  };
}

export function setCaptionSettings(id: ValueOf<typeof OPTIONS>, subOptionIndex: number): TubiThunkAction {
  return (dispatch, getState) => {
    if (id === OPTIONS.SWITCH) {
      const isCaptionOn = subOptionIndex === findSubOptionIndex(CAPTIONS_OPTIONS.switch.subOptions, SUB_OPTION_VALUE.ON);
      dispatch(setDefaultCaptions({
        enabled: isCaptionOn,
      }));
    } else {
      dispatch({
        type: actions.SET_CAPTION_SETTINGS,
        id,
        subOptionIndex,
      });
    }

    // track user select action
    const {
      captionSettings: { byId },
    } = getState();
    const currentOption = byId[id];
    const selectedSubOption = currentOption.subOptions?.[subOptionIndex];
    if (selectedSubOption) {
      trackLogging({
        type: TRACK_LOGGING.clientInfo,
        subtype: LOG_SUB_TYPE.CAPTIONS_SETTINGS,
        message: {
          option: currentOption.title,
          selection: selectedSubOption.title,
        },
      });
    }
  };
}

export function loadCaptionSettingsFromLocal(): TubiThunkAction {
  return (dispatch, getState) => {
    const { captions, audioTracks, captionsSettings } = loadCaptionSettings();
    const { ui: { userLanguageLocale } } = getState();

    dispatch({
      type: actions.LOAD_LOCAL_CAPTIONS,
      captions,
      audioTracks: audioTracks || getAudioTrackLabelFromLocale(userLanguageLocale),
      captionsSettings,
    });
  };
}

export function resetCaptionSettings(): Action {
  trackLogging({
    type: TRACK_LOGGING.clientInfo,
    subtype: LOG_SUB_TYPE.CAPTIONS_SETTINGS,
    message: {
      option: 'Reset',
      selection: '',
    },
  });

  return {
    type: actions.RESET_CAPTION_SETTINGS,
  };
}
