import classNames from 'classnames';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import type { StoreState } from 'common/types/storeState';
import type { BgPageType } from 'common/utils/backgroundImages';
import { preloadImages } from 'common/utils/preloadImages';
import addLoadedClass from 'ott/utils/addLoadedClass';
import type { FidelityLevel } from 'ott/utils/uiFidelity';

import styles from './OTTBackgroundGradient.scss';

export interface Props {
  fullScreen?: boolean,
  isKidsModeEnabled?: boolean,
  uiFidelity: FidelityLevel | number,
  isLiveGradient?: boolean,
  isScreenSaver?: boolean,
  isLiveNewsPlayerPage: boolean,
  isPreviewPlayerGradient?: boolean;
  isMyStuffGradient?: boolean;
  currentPage?: BgPageType;
  isEpgPage?: boolean;
  largeVideoPreview?: boolean;
  useRepaintGradient?: boolean;
}

function OTTBackgroundGradient({
  fullScreen = false,
  isKidsModeEnabled = false,
  isLiveGradient = false,
  isScreenSaver = false,
  isPreviewPlayerGradient = false,
  isMyStuffGradient = false,
  isEpgPage,
  largeVideoPreview,
  useRepaintGradient,
}: Props) {
  let baseGradientCls = '';
  // preload kids background image to improve transition across all apps (and handle PS5 stutter issue)
  const imagesToPreload: string[] = [];

  if (isScreenSaver) {
    baseGradientCls = styles.screenSaverGradient;
  } else if (isKidsModeEnabled) {
    baseGradientCls = styles.kidsGradient;
    if (fullScreen) {
      imagesToPreload.push(require('../../../common/theme/images/KidsDetailPageBackdrop.png'));
    }
  } else if (largeVideoPreview) {
    baseGradientCls = styles.previewPlayerBgGradientLarge;
  } else if (isPreviewPlayerGradient) {
    baseGradientCls = styles.previewPlayerBgGradient;
  } else if (isLiveGradient) {
    baseGradientCls = styles.liveGradient;
  } else {
    baseGradientCls = styles.gradient;
  }

  useEffect(() => {
    if (__CLIENT__) {
      preloadImages(imagesToPreload).then(addLoadedClass);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesToPreload.join(';')]);

  let kidsVideoPreviewClass = '';
  if (largeVideoPreview) {
    kidsVideoPreviewClass = styles.kidsPreviewLarge;
  } else if (isPreviewPlayerGradient) {
    kidsVideoPreviewClass = styles.kidsPreview;
  }

  const gradientClass = classNames(
    baseGradientCls,
    {
      [styles.epgPage]: isEpgPage,
      [styles.fullscreen]: fullScreen,
      [styles.kidsGradient]: isScreenSaver && isKidsModeEnabled,
      [styles.myStuffGradient]: isMyStuffGradient,
      [styles.repaintGradient]: useRepaintGradient,
    },
    kidsVideoPreviewClass
  );
  return <div className={gradientClass} />;
}

export const mapStateToProps = (state: StoreState) => {
  const { uiFidelity } = state.ui;
  return {
    uiFidelity,
    isLiveNewsPlayerPage: false,
  };
};

export const OTTBackgroundGradientRaw = OTTBackgroundGradient;

const connectedComponent = connect(mapStateToProps)(OTTBackgroundGradient);

export default connectedComponent;

