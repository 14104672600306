import { handleFireTvDeeplinkHook } from 'common/features/ottDeeplink/platforms/firetv-hyb';
import { callIfDefined } from 'common/helpers/routing';
import type { RouteFn } from 'common/helpers/routing';

export const ottDeeplinkOnEnterHook: RouteFn = (store, nextState, replace, cb) => {
  if (__OTTPLATFORM__ === 'FIRETV_HYB') {
    handleFireTvDeeplinkHook(store, nextState, replace, cb);
  }
  callIfDefined(cb);
};
