import { createSelector } from 'reselect';

import {
  isAuthComcastEmailPrefillEnabledSelector,
  isAuthVizioEmailPrefillEnabledSelector,
} from 'common/selectors/remoteConfig';

export const isComcastEmailPrefillEnabledSelector = createSelector(
  isAuthComcastEmailPrefillEnabledSelector,
  (isAuthComcastEmailPrefillEnabled) => {
    return __OTTPLATFORM__ === 'COMCAST' && isAuthComcastEmailPrefillEnabled;
  }
);

export const isVizioEmailPrefillEnabledSelector = createSelector(
  isAuthVizioEmailPrefillEnabledSelector,
  (isAuthVizioEmailPrefillEnabled) => {
    return __OTTPLATFORM__ === 'VIZIO' && isAuthVizioEmailPrefillEnabled;
  }
);
