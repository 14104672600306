import type { A11yAction, A11yState } from 'common/types/a11y';

import * as actions from '../constants/action-types';

export const initialState = {
  isNativeTTSOn: false,
  text: '',
  onceBefore: '', // read once before the main text the first time to reduce reading noise
  onceAfter: '', // read once after the main text the first time to reduce reading noise
  cancelable: true,
};

export default function a11yReducer(state: A11yState = initialState, action: A11yAction = {} as A11yAction): A11yState {
  switch (action.type) {
    case actions.SET_A11Y:
      // we may want to just change the text so onceAfter and onceBefore could be undefined in which case
      // we will keep them as is. However if we want to reset them then we will pass empty string
      // or specific text if we wish to change them
      const { onceBefore, onceAfter, text, cancelable } = action;
      return {
        ...state,
        ...(typeof text === 'string' ? { text } : {}),
        ...(typeof onceBefore === 'string' ? { onceBefore } : {}),
        ...(typeof onceAfter === 'string' ? { onceAfter } : {}),
        // we set cancelable true as default value if it is undefined
        cancelable: typeof cancelable === 'boolean' ? cancelable : true,
      };
    case actions.CLEAR_A11Y:
      return {
        ...state,
        text: '',
        onceBefore: '',
        onceAfter: '',
        cancelable: true,
      };
    case actions.ENABLE_NATIVE_TTS:
      return {
        ...state,
        isNativeTTSOn: true,
      };
    case actions.DISABLE_NATIVE_TTS:
      return {
        ...state,
        isNativeTTSOn: false,
      };
    default:
      return state;
  }
}
