import { LOG_SUB_TYPE, TRACK_LOGGING } from 'common/constants/error-types';
import { trackLogging } from 'common/utils/track';

export function trackEmptyBackground({ timeElapsed, isBackgroundImageVisible, isPreviewPlaying }: {
  timeElapsed: number,
  isBackgroundImageVisible: boolean,
  isPreviewPlaying: boolean,
}): void {
  trackLogging({
    type: TRACK_LOGGING.clientInfo,
    subtype: LOG_SUB_TYPE.EMPTY_BACKGROUND,
    message: {
      timeElapsed,
      isBackgroundImageVisible,
      isPreviewPlaying,
    },
  });
}
