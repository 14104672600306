import type { Location } from 'history';

import { loadContainer } from 'common/actions/container';
import { loadHistory } from 'common/actions/history';
import type { History, HistoryEpisode, SeriesHistory } from 'common/types/history';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import type StoreState from 'common/types/storeState';
import type { Video } from 'common/types/video';
import { getRecentHistory, getContentIdFromHistoryItem } from 'common/utils/history';

export interface LastCWItemData {
  cwContentId: string,
  historyItem: History | HistoryEpisode,
  videoItem: Video,
}

// get and build payload for the logged in user's last continue watching title
export const getLastCWItem = async (location: Location, getState: () => StoreState, dispatch: TubiThunkDispatch): Promise<LastCWItemData | null> => {
  // load history if it has not been loaded
  await dispatch(loadHistory());
  const { history: { contentIdMap }, video } = getState();
  let byId = video.byId;
  const historyContents = getRecentHistory({ historyDataMap: contentIdMap, maxLength: 1 });
  if (historyContents.length === 0) return null;

  let historyItem: History | HistoryEpisode = historyContents[0];
  const cwContentId = getContentIdFromHistoryItem(historyItem as History);
  const isEpisodeCWItem = !!(historyItem as SeriesHistory).episodes;

  // if episodes exist in the history item, set the history item to the last episode in the array (which is the latest watched)
  if (isEpisodeCWItem) {
    const episodes = (historyItem as SeriesHistory).episodes;
    historyItem = episodes[episodes.length - 1];
  }

  let isVideoLoaded = !!byId[cwContentId];
  // if video content is not in our redux store, load continue watching container to get video details
  if (!isVideoLoaded) {
    await dispatch(loadContainer({ location, id: 'continue_watching' }));
    byId = getState().video.byId;
  }
  isVideoLoaded = !!byId[cwContentId];
  if (isVideoLoaded) {
    const id = isEpisodeCWItem ? String(historyItem.contentId) : cwContentId;
    return {
      cwContentId: id,
      historyItem,
      videoItem: byId[cwContentId],
    };
  }

  // if video from history does not exist in continue watching, return null to remove cw from pmr
  return null;
};
