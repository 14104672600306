export const COMCAST_MONEY_BADGER_EVENT = 'onMoneyBadgerReady';
export const COMCAST_PLAYBACK_STATE_CHANGE = 'CONTENT_STATE_CHANGE';
export const COMCAST_PLAYBACK_STATE_STARTED = 'STARTED';

export const VIZIO_TTS_ENABLED = 'VIZIO_TTS_ENABLED';
export const VIZIO_TTS_DISABLED = 'VIZIO_TTS_DISABLED';
export const VIZIO_LIB_LOADED = 'VIZIO_LIBRARY_DID_LOAD';
// emit this event when the native TTS system is ready, if it takes some amount of time to initialize
export const EVENTS_TTS_INITIALIZED = 'events.ttsInitialized';

// this is used to limit the dismissLoadingScreen called times
export const IS_COMCAST_DISMISS_LOADING_ANIMATION_CALLED = 'comcast.dismiss_loading_called';

export const PS_ADVERTISING_ENDPOINT_URL = 'https://s2s.np.playstation.net/api/personalization/v1/partners/accessCode';
