import { useEffect } from 'react';

import * as domUtils from 'common/utils/dom';

interface UseKeydownHandlerOptions {
  prepend: boolean;
  listenerOptions?: AddEventListenerOptions;
}

export default function useKeydownHandler(
  callback: (event: KeyboardEvent) => void,
  options: Partial<UseKeydownHandlerOptions> = {},
) {
  useEffect(
    () => {
      if (options.prepend) {
        domUtils.prependEventListener(window, 'keydown', callback, options.listenerOptions);
      } else {
        domUtils.addEventListener(window, 'keydown', callback, options.listenerOptions);
      }
      return () => {
        domUtils.removeEventListener(window, 'keydown', callback, options.listenerOptions);
      };
    },
    [callback, options.prepend, options.listenerOptions],
  );
}
