import tubiHistory from 'common/history';
import { currentContentModeSelector } from 'common/selectors/contentMode';
import { forceFailsafeExperimentSelector } from 'common/selectors/experiments/forceFailsafe';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';
import type StoreState from 'common/types/storeState';
import { getPlatform } from 'common/utils/platform';

// For failsafe CDN request, we need to add the following headers:
export const getFailSafeHeaders = (state: StoreState, contentMode?: string) => {
  const headers: Record<string, string | number> = {};
  const { auth: { user }, userSettings } = state;

  const isForcedFailsafe = forceFailsafeExperimentSelector(state) || FeatureSwitchManager.get('force_failsafe') === FeatureSwitchManager.ENABLE_VALUE;
  if (isForcedFailsafe) {
    headers['X-TRIGGER-FAILSAFE'] = 'GAME_DAY_EXPERIENCE';
  }
  headers['X-TUBI-PLATFORM'] = getPlatform();
  headers['X-TUBI-MODE'] = contentMode || currentContentModeSelector(state, { pathname: tubiHistory.getCurrentLocation().pathname });
  if (user && userSettings) {
    headers['X-TUBI-RATING'] = userSettings.parentalRating;
  }
  return headers;
};
