import type { PlatformUppercase } from 'common/constants/platforms';
import { OTT_ROUTES } from 'common/constants/routes';
import tubiHistory from 'common/history';
import { getCurrentPathname } from 'common/utils/getCurrentPathname';
import { isKidsModeDeeplinkURL } from 'common/utils/urlPredicates';
import { hideLoadingSpinner } from 'ott/utils/loadingSpinner';
import { getRelaunchUrl, isFromRelaunch } from 'ott/utils/relaunchHelper';

// Skip on PS4/SONY for now, since there are issues with linear relaunch on these platforms.
// Skip on HILTON/DIRECTVHOSP since they're related to hotels.
// TODO test on Tizen. It should works when we setup relaunching on the client side by using the client cookies. It can be removed after testing.
const disabledRelaunchPlatforms: PlatformUppercase[] = ['TIZEN', 'PS4', 'SONY', 'HILTON', 'DIRECTVHOSP', 'BRIDGEWATER'];
const isDisabledRelaunchPlatform = disabledRelaunchPlatforms.includes(__OTTPLATFORM__);

export const setupOTTRelaunchPage = () => {
  const { query, search } = tubiHistory.getCurrentLocation();
  /**
   * Relaunch conditions:
   * 1. The current URL is the home page
   * 2. The current URL is not a kids mode deeplink URL
   * 3. The current URL is not a deeplink URL
   * 4. The current URL is not from relaunch linear/vod
   */
  if (isDisabledRelaunchPlatform
    || getCurrentPathname() !== OTT_ROUTES.home
    || isKidsModeDeeplinkURL(query)
    || query.utm_source
    || isFromRelaunch(search)
  ) {
    return;
  }

  const relaunchURL = getRelaunchUrl();
  if (!relaunchURL) return;

  // Hide the spinner on home page after redirect to other pages.
  hideLoadingSpinner();
  tubiHistory.replace(relaunchURL);
};

